<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t("users.label") }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
      <v-text-field
        v-model="search"
        :label="$t('users.search')"
        flat
        solo
        color="red darken-1"
        append-icon="search"
        single-line
        hide-details
      />
      <v-spacer class="hidden-sm-and-down" />
      <v-flex
        v-if="$store.getters.role == 'LekoAdmin'
          || $store.getters.role == 'LekoEmployee'
          || $store.getters.role == 'SuperAdmin'
        "
        xs12
        sm5
      >
        <v-autocomplete
          v-model="institution"
          :items="institutions"
          :loading="loadingInstitution"
          :label="$t('parts.advanced_search.institution')"
          item-text="title"
          item-value="_id"
          flat
          solo
          color="red darken-1"
          class="ml-2 mr-2 mt-2"
        />
      </v-flex>
      <v-btn
        v-if="$store.getters.role == 'LekoAdmin'
          || $store.getters.role == 'LekoEmployee'
          || $store.getters.role == 'SuperAdmin'
        "
        small
        @click="clearSearch()"
      >
        <v-icon
          small
          class="ma-0 pa-0"
        >
          mdi-broom
        </v-icon>
      </v-btn>
      <v-btn
        v-if="$store.getters.role == 'LekoAdmin'
          || $store.getters.role == 'LekoEmployee'
          || $store.getters.role == 'InstitutionAdmin'
          || $store.getters.role == 'SuperAdmin'
        "
        color="grey darken-4"
        dark
        @click="newUser"
      >
        {{ $t("users.button") }}
      </v-btn>
    </v-toolbar>

    <!--ALERT-->
    <v-alert
      :value="alert.value"
      type="error"
    >
      {{ alert.message }}
    </v-alert>

    <!--EDIT DIALOG-->
    <edit-dialog
      ref="editDialog"
      @close="getUsers"
      @error="''"
    />

    <!--DATATABLE-->
    <datatable
      ref="datatable"
      :search="search"
      :institution="institution"
      @edit="editUser"
    />
  </v-container>
</template>

<script>
import EditDialog from '@/components/Users/dialog.vue';
import Datatable from '@/components/Users/datatable.vue';
import getRequest from '../api/getRequest';

export default {
  components: {
    'edit-dialog': EditDialog,
    datatable: Datatable,
  },
  data: () => ({
    search: '',
    alert: {
      value: false,
      message: '',
    },
    institution: null,
    institutions: [],
    loadingInstitution: false,
  }),
  created() {
    this.getInstitutions();
  },
  methods: {
    clearSearch() {
      this.search = '';
      this.institution = null;
    },
    getInstitutions() {
      this.loadingInstitution = true;
      getRequest('/institution/')
        .then((response) => { this.institutions = response.institutions; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingInstitution = false; });
    },
    showAlert() {
      this.alert = { value: true, message: '' };
      setTimeout(() => { this.alert = { value: false, message: '' }; }, 2000);
    },
    newUser() {
      const { editDialog } = this.$refs;
      editDialog.open(false, null);
    },
    editUser(data) {
      const { editDialog } = this.$refs;
      editDialog.open(true, data);
    },
    getUsers() {
      const { datatable } = this.$refs;
      datatable.getUsers();
    },
  },

};
</script>
