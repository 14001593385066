<template>
  <v-dialog
    v-model="dialog"
    max-width="1300"
  >
    <v-card>
      <v-layout
        justify-end
        class="pt-1 pr-1"
      >
        <v-btn
          outline
          icon
          large
          color="red"
          @click="dialog = false"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-layout>
      <v-card-text>
        <v-layout
          row
          wrap
        >
          <v-flex
            v-for="(part, index) in parts"
            :key="index"
          >
            <v-card flat>
              <v-layout
                align-center
                justify-center
                column
              >
                <v-flex
                  pa-2
                  xs12
                >
                  <v-layout column>
                    <v-flex>
                      <qr-code
                        :size="150"
                        :text="part._id"
                      />
                    </v-flex>
                    <v-flex>
                      <v-layout
                        align-center
                        justify-center
                        row
                        wrap
                      >
                        <v-flex
                          pl-5
                          xs8
                        >
                          <h3 class="text-xs-center">
                            {{ part._id.slice(part._id.length-6, part._id.length) }}
                          </h3>
                        </v-flex>
                        <v-flex xs4>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn
                                large
                                icon
                                v-on="on"
                                @click="printSingle(part._id)"
                              >
                                <v-icon>
                                  {{ part.printed ? 'print_disabled' : 'print' }}
                                </v-icon>
                              </v-btn>
                            </template>
                            <span v-if="part.printed">
                              <v-layout align-center>
                                <v-icon> print_disabled</v-icon>
                                &nbsp;
                                {{ $t('printed_alert') }}
                              </v-layout>
                            </span>
                          </v-tooltip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          flat
          color="primary"
          @click="print"
        >
          {{ $t('parts.insert_parts.qr_codes.print_qr_codes') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueQrCode from 'vue-qr-generator'; // eslint-disable-line import/extensions
import QrCode from 'qrcode';
import { Printd } from 'printd';
import postRequest from '../../api/postRequest';

function printSingleQrCode(id) {
  QrCode.toDataURL(id, (error, url) => {
    if (error) {
      console.log(error);
    }

    const qrImage = document.createElement('img');
    qrImage.setAttribute('id', 'qrImage');
    qrImage.setAttribute('src', url);
    qrImage.style.width = '100px';
    qrImage.style.height = '100px';

    const idComp = document.createElement('span');
    idComp.textContent = id.slice(id.length - 6, id.length);
    idComp.setAttribute('id', 'idText');

    const urlComp = document.createElement('span');
    urlComp.textContent = 'www.lekotech.com';
    urlComp.setAttribute('id', 'urlText');

    const content = document.createElement('div');
    content.setAttribute('id', 'content');
    content.appendChild(idComp);
    content.appendChild(qrImage);
    content.appendChild(urlComp);

    const css = [
      `
      @media print {
        @page {
          size: 4cm, 3cm;
          size: portrait;
        }
        body {
          height: 100%;
          width: 100%;
        }
        .hideOnPrint {
          display: none;
        }
        #content {
          padding-top: 5px;
          padding-bottom: 5px;
          display: grid;
        }
        #qrImage {
          margin: 0 auto;
        }
        #idText {
          font-family: "Roboto";
          font-size: 16px;
          text-align: center;
          font-weight: bold;
          text-transform: uppercase;
          margin: 0 auto;
        }
        #urlText {
          font-family: "Roboto";
          font-size: 8px;
          text-align: center;
          font-weight: bold;
          text-transform: lowercase;
          margin: 0 auto;
          padding-top: 10px;
        }
      }
      `,
      'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons',
    ];

    const d = new Printd();
    return d.print(content, css, '', (callback) => {
      const { iframe, launchPrint } = callback;
      const { contentWindow } = iframe;
      contentWindow.addEventListener('afterprint', () => {
        postRequest.object('/print', { type: 'temporary-parts', id });
      });
      launchPrint();
    });
  });
}

function printMultipleQrCodes(parts) {
  const content = document.createElement('span');
  content.setAttribute('id', 'content');

  parts.forEach((part) => {
    QrCode.toDataURL(part._id, (error, url) => {
      if (error) {
        console.log(error);
      }

      const qrImage = document.createElement('img');
      qrImage.setAttribute('id', 'qrImage');
      qrImage.setAttribute('src', url);
      qrImage.style.width = '100px';
      qrImage.style.height = '100px';

      const idComp = document.createElement('span');
      idComp.textContent = part._id.slice(part._id.length - 6, part._id.length);
      idComp.setAttribute('id', 'idText');

      const urlComp = document.createElement('span');
      urlComp.textContent = 'www.lekotech.com';
      urlComp.setAttribute('id', 'urlText');

      const partComp = document.createElement('div');
      partComp.setAttribute('id', 'part');
      partComp.appendChild(idComp);
      partComp.appendChild(qrImage);
      partComp.appendChild(urlComp);

      content.appendChild(partComp);
    });
  });

  const css = [
    `
    @media print {
      @page {
        size: 4cm, 3cm;
        size: portrait;
      }
      body {
        height: 100%;
        width: 100%;
      }
      .hideOnPrint {
        display: none;
      }
      #component {
        padding-top: 5px;
        padding-bottom: 5px;
        display: grid;
      }
      #part {
        padding-top: 8px;
        text-align: center;
      }
      #qrImage {
        margin: 0 auto;
      }
      #idText {
        font-family: "Roboto";
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0 auto;
      }
      #urlText {
        font-family: "Roboto";
        font-size: 8px;
        text-align: center;
        font-weight: bold;
        text-transform: lowercase;
        margin: 0 auto;
        margin-top: 10px;
      }
    }
    `,
    'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons',
  ];
  const d = new Printd();
  return d.print(content, css, '', (callback) => {
    const { iframe, launchPrint } = callback;
    const { contentWindow } = iframe;
    contentWindow.addEventListener('afterprint', () => {
      postRequest.object('/print', { type: 'temporary-parts', ids: parts.map(p => p._id) });
    });
    launchPrint();
  });
}

export default {
  components: {
    'qr-code': VueQrCode,
  },
  data: () => ({
    dialog: false,
    parts: [],
  }),
  watch: {
    dialog(value) {
      if (!value) {
        this.parts = [];
      }
    },
  },
  methods: {
    open(data) {
      this.parts = data;
      this.dialog = true;
    },
    print() {
      printMultipleQrCodes(this.parts);
    },
    printSingle(id) {
      printSingleQrCode(id);
    },
  },
};
</script>
