<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <!--Title-->
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t('shipments.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
      <!--Search-->
      <v-text-field
        v-model="search"
        :label="$t('shipments.search')"
        flat
        solo
        color="red darken-1"
        append-icon="search"
        single-line
        hide-details
      />
      <v-spacer />
      <!-- Delete Shipments -->
      <v-btn
        v-if="selected.length > 0"
        color="grey darken-4"
        dark
        :disabled="selected.find(s => s.vehicle)"
        :loading="loadingDelete"
        @click="deleteShipments"
      >
        {{ $t("shipments.delete") }}
      </v-btn>
      <!--Print Shipments-->
      <v-btn
        color="grey darken-4"
        class="hidden-sm-and-down"
        dark
        @click="printShipments"
      >
        {{ $t('shipments.print') }}
      </v-btn>
      <!--New Shipment-->
      <v-btn
        v-if="$store.getters.role == 'LekoAdmin'
          || $store.getters.role == 'InstitutionAdmin'
          || $store.getters.role == 'InstitutionManager'
          || $store.getters.role == 'SuperAdmin'
        "
        color="grey darken-4"
        dark
        @click="newShipment"
      >
        {{ $t('shipments.button') }}
      </v-btn>
    </v-toolbar>
    <!--ALERT-->
    <v-alert
      :value="alert.value"
      type="error"
    >
      {{ alert.message }}
    </v-alert>
    <!--QR DIALOG-->
    <qr-dialog ref="qrDialog" />
    <!--DIALOG-->
    <edit-dialog
      ref="dialog"
      @save="getShipments"
      @error="showAlert"
    />
    <!--INSERT EMPTY-->
    <insert-empty ref="insertEmpty" />
    <!--DATATABLE-->
    <datatable
      ref="datatable"
      :search="search"
      @qr="openQrDialog"
      @insert="openInsertDialog"
      @edit-shipment="editShipment"
      @error="showAlert"
      @selected="setSelectedItems"
    />
  </v-container>
</template>

<script>
import Datatable from '@/components/Shipments/datatable.vue';
import Dialog from '@/components/Shipments/dialog.vue';
import QrDialog from '@/components/qrDialog.vue';
import InsertEmpty from '@/components/Shipments/insertEmpty.vue';
import Print from '../components/Shipments/print';

import deleteRequest from '../api/deleteRequest';

export default {
  components: {
    datatable: Datatable,
    'insert-empty': InsertEmpty,
    'edit-dialog': Dialog,
    'qr-dialog': QrDialog,
  },
  data: () => ({
    search: '',
    selected: [],
    loadingDelete: false,
    alert: {
      value: false,
      message: String,
    },
  }),
  watch: {},
  mounted() {
    this.getShipments();
  },
  methods: {
    showAlert(error) {
      console.log(error);
      this.alert = {
        value: true,
        message: '',
      };
      setTimeout(() => {
        this.alert.value = false;
      }, 2000);
    },
    openInsertDialog(shipmentId) {
      const { insertEmpty } = this.$refs;
      insertEmpty.open(shipmentId);
    },
    getShipments() {
      const { datatable } = this.$refs;
      datatable.getShipments();
    },
    printShipments() {
      const { datatable } = this.$refs;
      Print(datatable.shipments);
    },
    newShipment() {
      const { dialog } = this.$refs;
      dialog.open(false, null);
    },
    editShipment(shipment) {
      const { dialog } = this.$refs;
      dialog.open(true, shipment);
    },
    openQrDialog(data) {
      const { qrDialog } = this.$refs;
      qrDialog.open(data);
    },
    setSelectedItems(data) {
      this.selected = data;
    },
    deleteShipments() {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loadingDelete = true;
        const ids = this.selected.map(s => s._id).join(',');
        deleteRequest('/shipment/', `ids=${ids}`)
          .then((response) => {
            this.getShipments();
          })
          .catch((error) => { console.log(error); })
          .finally(() => { this.loadingDelete = false; });
      }
    },
  },
};
</script>
