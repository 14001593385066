<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card flat>
      <v-layout justify-end>
        <v-btn
          outline
          icon
          color="red"
          @click="dialog = false"
        >
          <v-icon >
            close
          </v-icon>
        </v-btn>
      </v-layout>
      <v-layout
        align-center
        justify-center
        wrap
        class="ml-2 mr-2"
      >
        <!-----------------------------------------NAME------------------------------------------>
        <v-flex
          xs6
          pa-1
        >
          <v-layout
            column
            wrap
          >
            <span class="caption">{{ $t('buyer_details.name') }}</span>
            <span class="body-2" v-if="buyerDetails && buyerDetails.checkout_information && buyerDetails.checkout_information.name">{{  buyerDetails.checkout_information.name }}</span>
            <span class="body-2" v-else-if="buyerDetails && buyerDetails.buyerDetails && buyerDetails.buyerDetails.name">{{  buyerDetails.buyerDetails.name }}</span>
          </v-layout>
        </v-flex>
        <!----------------------------------------SURNAME---------------------------------------->
        <v-flex
          xs6
          pa-1
        >
          <v-layout
            column
            wrap
          >
            <span class="caption">{{ $t('buyer_details.surname') }}</span>
            <span class="body-2" v-if="buyerDetails && buyerDetails.checkout_information && buyerDetails.checkout_information.surname">{{  buyerDetails.checkout_information.surname }}</span>
            <span class="body-2" v-else-if=" buyerDetails && buyerDetails.buyerDetails && buyerDetails.buyerDetails.surname">{{  buyerDetails.buyerDetails.surname }}</span>
          </v-layout>
        </v-flex>
        <!----------------------------------------ADDRESS---------------------------------------->
        <v-flex
          xs12
          pa-1
        >
          <v-layout
            column
            wrap
          >
            <span class="caption">{{ $t('buyer_details.address') }}</span>
            <span class="body-2">{{buyerDetails && buyerDetails.checkout_information ? buyerDetails.checkout_information.address : '/' }}</span>
    
          </v-layout>
        </v-flex>
        <!-----------------------------------------PHONE----------------------------------------->
        <v-flex
          xs12
          sm6
          pa-1
        >
          <v-layout
            column
            wrap
          >
            <span class="caption">{{ $t('buyer_details.phone') }}</span>
            <span class="body-2">{{buyerDetails && buyerDetails.checkout_information ? buyerDetails.checkout_information.phone : '/'}}</span>
          </v-layout>
        </v-flex>
        <!-----------------------------------------EMAIL----------------------------------------->
        <v-flex
          xs12
          sm6
          pa-1
        >
          <v-layout
            column
            wrap
          >
            <span class="caption">{{ $t('buyer_details.email') }}</span>
            <span class="body-2" v-if="buyerDetails && buyerDetails.checkout_information && buyerDetails.checkout_information.email">{{  buyerDetails.checkout_information.email }}</span>
            <span class="body-2" v-else-if="buyerDetails && buyerDetails.buyerDetails && buyerDetails.buyerDetails.email">{{  buyerDetails.buyerDetails.email }}</span>
          </v-layout>
        </v-flex>
        <!----------------------------------------MESSAGE---------------------------------------->
        <v-flex
          xs12
          pa-1
          mb-2
        >
          <v-layout
            column
            wrap
          >
            <span class="caption">{{ $t('buyer_details.message') }}</span>
            <span class="body-2">{{buyerDetails && buyerDetails.checkout_information ? buyerDetails.checkout_information.message : '/'}}</span>
          </v-layout>
        </v-flex>
        <!--------------------------------------------------------------------------------------->
      </v-layout>
      <!------------------------------------------ACTIONS------------------------------------------>
      <v-card-actions>
        <v-layout column>
          <v-flex xs12>
            <v-layout
              align-center
              row
            >
              <v-btn
                icon
                @click="print"
              >
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn
                v-if="buyerDetails && buyerDetails.buyerDetails && !buyerDetails.buyerDetails.contacted"
                :loading="loading"
                class="mr-2"
                color="primary"
                depressed
                small
                @click="contactBuyer"
              >
                {{ $t('buyer_details.contact_client') }}
              </v-btn>
              <v-btn
                v-else
                small
                depressed
                color="success"
              >
                {{ $t('buyer_details.contacted') }}
                <v-icon right>
                  done
                </v-icon>
              </v-btn>
              <v-spacer />
              <span
                v-if="buyerDetails && buyerDetails.buyerDetails && buyerDetails.buyerDetails.registered"
                class="title success--text mr-2"
              >{{ $t('buyer_details.registered') }}</span>
              <span
                v-else
                class="title error--text mr-2"
              >{{ $t('buyer_details.unregistered') }}</span>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-card-actions>
      <!------------------------------------------------------------------------------------------->
    </v-card>
  </v-dialog>
</template>

<script>
import { Printd } from 'printd';
import putRequest from '../api/putRequest';

function printDetails(locale, data) {
  const content = document.createElement('div');
  content.setAttribute('id', 'content');
  content.innerHTML = `
  <div class="main">
    <div>${locale.name}: ${data.name}</div>
    <div>${locale.surname}: ${data.surname}</div>
    <div>${locale.phone}: ${data.phone}</div>
    <div>${locale.address}: ${data.address}</div>
  </div>
  `;
  const css = [`
    .main {
      font-family: "Verdana";
      margin: 0 auto;
    }
  `];
  const d = new Printd();
  return d.print(content, css);
}

export default {
  data: () => ({
    dialog: false,
    loading: false,
    buyerDetails: {},
  }),
  watch: {
    dialog(value) {
      if (value === false) {
        this.buyerDetails = {};
      }
    },
  },
  methods: {
    showBuyerDetails(data) {
      this.buyerDetails = data;
      this.orderId = data.id;
      this.dialog = true;
    },
    print() {
      const locale = {
        name: this.$i18n.t('buyer_details.name'),
        surname: this.$i18n.t('buyer_details.surname'),
        address: this.$i18n.t('buyer_details.address'),
        phone: this.$i18n.t('buyer_details.phone'),
      };
      printDetails(locale, this.buyerDetails);
    },
    contactBuyer() {
      this.loading = true;
      putRequest('/order/contact-buyer/', `order_id=${this.orderId}`)
        .then((response) => {
          this.loading = false;
          this.buyerDetails.contacted = true;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>
