<template>
  <v-data-table
    :headers="headers"
    :items="reservations"
    :loading="loading"
    item-key="_id"
    hide-actions
    class="elevation-1"
  >
    <v-progress-linear
      slot="progress"
      color="red"
      indeterminate
    />
    <!--=======================================MAIN TABLE========================================-->
    <template
      slot="items"
      slot-scope="props"
    >
      <tr
        :class="props.expanded ? 'tableSelected pointer' : 'pointer'"
        @click="props.expanded = !props.expanded"
      >
        <!-----------------------------------------STATUS------------------------------------------>
        <td>
          <!--ACTIVE-->
          <v-chip
            v-if="props.item.status === 'Active'"
            label
            outline
            color="primary"
          >
            {{ $t('reservations.datatable.active') }}
          </v-chip>
          <!--SOLD-->
          <v-chip
            v-if="props.item.status === 'Sold'"
            label
            outline
            color="success"
          >
            {{ $t('reservations.datatable.sold') }}
          </v-chip>
          <!--EXPIRED-->
          <v-chip
            v-if="props.item.status === 'Expired'"
            label
            outline
            color="error"
          >
            {{ $t('reservations.datatable.expired') }}
          </v-chip>
          <!--CANCELLED-->
          <v-chip
            v-if="props.item.status === 'Cancelled'"
            label
            outline
            color="error"
          >
            {{ $t('reservations.datatable.cancelled') }}
          </v-chip>
        </td>
        <!----------------------------------------CUSTOMER----------------------------------------->
        <td class="text-xs">
          <span v-if="props.item.client_id">
            {{ props.item.client_id.name }} {{ props.item.client_id.surname }}
          </span>
        </td>
        <!-----------------------------------------COMMENT----------------------------------------->
        <td class="text-xs">
          {{ props.item.comment }}
        </td>
        <!------------------------------------------PRICE------------------------------------------>
        <td class="text-xs">
          {{ props.item.proposed_price }} {{ props.item.currency }}
        </td>
        <!---------------------------------EXPIRING TIME (ACTIVE)---------------------------------->
        <td
          v-if="$props.type === 'active'"
          class="text-xs"
        >
          {{ props.item.date_created | dateFromNow }}
        </td>
        <!---------------------------------CREATED DATE (INACTIVE)--------------------------------->
        <td
          v-if="$props.type === 'inactive'"
          class="text-xs"
        >
          {{ props.item.date_created | date }}
        </td>
        <!-----------------------------------------ACTIONS----------------------------------------->
        <td class="text-xs">
          <!--SELL-->
          <v-btn
            v-if="$props.type === 'active'"
            icon
            @click="sell(props.item._id)"
          >
            <v-icon color="grey darken-2">
              done
            </v-icon>
          </v-btn>
          <!--CANCEL-->
          <v-btn
            v-if="$props.type === 'active'"
            icon
            @click="cancelReservation(props.item._id)"
          >
            <v-icon color="grey darken-2">
              cancel
            </v-icon>
          </v-btn>
          <!--DELETE-->
          <v-btn
            v-if="$props.type === 'inactive'"
            icon
            @click="deleteReservation(props.item._id)"
          >
            <v-icon color="grey darken-2">
              delete
            </v-icon>
          </v-btn>
        </td>
        <!----------------------------------------------------------------------------------------->
      </tr>
    </template>
    <!--====================================EXPANDED SECTION=====================================-->
    <template
      slot="expand"
      slot-scope="props"
    >
      <!--------------------------------------INVENTORY PARTS-------------------------------------->
      <!--TOOLBAR-->
      <v-toolbar
        flat
        dark
        dense
        color="red darken-3"
      >
        <v-toolbar-title>
          {{ $t('reservations.datatable.inventory_parts') }}
        </v-toolbar-title>
      </v-toolbar>
      <!--DATATABLE-->
      <v-data-table
        :headers="partsHeaders"
        :items="props.item.parts"
        item-key="index"
        class="tableBorder"
        hide-actions
      >
        <template
          slot="items"
          slot-scope="part"
        >
          <!------------------------------------------ID------------------------------------------->
          <!--OLD ID-->
          <td v-if="part.item.id.old_id && part.item.id.old_id !== undefined">
            <v-layout
              ma-1
              align-center
              justify-center
              column
            >
              <qr-code
                :size="60"
                :text="part.item.id.old_id"
              />
              <!-- eslint-disable-next-line max-len -->
              {{ part.item.id.old_id.slice(part.item.id.old_id.length-6, part.item.id.old_id.length) }}
            </v-layout>
          </td>
          <!--NEW ID-->
          <td v-else>
            <v-layout
              ma-1
              align-center
              justify-center
              column
            >
              <qr-code
                :size="60"
                :text="part.item.id._id"
              />
              {{ part.item.id._id.slice(part.item.id._id.length-6, part.item.id._id.length) }}
            </v-layout>
          </td>
          <!----------------------------------------ACTIONS---------------------------------------->
          <td>
            <v-layout
              row
              align-center
            >
              <!--DETAILS-->
              <v-btn
                icon
                @click="details(part.item.id)"
              >
                <v-icon color="grey darken-2">
                  view_list
                </v-icon>
              </v-btn>
            </v-layout>
          </td>
          <!-----------------------------------------GROUP----------------------------------------->
          <td>
            <v-layout column>
              <v-flex>
                <span
                  v-if="part.item.id.infoCar
                    && part.item.id.infoCar.description
                    && part.item.id.infoCar.description.descriptionId
                    && part.item.id.infoCar.description.descriptionId.category"
                  class="text-xs font-weight-bold"
                >
                  <!-- eslint-disable-next-line max-len -->
                  {{ part.item.id.infoCar.description.descriptionId.category[$store.getters.language] }}
                </span>
              </v-flex>
            </v-layout>
          </td>
          <!-----------------------------------------TITLE----------------------------------------->
          <td class="text-xs">
            <v-layout column>
              <v-flex v-if="part.item.id.infoCar && part.item.id.infoCar.description">
                <span
                  v-if="part.item.id.infoCar.description.descriptionId
                    && part.item.id.infoCar.description.descriptionId.title"
                  class="text-xs font-weight-bold"
                >
                  <!-- eslint-disable-next-line max-len -->
                  {{ part.item.id.infoCar.description.descriptionId.title[$store.getters.language] }}
                </span>
                <span
                  v-if="part.item.id.infoCar.description.positionCode"
                  class="text-xs"
                >
                  {{ getPosition(part.item.id.infoCar.description.positionCode) }}
                </span>
              </v-flex>
            </v-layout>
          </td>
          <!-----------------------------------MANUFACTURER CODE----------------------------------->
          <td class="text-xs">
            {{ part.item.id.manufacturer_code }}
          </td>
          <!----------------------------------------IMAGES----------------------------------------->
          <td>
            <v-layout
              align-center
              justify-center
            >
              <img
                :src="`${server}/images?q=${props.item.images && props.item.images.length
                  ? props.item.images[0].thumb : ''}`"
                class="ma-1 pointer"
                height="75"
                width="100"
                @click="showImages(part.item.id.images)"
              >
            </v-layout>
          </td>
          <!-----------------------------------------NOTES----------------------------------------->
          <td class="text-xs">
            {{ part.item.id.notes }}
          </td>
          <!-----------------------------------------PRICE----------------------------------------->
          <td class="text-xs">
            {{ part.item.id.price }} {{ part.item.id.currency }}
          </td>
          <!---------------------------------------LOCATION---------------------------------------->
          <td
            v-if="props.item.id.shelf_data"
            class="text-xs"
          >
            <span>
              {{ props.item.id.shelf_data.shelf.room_name }} -
              {{ props.item.id.shelf_data.shelf.location_code }}:
              {{ props.item.id.shelf_data.shelf.x }},
              {{ props.item.id.shelf_data.shelf.y }}
            </span>

            <div class="text-no-wrap ">
              <span class="caption">Mag: </span>
              <span class="font-weight-bold caption">{{ props.item.id.shelf_data.repo }}</span>
            </div>
          </td>
          <td
            v-else
            class="text-xs"
          >
            -
          </td>
          <!----------------------------------------QUALITY---------------------------------------->
          <td v-if="part.item.id.quality">
            <div style="width: 150px">
              <v-rating
                :value="part.item.id.quality.rating"
                color="red"
                background-color="red darken-4"
                readonly
                small
              />
            </div>
          </td>
          <td v-else />
          <!-----------------------------------------DATE------------------------------------------>
          <td>{{ part.item.id.date_created | date }}</td>
        <!----------------------------------------------------------------------------------------->
        </template>
      </v-data-table>
      <!-------------------------------------RESERVATION PARTS------------------------------------->
      <!--TOOLBAR-->
      <v-toolbar
        v-if="!($props.type === 'inactive' && props.item.status === 'Expired')"
        flat
        dark
        dense
        color="red darken-3"
      >
        <v-toolbar-title>
          {{ $t('reservations.datatable.reservation_parts') }}
        </v-toolbar-title>
      </v-toolbar>
      <!--DATATABLE-->
      <v-data-table
        v-if="!($props.type === 'inactive' && props.item.status === 'Expired')"
        :headers="reservationPartsHeaders"
        :items="props.item.reservationParts"
        item-key="index"
        class="tableBorder"
        hide-actions
      >
        <template
          slot="items"
          slot-scope="part"
        >
          <td> {{ part.item.description }} </td>
          <td> {{ part.item.notes }} </td>
          <img
            :src="`${server}/images/reservation?q=${part.item._id}-${part.item.images[0]
            }`"
            class="ma-1 pointer"
            height="75"
            width="100"
            @click="showResImages(part.item._id, part.item.images)"
          >
          <td> {{ part.item.price }} {{ part.item.currency }} </td>
        </template>
      </v-data-table>
    </template>
    <!--=========================================================================================-->
  </v-data-table>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-qr-generator';

import getRequest from '../../api/getRequest';
import postRequest from '../../api/postRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  components: {
    'qr-code': VueQrCode,
  },
  filters: {
    dateFromNow(dateCreated) {
      const creationTime = new Date(dateCreated).getTime();
      const expireTime = creationTime + 259200000; // 72 hours after creation.
      const expireDate = new Date(expireTime);
      moment.locale(localStorage.getItem('language'));
      return moment(expireDate).fromNow();
    },
    date(dateCreated) {
      moment.locale(localStorage.getItem('language'));
      return moment(dateCreated).format('LLL');
    },
  },
  props: {
    type: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    loading: false,
    headers: [
      { text: 'Status', sortable: false, value: 'status' },
      { text: 'Customer', sortable: false, value: 'customer' },
      { text: 'Comment', sortable: false, value: 'comment' },
      { text: 'Price', sortable: false, value: 'price' },
      { text: 'Date', sortable: true, value: 'date_created' },
      { text: 'Actions', sortable: false, value: 'actions' },
    ],
    partsHeaders: [
      { text: 'QR Code', sortable: true, value: '_id' },
      { text: 'Actions', sortable: false, value: '' },
      { text: 'Group', sortable: true, value: 'group' },
      { text: 'Title', sortable: true, value: 'title' },
      { text: 'Manufacturer Code', sortable: false, value: 'manufacturer_code' },
      { text: 'Images', sortable: false, value: '' },
      { text: 'Notes', sortable: false, value: 'notes' },
      { text: 'Price', sortable: true, value: 'price' },
      { text: 'Location', sortable: true, value: 'shelf_data.shelf.room_name' },
      { text: 'Quality', sortable: true, value: 'quality' },
      { text: 'Date Created', sortable: true, value: 'date_created' },
    ],
    reservationPartsHeaders: [
      { text: 'Description', sortable: false, value: 'description' },
      { text: 'Notes', sortable: false, value: 'notes' },
      { text: 'Images', sortable: false, value: '' },
      { text: 'Price', sortable: false, value: 'price' },
    ],
    reservations: [],
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
  },
  mounted() {
    this.getLocale();
    this.getReservations();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('reservations.datatable.status');
      this.headers[1].text = this.$i18n.t('reservations.datatable.customer');
      this.headers[2].text = this.$i18n.t('reservations.datatable.comment');
      this.headers[3].text = this.$i18n.t('reservations.datatable.price');
      this.headers[4].text = this.$props.type === 'active'
        ? this.$i18n.t('reservations.datatable.expiring_time')
        : this.$i18n.t('reservations.datatable.date_created');
      this.headers[5].text = this.$i18n.t('reservations.datatable.actions');
      this.partsHeaders[0].text = this.$i18n.t('reservations.parts.datatable.qr_code');
      this.partsHeaders[1].text = this.$i18n.t('reservations.parts.datatable.actions');
      this.partsHeaders[2].text = this.$i18n.t('reservations.parts.datatable.group');
      this.partsHeaders[3].text = this.$i18n.t('reservations.parts.datatable.title');
      this.partsHeaders[4].text = this.$i18n.t('reservations.parts.datatable.manufacturer_code');
      this.partsHeaders[5].text = this.$i18n.t('reservations.parts.datatable.images');
      this.partsHeaders[6].text = this.$i18n.t('reservations.parts.datatable.notes');
      this.partsHeaders[7].text = this.$i18n.t('reservations.parts.datatable.price');
      this.partsHeaders[8].text = this.$i18n.t('reservations.parts.datatable.location');
      this.partsHeaders[9].text = this.$i18n.t('reservations.parts.datatable.quality');
      this.partsHeaders[10].text = this.$i18n.t('reservations.parts.datatable.date_created');
      this.reservationPartsHeaders[0].text = this.$i18n.t('reservations.datatable.description');
      this.reservationPartsHeaders[1].text = this.$i18n.t('reservations.datatable.notes');
      this.reservationPartsHeaders[2].text = this.$i18n.t('reservations.datatable.images');
      this.reservationPartsHeaders[3].text = this.$i18n.t('reservations.datatable.price');
    },
    getReservations() {
      this.loading = true;
      getRequest(
        `/reservation/${this.$props.type}`,
        `role=${this.$store.getters.role}&user_id=${this.$store.getters.userId}&institution_id=${this.$store.getters.institutionId}`,
      )
        .then((response) => {
          this.reservations = response.reservations;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    showImages(images) {
      this.$emit('images', { type: 'string', images });
    },
    showResImages(id, images) {
      this.$emit('images', {
        type: 'string', id, images, path: 'reservations',
      });
    },
    details(data) {
      this.$emit('details', data);
    },
    sell(id) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('reservations.confirm_reservation_alert'));
      if (c) {
        this.loading = true;
        postRequest.object('/reservation/sell', { id })
          .then((response) => {
            this.$emit('sold');
          })
          .catch((error) => { console.log(error); })
          .finally(() => { this.loading = false; });
      }
    },
    cancelReservation(id) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('reservations.cancel_reservation_alert'));
      if (c) {
        this.loading = true;
        postRequest.object('/reservation/cancel', { id })
          .then((response) => {
            this.getReservations();
          })
          .catch((error) => { console.log(error); })
          .finally(() => { this.loading = false; });
      }
    },
    deleteReservation(id) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c) {
        this.loading = true;
        deleteRequest('/reservation', `id=${id}`)
          .then((response) => {
            this.loading = false;
            this.$emit('deleted');
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      }
    },
    getPosition(code) {
      switch (code) {
        case 'S001':
          return this.$i18n.t('left');
        case 'S002':
          return this.$i18n.t('right');
        default:
          return '';
      }
    },
  },
};
</script>
