<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <!--TITLE-->
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t("clients.label") }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
      <!--SEARCH FIELD-->
      <v-text-field
        v-model="search"
        :label="$t('clients.search')"
        flat
        solo
        color="red darken-1"
        append-icon="search"
        single-line
        hide-details
      />
      <v-spacer class="hidden-sm-and-down" />
      <v-btn
        v-if="selected.length > 0"
        color="grey darken-4"
        dark
        :loading="loadingDelete"
        @click="deleteClients"
      >
        {{ $t("clients.delete") }}
      </v-btn>
      <v-btn
        v-if="$store.getters.role == 'LekoAdmin'
          || $store.getters.role == 'LekoEmployee'
          || $store.getters.role == 'InstitutionAdmin'
          || $store.getters.role == 'InstitutionManager'
          || $store.getters.role == 'SuperAdmin'
        "
        color="grey darken-4"
        dark
        @click="newClient"
      >
        {{ $t("clients.button") }}
      </v-btn>
    </v-toolbar>
    <!--ALERT-->
    <v-alert
      :value="alert.value"
      type="error"
    >
      {{ alert.message }}
    </v-alert>
    <!--EDIT DIALOG-->
    <edit-dialog
      ref="editDialog"
      @saved="getClients"
      @add-vehicle="openVehicleDialog"
    />
    <!--DETAILS DIALOG-->
    <details-dialog
      ref="detailsDialog"
      @saved="getClients"
    />
    <!--DATATABLE-->
    <datatable
      ref="datatable"
      :search="search"
      @edit="openEditDialog"
      @details="openDetailsDialog"
      @selected="setSelectedItems"
    />
    <!--VEHICLE DIALOG-->
    <vehicle-dialog
      ref="vehicleDialog"
      @save="saveVehicle"
      @close="closeVehicleDialog"
    />
  </v-container>
</template>

<script>
// import ManageClients from '@/components/Clients/Clients.vue';
import EditDialog from '@/components/Clients/editDialog.vue';
import VehicleDialog from '@/components/Clients/vehicleDialog.vue';
import DetailsDialog from '@/components/Clients/detailsDialog.vue';
import Datatable from '@/components/Clients/datatable.vue';

import deleteRequest from '../api/deleteRequest';

export default {
  components: {
    // 'manage-clients': ManageClients,
    'edit-dialog': EditDialog,
    'details-dialog': DetailsDialog,
    'vehicle-dialog': VehicleDialog,
    datatable: Datatable,
  },
  data: () => ({
    search: '',
    selected: [],
    loadingDelete: false,
    alert: {
      value: false,
      message: '',
    },
  }),
  watch: {},
  methods: {
    newClient() {
      const { editDialog } = this.$refs;
      editDialog.open(false, null, this.$store.getters.institutionId);
    },
    getClients() {
      const { datatable } = this.$refs;
      datatable.getClients();
    },
    openEditDialog(data) {
      const { editDialog } = this.$refs;
      editDialog.open(true, data, this.$store.getters.institutionId);
    },
    openDetailsDialog(id) {
      const { detailsDialog } = this.$refs;
      detailsDialog.open(id);
    },
    openVehicleDialog(data) {
      const { vehicleDialog } = this.$refs;
      vehicleDialog.open(data);
    },
    closeVehicleDialog() {
      const { editDialog } = this.$refs;
      editDialog.show();
    },
    saveVehicle(data) {
      const { editDialog } = this.$refs;
      editDialog.addVehicle(data);
    },
    setSelectedItems(data) {
      this.selected = data;
    },
    deleteClients() {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loadingDelete = true;
        const ids = this.selected.map(s => s._id).join(',');
        deleteRequest('/client/', `ids=${ids}`)
          .then((response) => {
            this.getClients();
          })
          .catch((error) => { console.log(error); })
          .finally(() => { this.loadingDelete = false; });
      }
    },
  },
};
</script>
