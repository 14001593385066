<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title class="redColor">
        <span
          v-if="edit == true"
          class="headline"
        >{{ $t('warranties.form.edit_warranty') }}</span>
        <span
          v-if="edit == false"
          class="headline"
        >{{ $t('warranties.form.create_warranty') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="warranty.title"
                  :rules="rules.requiredLetters"
                  :label="$t('warranties.form.title')"
                  color="red darken-1"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="warranty.content"
                  :rules="rules.requiredLetters"
                  :label="$t('warranties.form.content')"
                  color="red darken-1"
                />
              </v-flex>
              <v-flex xs12>
                <label for="content_document">
                  {{ $t('warranties.form.content_document') }}
                </label>
                <br>
                <input
                  id="contentDocument"
                  ref="contentDocument"
                  type="file"
                  accept=".pdf"
                  name="contentDocument"
                  enctype="multipart/form-data"
                  @change="getDocumentFile"
                >
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="warranty.expire_date"
                  :rules="rules.required"
                  :label="$t('warranties.form.expire')"
                  :min="currentDate"
                  color="red darken-1"
                  type="date"
                  oninput="validity.valid||(value='');"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="returnDays"
                  :rules="rules.required"
                  :label="$t('warranties.form.warranty_days')"
                  :min="1"
                  :max="365"
                  color="red darken-1"
                  type="number"
                  oninput="validity.valid||(value='');"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="warranty.return_before_warranty"
                  :rules="rules.required"
                  :label="$t('warranties.form.return')"
                  :min="currentDate"
                  color="red darken-1"
                  type="date"
                  oninput="validity.valid||(value='');"
                />
              </v-flex>
              <v-flex xs12>
                <v-select
                  v-model="warranty.language"
                  :items="langItems"
                  :rules="rules.required"
                  :label="$t('warranties.form.language')"
                  color="red darken-1"
                  item-text="title"
                  item-value="value"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('warranties.form.close') }}
        </v-btn>
        <v-btn
          :disabled="!valid"
          :loading="loading"
          color="blue darken-1"
          flat
          @click.native="save"
        >
          {{ $t('warranties.form.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import putRequest from '../../api/putRequest';
import postRequest from '../../api/postRequest';


export default {
  data: () => ({
    dialog: false,
    loading: false,
    edit: null,
    valid: false,
    warranty: {},
    document: {},
    returnDays: null,
    langItems: [
      {
        title: 'Albanian',
        value: 'al',
      },
      {
        title: 'English',
        value: 'en',
      },
      {
        title: 'Italian',
        value: 'it',
      },
    ],
    rules: {
      requiredLetters: [
        v => !!v || 'Please fill this field',
        v => /[A-Za-z]/.test(v) || 'Only letters are allowed',
      ],
      requiredNumbers: [
        v => !!v || 'Please fill this field',
        v => /[0-9]/.test(v) || 'Only numbers are allowed',
      ],
      required: [v => !!v || 'Please fill this field'],
    },
  }),
  computed: {
    currentDate: () => new Date().toISOString().split('T')[0],
  },
  methods: {
    open(edit, data) {
      this.edit = edit;
      if (data) {
        this.warranty = data;
        this.returnDays = data.return_days ? data.return_days / 86400 : null;
      }
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.loading = false;
      this.edit = null;
      this.warranty = {};
      this.returnDays = null;
      this.$emit('close');
    },
    async save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.warranty.return_days = this.returnDays * 86400;
        try {
          if (this.edit) {
            const response = await putRequest('/warranty/', `id=${this.warranty._id}`, this.warranty);
            await this.uploadDocument(response.data.id);
            this.close();
          } else {
            const response = await postRequest.object('/warranty/', this.warranty);
            await this.uploadDocument(response.data.id);
            this.close();
          }
        } catch (error) {
          this.$emit('error', error);
          this.close();
        }
      }
    },
    getDocumentFile() {
      this.warranty.content_document = this.$refs.contentDocument.files[0].name;
      [this.document] = this.$refs.content_document.files;
    },
    uploadDocument(documentId) {
      const formData = new FormData();
      formData.append('document', this.document);
      return new Promise(async (resolve, reject) => {
        try {
          await postRequest.formData('/warranty/document/', `q=${documentId}`, formData);
          resolve();
        } catch (error) { reject(error); }
      });
    },
  },
};

</script>
