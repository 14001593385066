<template>
  <v-dialog
    v-model="dialog"
    width="800"
  >
    <v-card flat>
      <v-toolbar color="red darken-3">
        <v-toolbar-title>{{ $t('parts.movement_history.label') }}</v-toolbar-title>
        <v-spacer />
        <span class="title">{{ count }}</span>
        <v-flex
          xs2
          sm1
        >
          <v-btn
            outline
            icon
            large
            color="black"
            @click="dialog = false"
          >
            <v-icon>
              close
            </v-icon>
          </v-btn>
        </v-flex>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :loading="loading"
        class="elevation-1"
      >
        <v-progress-linear
          slot="progress"
          color="red"
          indeterminate
        />
        <template v-slot:items="props">
          <!----------------------------------------STATUS----------------------------------------->
          <td class="text-xs">
            {{ getStatusName(props.item.status) }}
          </td>
          <!-----------------------------------------USER------------------------------------------>
          <td
            v-if="props.item.user"
            class="text-xs"
          >
            {{ props.item.user.name }} {{ props.item.user.surname }}
          </td>
          <td v-else />
          <!----------------------------------------CLIENT----------------------------------------->
          <td
            v-if="props.item.client"
            class="text-xs"
          >
            {{ props.item.client.name }}
          </td>
          <td v-else />
          <!-----------------------------------------DATE------------------------------------------>
          <td class="text-xs">
            {{ props.item.date | moment }}
          </td>
          <!--------------------------------------------------------------------------------------->
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import getRequest from '../api/getRequest';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LLL');
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
    items: [],
    count: null,
    headers: [
      {
        text: 'Status',
        sortable: false,
        value: 'status',
      },
      {
        text: 'User',
        sortable: false,
        value: 'user.name',
      },
      {
        text: 'Client',
        sortable: false,
        value: 'client.name',
      },
      {
        text: 'Date',
        sortable: true,
        value: 'date',
      },
    ],
  }),
  watch: {
    dialog(value) {
      if (!value) {
        this.items = [];
      }
    },
  },
  mounted() {
    this.getLocale();
  },
  methods: {
    getStatusName(status) {
      switch (status) {
        case 0.1:
          return this.$i18n.t('parts.movement_history.status.inserted_empty_parts');
        case 0.2:
          return this.$i18n.t('parts.movement_history.status.deleted_empty_parts');
        case 1.0:
          return this.$i18n.t('parts.movement_history.status.inserted_temporary_parts');
        case 1.5:
          return this.$i18n.t('parts.movement_history.status.deleted_temporary_parts');
        case 1.1:
          return this.$i18n.t('parts.movement_history.status.sent_for_approval');
        case 1.2:
          return this.$i18n.t('parts.movement_history.status.sent_for_review');
        case 1.3:
          return this.$i18n.t('parts.movement_history.status.refused');
        case 1.4:
          return this.$i18n.t('parts.movement_history.status.approved');
        case 1.6:
          return this.$i18n.t('parts.movement_history.status.saved_approve_parts');
        case 2.0:
          return this.$i18n.t('parts.movement_history.status.inserted_inventory');
        case 2.1:
          return this.$i18n.t('parts.movement_history.status.deleted_from_inventory');
        case 3.0:
          return this.$i18n.t('parts.movement_history.status.returned_sales');
        case 3.1:
          return this.$i18n.t('parts.movement_history.status.returned_shop');
        case 4.0:
          return this.$i18n.t('parts.movement_history.status.re_entered');
        case 5.0:
          return this.$i18n.t('parts.movement_history.status.found');
        case 6.0:
          return this.$i18n.t('parts.movement_history.status.sold');
        case 6.1:
          return this.$i18n.t('parts.movement_history.status.sold_online');
        case 6.2:
          return this.$i18n.t('parts.movement_history.status.sold_ebay');
        case 6.3:
          return this.$i18n.t('parts.movement_history.status.sold_ovoko');
        case 6.4:
          return this.$i18n.t('parts.movement_history.status.sold_ebay_leko');
        case 7.0:
          return this.$i18n.t('parts.movement_history.status.lost');
        case 8.0:
          return this.$i18n.t('parts.movement_history.status.damaged');
        case 8.1:
          return this.$i18n.t('parts.movement_history.status.damaged_sales');
        case 8.2:
          return this.$i18n.t('parts.movement_history.status.damaged_shop');
        case 8.3:
          return this.$i18n.t('parts.movement_history.status.damaged_lost');
        default:
          return '';
      }
    },
    getLocale() {
      this.headers[0].text = this.$i18n.t('parts.movement_history.table.status');
      this.headers[1].text = this.$i18n.t('parts.movement_history.table.user');
      this.headers[2].text = this.$i18n.t('parts.movement_history.table.client');
      this.headers[3].text = this.$i18n.t('parts.movement_history.table.date');
    },
    open(part) {
      this.dialog = true;
      this.loading = true;
      getRequest(`/history/part/single/${part._id}`, part.old_id ? `old_id=${part.old_id}` : '')
        .then((response) => {
          this.loading = false;
          this.items = response.history;
          this.count = response.count;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>
