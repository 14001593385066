<template>
  <div>
    <!-------------------------------------------TOOLBAR------------------------------------------->
    <toolbar
      :items="datatableItems"
      @search="search"
      @adv-search="advancedSearch"
      @clear-search="clearSearch"
      @automatic-approval="openApprovalDialog"
    />
    <!------------------------------------------DATATABLE------------------------------------------>
    <datatable
      :search="searchObject"
      @items="getDatatableItems"
      @qr="openQrDialog"
      @images="openImagesCarousel"
      @infocar-cad="openInfoCarCad"
      @view-car="openCarDialog"
      @actions="openActionsDialog"
    />
    <!--QR DIALOG-->
    <qr-dialog ref="qrDialog" />
    <!--CAROUSEL-->
    <img-carousel ref="imgCarousel" />
    <!---------------------------------------ACTIONS DIALOG---------------------------------------->
    <actions-dialog
      ref="actionsDialog"
      :hide="hideActions"
      @show-kromeda="openKromedaDialog"
      @open-img-dialog="openImgDialog"
      @approve="''"
      @review="''"
      @refuse="''"
      @close="getParts"
      @add-ebay="addEbayItem"
      @show-error-modal="showActionsErrorModal"
    />
    <!----------------------------------------IMAGE DIALOG----------------------------------------->
    <img-dialog
      ref="imgDialog"
      @close="closeImgDialog"
    />
    <!---------------------------------------APPROVAL DIALOG--------------------------------------->
    <approval-dialog ref="approvalDialog" />
    <!-----------------------------------------INFOCAR CAD----------------------------------------->
    <infocar-cad ref="infoCarCad" />
    <!---------------------------------------KROMEDA DIALOG---------------------------------------->
    <kromeda-dialog
      ref="kromedaDialog"
      @close="closeKromedaDialog"
    />
    <!-----------------------------------------CAR DIALOG------------------------------------------>
    <car-dialog ref="carDialog" />
    <!-------------------------------------EBAY DIALOG---------------------------------------->
    <ebay
      ref="ebayFile"
      @close="getParts"
    />
  </div>
</template>

<script>
import Toolbar from '@/components/ApproveParts/toolbar.vue';
import Datatable from '@/components/ApproveParts/datatable.vue';
import ActionsDialog from '@/components/ApproveParts/actionsDialog.vue';
import KromedaDialog from '@/components/ApproveParts/kromedaDialog.vue';
import ApprovalDialog from '@/components/ApproveParts/approvalDialog.vue';
import ebayDialog from '@/components/Inventory/ebay.vue';
import QrDialog from '@/components/qrDialog.vue';
import ImgCarousel from '@/components/imgCarousel.vue';
import ImgDialog from '@/components/imgDialog.vue';
import InfoCarCad from '@/components/infoCarCad.vue';
import CarDialog from '@/components/carDialog.vue';

export default {
  components: {
    toolbar: Toolbar,
    datatable: Datatable,
    'actions-dialog': ActionsDialog,
    'kromeda-dialog': KromedaDialog,
    'qr-dialog': QrDialog,
    'img-carousel': ImgCarousel,
    'img-dialog': ImgDialog,
    'approval-dialog': ApprovalDialog,
    'infocar-cad': InfoCarCad,
    'car-dialog': CarDialog,
    ebay: ebayDialog,
  },
  data: () => ({
    datatableItems: [],
    hideActions: false,
    searchObject: {
      type: null,
      data: {},
    },
  }),
  methods: {
    getParts() { // Executed when approving, refusing or reviewing part.
      if (this.searchObject.type === null) {
        this.clearSearch();
      } else if (this.searchObject.type === 'regularSearch') {
        this.search(this.searchObject.data);
      } else if (this.searchObject.type === 'advancedSearch') {
        this.advancedSearch(this.searchObject.data);
      }
    },
    /* SEARCH ----------------------------------------------------------------------------------- */
    search(data) { this.searchObject = { type: 'regularSearch', data }; },
    advancedSearch(data) { this.searchObject = { type: 'advancedSearch', data }; },
    clearSearch() { this.searchObject = { type: null, data: {} }; },
    /* ACTIONS DIALOG --------------------------------------------------------------------------- */
    openActionsDialog(data) {
      const { actionsDialog } = this.$refs;
      actionsDialog.open(data);
    },
    /* KROMEDA DIALOG --------------------------------------------------------------------------- */
    openKromedaDialog(data) {
      const { kromedaDialog } = this.$refs;
      kromedaDialog.open(data);
      this.hideActions = true;
    },
    closeKromedaDialog() {
      this.hideActions = false;
    },
    /* QR DIALOG -------------------------------------------------------------------------------- */
    openQrDialog(id) {
      const { qrDialog } = this.$refs;
      qrDialog.open(id);
    },
    /* IMAGE DIALOG ----------------------------------------------------------------------------- */
    openImgDialog(image) {
      const { imgDialog } = this.$refs;
      imgDialog.open(image);
      this.hideActions = true;
    },
    closeImgDialog() {
      this.hideActions = false;
    },
    /* IMAGES CAROUSEL -------------------------------------------------------------------------- */
    openImagesCarousel(data) {
      const { imgCarousel } = this.$refs;
      imgCarousel.open(data);
    },
    /* DATATABLE ITEMS -------------------------------------------------------------------------- */
    getDatatableItems(data) {
      this.datatableItems = data;
    },
    /* CAD -------------------------------------------------------------------------------------- */
    openInfoCarCad(data) {
      const { infoCarCad } = this.$refs;
      infoCarCad.open({
        brandCode: data.brand.brandCode,
        modelCode: data.model.modelCode,
        versionCode: data.version.versionCode,
      });
    },
    /* AUTOMATIC APPROVAL ----------------------------------------------------------------------- */
    openApprovalDialog() {
      const { approvalDialog } = this.$refs;
      approvalDialog.open();
    },
    /* CAR DIALOG ------------------------------------------------------------------------------- */
    openCarDialog(data) {
      const { carDialog } = this.$refs;
      carDialog.open(data);
    },
    addEbayItem(data) {
      const { ebayFile } = this.$refs;
      ebayFile.acceptAddEbay(data);
    },
    showActionsErrorModal(data) {
      const { ebayFile } = this.$refs;
      ebayFile.showActionsErrorModal(data);
    },
  },
};
</script>
