<template>
  <v-data-table
    :pagination.sync="pagination"
    :headers="headers"
    :items="users"
    :total-items="totalItems"
    :rows-per-page-items="[5,10,25]"
    item-key="_id"
    :loading="loading"
    class="elevation-1"
  >
    <v-progress-linear
      slot="progress"
      color="red"
      indeterminate
    />
    <template
      slot="items"
      slot-scope="props"
    >
      <tr
        v-if="props.item.email !== 'nikolin@lekotech.com' || $store.getters.role === 'SuperAdmin'"
        :class="{tableRowColorSelected : props.index == activeRow}"
        class="tableRowColor"
        @click="activeRow = props.index"
      >
        <td class="text-xs">
          {{ props.index + 1 }}
        </td>
        <td>
          <v-layout
            column
            wrap
          >
            <v-flex>
              <v-icon>person</v-icon>
              {{ props.item.name }} {{ props.item.surname }}
            </v-flex>

            <v-flex>
              <v-icon>email</v-icon>
              {{ props.item.email }}
            </v-flex>

            <v-flex>
              <v-icon>phone</v-icon>
              {{ props.item.phone }}
            </v-flex>

            <v-flex>
              <v-icon>calendar_month</v-icon>
              {{ props.item.date_created | moment }}
            </v-flex>
          </v-layout>
        </td>
        <td>
          <v-progress-circular
            v-if="props.item._loading"
            indeterminate
            color="red"
          />

          <v-layout
            v-else-if="props.item._parts"
            column
            wrap
          >
            <v-flex>
              {{ $t('users.table.temporary') }} {{ props.item._parts.InsertedTemporaryParts }}
            </v-flex>
            <v-flex>
              {{ $t('users.table.approved') }} {{ props.item._parts.ApprovedParts }}
            </v-flex>
            <v-flex>
              {{ $t('users.table.inventory') }} {{ props.item._parts.InsertedInventory }}
            </v-flex>
            <v-flex>
              {{ $t('users.table.sold') }} {{ props.item._parts.Sold }}
            </v-flex>
          </v-layout>

          <!-- trigger the method -->
          {{ getParts(props.item._id) }}
        </td>
        <td class="text-xs">
          <v-layout
            column
            wrap
          >
            <v-flex>
              {{ props.item.last_login_date | momentLD }}
            </v-flex>
            <v-flex>
              {{ props.item.last_login_date | momentLT }}
            </v-flex>
          </v-layout>
        </td>
        <td class="text-xs">
          {{ props.item.date | moment }}
        </td>
        <!-- <td class="text-xs">
          {{ props.item.city }}
        </td>
        <td class="text-xs">
          {{ props.item.state }}
        </td> -->
        <td class="text-xs">
          {{ props.item.status }}
        </td>
        <td class="text-xs">
          {{ props.item.institution_id.title }}
        </td>
        <td
          class="justify-center"
        >
          <v-layout row>
            <v-icon
              v-if="$store.getters.role == 'SuperAdmin' && props.item.disabled === false &&
                props.item._id.toString() !== $store.getters.userId.toString()"
              class="mr-2 pointer"
              @click="disableUser(props.item._id)"
            >
              lock
            </v-icon>
            <v-icon
              v-else-if="$store.getters.role == 'SuperAdmin' && props.item.disabled === true &&
                props.item._id.toString() !== $store.getters.userId.toString()"
              class="mr-2 pointer"
              @click="enableUser(props.item._id)"
            >
              no_encryption
            </v-icon>
            <v-icon
              v-if="$store.getters.role == 'LekoAdmin'
                || $store.getters.role == 'LekoEmployee'
                || $store.getters.role == 'InstitutionAdmin'
                || $store.getters.role == 'SuperAdmin'
              "
              class="mr-2 pointer"
              @click="editUser(props.item)"
            >
              edit
            </v-icon>
            <v-icon
              v-if="($store.getters.role == 'LekoAdmin'
                || $store.getters.role == 'LekoEmployee'
                || $store.getters.role == 'InstitutionAdmin'
                || $store.getters.role == 'SuperAdmin')
                && props.item._id.toString() !== $store.getters.userId.toString()
              "
              class="pointer"
              @click="deleteUser(props.item._id)"
            >
              delete
            </v-icon>
          </v-layout>
        </td>
      </tr>
    </template>
    <span hidden> {{ tick }} </span>
  </v-data-table>
</template>

<script>
import moment from 'moment';
import getRequest from '../../api/getRequest';
import deleteRequest from '../../api/deleteRequest';
import putRequest from '../../api/putRequest';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('MMMM Do YYYY');
    },
    momentLD(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('DD/MM/YYYY');
    },
    momentLT(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('HH:mm:ss');
    },
  },
  props: ['search', 'institution'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    loading: false,
    activeRow: null,
    pagination: {
      rowsPerPage: 5,
      page: 1,
      sortBy: '_id',
      descending: true,
    },
    headers: [
      {
        text: '',
        sortable: false,
        value: 'count',
      },
      {
        text: '',
        sortable: true,
        value: 'name',
      },
      {
        text: '',
        sortable: true,
        value: 'parts',
      },
      {
        text: '',
        sortable: true,
        value: 'last_login_date',
      },
      {
        text: '',
        sortable: true,
        value: 'date_created',
      },
      // {
      //   text: '',
      //   sortable: false,
      //   value: 'city',
      // },
      // {
      //   text: '',
      //   sortable: false,
      //   value: 'state',
      // },
      {
        text: '',
        sortable: true,
        value: 'status',
      },
      {
        text: '',
        sortable: true,
        value: 'institution_id.title',
      },
      {
        text: '',
        sortable: false,
      },
    ],
    users: [],
    totalItems: 0,
    tick: 0,
  }),
  computed: {
    searchField() { return this.$props.search; },
    searchByInstitution() { return this.$props.institution; },
  },
  watch: {
    pagination: {
      handler() {
        if (!this.searchField || this.searchField.length < 1) this.getUsers();
        else this.searchUsers(this.searchField);
      },
    },
    searchField: {
      handler(value) {
        if (!value || value.length < 1) this.getUsers();
        else this.searchUsers(value);
      },
      deep: true,
    },
    searchByInstitution: {
      handler(value) {
        if (value) {
          this.searchUsers('');
        }
      },
      deep: true,
    },
  },
  created() {
    this.getLocale();
    this.getUsers();
    this.autoRefresh();
  },
  methods: {
    autoRefresh() { // VERY BAD PRACTICE
      setInterval(() => { this.tick += 1; }, 500);
    },
    getLocale() {
      this.headers[0].text = '';
      this.headers[1].text = this.$i18n.t('users.table.user');
      this.headers[2].text = this.$i18n.t('users.table.parts');
      this.headers[3].text = this.$i18n.t('users.table.last_login_date');
      this.headers[4].text = this.$i18n.t('users.table.date');
      // this.headers[4].text = this.$i18n.t('users.table.city');
      // this.headers[5].text = this.$i18n.t('users.table.state');
      this.headers[5].text = this.$i18n.t('users.table.status');
      this.headers[6].text = this.$i18n.t('users.table.institution_id');
      this.headers[7].text = this.$i18n.t('users.table.actions');
    },
    getUsers() {
      this.loading = true;
      getRequest(
        '/user/',
        `role=${this.$store.getters.role
        }&institution_id=${this.$store.getters.institutionId
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending}`,
      )
        .then((response) => {
          // console.log(response);
          this.users = response.users;
          this.totalItems = response.count;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
    searchUsers(value) {
      this.loading = true;
      getRequest(
        '/user/',
        `role=${this.searchByInstitution ? 'InstitutionAdmin' : this.$store.getters.role
        }&search=${value
        }&institution_id=${this.searchByInstitution ? this.searchByInstitution : this.$store.getters.institutionId
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page
        }&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending}`,
      )
        .then((response) => {
          // console.log(response);
          this.users = response.users;
          this.totalItems = response.count;
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
    getParts(userId) {
      const index = this.users.findIndex(user => user._id.toString() === userId.toString());
      if (!this.users[index]._parts) {
        this.users[index]._parts = {};
        this.users[index]._loading = true;
        getRequest(`/history/user/single/${userId}`, 'status=1,1.4,2,6')
          .then((result) => {
            this.users[index]._loading = false;
            this.users[index]._parts.InsertedTemporaryParts = result.InsertedTemporaryParts;
            this.users[index]._parts.ApprovedParts = result.ApprovedParts;
            this.users[index]._parts.InsertedInventory = result.InsertedInventory;
            this.users[index]._parts.Sold = result.Sold;
          })
          .catch((error) => {
            console.log(error);
            this.users[index]._loading = false;
          });
      }
    },
    editUser(data) {
      this.$emit('edit', data);
    },
    deleteUser(userId) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loading = true;
        deleteRequest('/user/', `id=${userId}`).then((response) => {
          this.loading = false;
          this.getUsers();
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
      }
    },
    disableUser(userId) {
      this.loading = true;
      const disabled = true;
      putRequest('/user/disable', `id=${userId}`, { disabled })
        .then((response) => {
          this.loading = false;
          this.getUsers();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    enableUser(userId) {
      this.loading = true;
      const disabled = false;
      putRequest('/user/disable', `id=${userId}`, { disabled })
        .then((response) => {
          this.loading = false;
          this.getUsers();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>
