<template>
  <v-data-table
    :pagination.sync="pagination"
    :headers="headers"
    :items="requests"
    :total-items="total"
    :rows-per-page-items="[5,10,25]"
    :loading="loading"
    class="elevation-1"
  >
    <v-progress-linear
      slot="progress"
      indeterminate
      color="red"
    />
    <template
      slot="items"
      slot-scope="props"
    >
      <tr
        :class="{tableRowColorSelected : props.index === activeRow}"
        class="tableRowColor"
        @click="activeRow = props.index"
      >
        <!--ID-->
        <td>
          <span>
            {{ props.item._id.slice(props.item._id.length-4, props.item._id.length) }}
          </span>
        </td>
        <!--STATUS-->
        <td>
          <v-chip
            label
            outline
            :color="props.index === activeRow ? 'white' : getStatus(props.item).color"
          >
            {{ getStatus(props.item).status }}
          </v-chip>
        </td>
        <!--USER-->
        <td>
          <span v-if="props.item.user.details">{{ props.item.user.details.name }} </span>
          <span v-if="props.item.user.details">{{ props.item.user.details.surname }} </span>
        </td>
        <!--REQUESTS-->
        <td>
          {{ props.item.requests.length }}
          {{ props.item.requests.length === 1
            ? $t('part_requests.headers._part') : $t('part_requests.headers._parts') }}
        </td>
        <!--INSTITUTIONS-->
        <td>
          <v-layout align-center>
            <v-chip
              v-if="props.item.assignedInstitutions.length > 0"
              :color="props.item.color.rgb"
              :dark="props.item.color.dark"
            >
              {{ props.item.assignedInstitutions | institutions }}
            </v-chip>
            <span v-if="props.item.assignedInstitutions.length > 1">
              (+{{ props.item.assignedInstitutions.length - 1 }}
              {{ props.item.assignedInstitutions.length - 1 === 1
                ? $t('part_requests.headers._other') : $t('part_requests.headers._others') }})
            </span>
          </v-layout>
        </td>
        <!--CREATION DATE-->
        <td> {{ props.item.dateCreated | moment }} </td>
        <!--ACTIONS-->
        <td>
          <v-layout
            align-center
            justify-center
          >
            <!--VIEW DETAILS-->
            <v-btn
              icon
              @click="viewRequestDetails(props.item)"
            >
              <v-icon color="grey darken-2">
                view_list
              </v-icon>
            </v-btn>
            <!--CONFIRM SALE-->
            <v-btn
              v-if="props.item.status === 2.1 && checkOffer(props.item)"
              icon
              @click="confirmSale(props.item._id)"
            >
              <v-icon color="grey darken-2">
                check
              </v-icon>
            </v-btn>
            <!--VIEW OFFERS-->
            <v-btn
              icon
              @click="viewOffers(props.item._id, props.item.assignedInstitutions)"
            >
              <v-icon color="grey darken-2">
                local_offer
              </v-icon>
            </v-btn>
            <!--ASSIGN INSTITUTIONS-->
            <v-btn
              v-if="$store.getters.role === 'LekoAdmin'
                || $store.getters.role === 'LekoEmployee'
                || $store.getters.role === 'SuperAdmin'"
              icon
              @click="assignInstitutions(props.item._id, props.item.assignedInstitutions)"
            >
              <v-icon color="grey darken-2">
                assignment
              </v-icon>
            </v-btn>
          </v-layout>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';
import store from '../../store';

import getRequest from '../../api/getRequest';
import putRequest from '../../api/putRequest';

function generateRandomColor() {
  const random = () => Math.floor(Math.random() * Math.floor(255));
  const color = { r: random(), g: random(), b: random() };
  const brightness = ((color.r * 299) + (color.g * 587) + (color.b * 114)) / 1000;
  return {
    rgb: `rgb(${color.r},${color.g},${color.b})`,
    dark: brightness < 155,
  };
}

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LLL');
    },
    institutions(value) {
      if (value.length > 0) {
        const index = value
          .findIndex(v => v.id._id.toString() === store.getters.institutionId.toString());
        if (index > -1) return value[index].id.title;
        return value[0].id.title;
      }
      return 'None';
    },
  },
  props: ['selected'],
  data: () => ({
    loading: false,
    activeRow: null,
    requests: [],
    total: 0,
    pagination: {
      rowsPerPage: 5,
      page: 1,
      sortBy: 'dateCreated',
      descending: true,
    },
    headers: [
      { text: 'ID', sortable: false, value: '_id' },
      { text: 'Status', sortable: false, value: 'status' },
      { text: 'User', sortable: false, value: 'user' },
      { text: 'Parts', sortable: false, value: 'parts' },
      { text: 'Institutions', sortable: false, value: 'institutions' },
      { text: 'Date Created', sortable: true, value: 'dateCreated' },
      { text: 'Actions', sortable: false, value: 'actions' },
    ],
    loadingInstitutions: false,
    loadingSale: false,
    institutions: [],
    selectedInstitutions: [],
  }),
  watch: {
    '$props.selected': {
      handler() { this.getPartRequests(); },
    },
    pagination: {
      handler() { this.getPartRequests(); },
      deep: true,
    },
    requests: {
      handler() {
        this.requests.forEach((request) => { request.color = generateRandomColor(); });
      },
      deep: true,
    },
  },
  created() {
    this.getInstitutions();
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('part_requests.headers.id');
      this.headers[1].text = this.$i18n.t('part_requests.headers.status');
      this.headers[2].text = this.$i18n.t('part_requests.headers.user');
      this.headers[3].text = this.$i18n.t('part_requests.headers.parts');
      this.headers[4].text = this.$i18n.t('part_requests.headers.assigned_institutions');
      this.headers[5].text = this.$i18n.t('part_requests.headers.date_created');
      this.headers[6].text = this.$i18n.t('part_requests.headers.actions');
    },
    getPartRequests() {
      this.loading = true;
      getRequest(
        '/part-request',
        `category=2&status=${this.$props.selected}&institution_id=${this.$store.getters.institutionId}&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage}&page=${this.pagination.page}&sort_by=${this.pagination.sortBy}&descending=${this.pagination.descending}`,
      )
        .then((response) => {
          this.requests = response.partRequests;
          this.total = response.count;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    viewRequestDetails(data) {
      this.$emit('view-details', data);
    },
    confirmSale(id) {
      this.loadingSale = true;
      putRequest('/part-request/confirm-sale', `id=${id}`, { institution: this.$store.getters.institutionId })
        .then((response) => {
          this.$emit('confirmed-sale');
        })
        .catch(error => console.log(error))
        .finally(() => this.getPartRequests());
    },
    getInstitutions() {
      this.loadingInstitutions = true;
      getRequest('/institution').then((response) => {
        this.institutions = response.institutions;
        this.loadingInstitutions = false;
      })
        .catch((error) => {
          console.log(error);
          this.loadingInstitutions = false;
        });
    },
    viewOffers(id, assignedInstitutions) {
      this.$emit('view-offers', { id, assignedInstitutions, editable: false });
    },
    assignInstitutions(id, institutions) {
      this.$emit('assign-institutions', { id, institutions });
    },
    checkOffer(request) {
      const index = request.assignedInstitutions
        .findIndex(i => i.id._id.toString() === this.$store.getters.institutionId.toString());
      const offer = index > -1 ? request.assignedInstitutions[index].offer : null;
      return !!(offer && offer.proposed && offer.selected);
    },

    getStatus(item) {
      if (item.status === 2) return { color: 'orange', status: this.$i18n.t('part_requests.status.pending_offer_confirmation') };
      if (item.status === 2.1) return { color: 'light-green', status: this.$i18n.t('part_requests.status.confirmed_offer') };
      return { color: 'white', status: '' };
    },
  },
};
</script>
