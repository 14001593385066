<template>
  <v-card flat>
    <v-card-title>
      <span class="headline"> {{ $t('reservations.client.details') }} </span>
    </v-card-title>
    <v-card-text>
      <v-layout
        align-center
        justify-center
        row
        wrap
      >
        <!--SELECT CLIENT-->
        <v-flex
          xs6
          sm8
          md9
        >
          <v-autocomplete
            v-model="client"
            :items="clients"
            :loading="loading"
            :label="$t('reservations.client.select_client')"
            :rules="rules.requiredSelect"
            color="red darken-1"
            item-text="name"
            item-value="_id"
            prepend-icon="person"
            return-object
          >
            <template
              slot="selection"
              slot-scope="data"
            >
              <span v-if="data && data.item">
                {{ data.item.name }} {{ data.item.surname }}
              </span>
              <span v-else />
            </template>
            <template
              slot="item"
              slot-scope="data"
            >
              <span v-if="data && data.item">
                {{ data.item.name }} {{ data.item.surname }}
              </span>
              <span v-else />
            </template>
          </v-autocomplete>
        </v-flex>
        <!--ADD CLIENT-->
        <v-flex
          xs6
          sm4
          md3
        >
          <v-btn
            color="success"
            depressed
            small
            @click="addClient"
          >
            {{ $t('reservations.client.new_client') }}
          </v-btn>
        </v-flex>
        <!--COMMENT-->
        <v-flex xs12>
          <v-textarea
            v-model="comment"
            :label="$t('reservations.client.comment')"
            color="red darken-2"
          />
        </v-flex>
        <!--PRICE-->
        <v-flex
          xs6
          pr-2
        >
          <v-text-field
            v-model="calculatedPrice"
            disabled
            :label="$t('reservations.client.calculated_price')"
            color="red darken-2"
          />
        </v-flex>
        <v-flex
          xs6
          pl-2
        >
          <v-layout
            row
            wrap
          >
            <v-flex
              xs6
              pr-1
            >
              <v-text-field
                v-model="proposedPrice"
                type="number"
                min="0"
                :label="$t('reservations.client.proposed_price')"
                color="red darken-2"
              />
            </v-flex>
            <v-flex
              xs6
              pl-1
            >
              <v-select
                v-model="currency"
                :items="currencyItems"
                :label="$t('reservations.client.currency')"
                color="red darken-2"
              />
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import getRequest from '../../../api/getRequest';

export default {
  props: {
    price: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    loading: false,
    client: {},
    clients: [],
    rules: { requiredSelect: [v => (v && !!v.name) || 'Item is required'] },
    comment: '',
    proposedPrice: 0,
    currency: 'EUR',
    currencyItems: ['EUR'], // 'ALL',
  }),
  computed: {
    calculatedPrice() {
      return this.$props.price;
    },
  },
  mounted() {
    this.getClients();
  },
  methods: {
    addClient() {
      this.$emit('add-client');
    },
    getClients() {
      this.loading = true;
      getRequest(
        '/client/',
        `role=${this.$store.getters.role}&institution_id=${this.$store.getters.institutionId}`,
      )
        .then((response) => {
          this.clients = response.clients;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
  },
};
</script>
