<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <!-------------------------------------------TITLE------------------------------------------->
      <v-card-title class="redColor">
        <span
          v-if="edit == true"
          class="headline"
        >
          {{ $t('institutions.form.edit_institution') }}
        </span>
        <span
          v-if="edit == false"
          class="headline"
        >
          {{ $t('institutions.form.create_institution') }}
        </span>
      </v-card-title>
      <!------------------------------------------------------------------------------------------->
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <!---------------------------------------TITLE--------------------------------------->
              <v-flex xs12>
                <v-text-field
                  v-model="institution.title"
                  :rules="rules.requiredLetters"
                  :label="$t('institutions.form.title')"
                  color="red darken-1"
                  prepend-icon="domain"
                />
              </v-flex>
              <!--------------------------------------ADDRESS-------------------------------------->
              <v-flex xs12>
                <v-text-field
                  v-model="institution.address"
                  :rules="rules.requiredLetters"
                  :label="$t('institutions.form.address')"
                  color="red darken-1"
                  prepend-icon="list_alt"
                />
              </v-flex>
              <!------------------------------------UPLOAD LOGO------------------------------------>
              <v-flex xs12>
                <label style="padding-right:10px;">{{ $t('institutions.form.upload_logo') }}</label>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      small
                      v-on="on"
                    >
                      info
                    </v-icon>
                  </template>
                  <span>72 x 72 {{ $t('institutions.form.preferred') }}</span>
                </v-tooltip>
                <br>
                <input
                  ref="logo"
                  :style="{ color: !validLogo ? '#ff5252' : '' }"
                  type="file"
                  accept="image/*"
                  :rules="false"
                  @change="onInput"
                >
              </v-flex>
              <!---------------------------------------STATE--------------------------------------->
              <v-flex xs6>
                <v-select
                  v-model="state"
                  :rules="rules.required"
                  :items="states"
                  :loading="loadingState"
                  :label="$t('institutions.form.state')"
                  color="red darken-1"
                  item-text="name"
                  item-value="name"
                  prepend-icon="public"
                  return-object
                />
              </v-flex>
              <!---------------------------------------CITY---------------------------------------->
              <v-flex xs6>
                <v-select
                  v-model="institution.city"
                  :rules="rules.required"
                  :items="cities"
                  :loading="loadingCity"
                  :label="$t('institutions.form.city')"
                  color="red darken-1"
                  item-text="name"
                  item-value="name"
                  prepend-icon="location_city"
                />
              </v-flex>
              <!---------------------------------------PHONE--------------------------------------->
              <v-flex xs12>
                <v-text-field
                  v-model="institution.phone"
                  :rules="rules.requiredNumbers"
                  :label="$t('institutions.form.phone')"
                  color="red darken-1"
                  prepend-icon="call"
                  type="number"
                />
              </v-flex>
              <!--------------------------------------WEBSITE-------------------------------------->
              <v-flex xs12>
                <v-text-field
                  v-model="institution.website"
                  :rules="rules.requiredLetters"
                  :label="$t('institutions.form.website')"
                  color="red darken-1"
                  prepend-icon="web"
                />
              </v-flex>
              <!-------------------------------------LEKOSHOP-------------------------------------->
              <v-flex xs12>
                <v-text-field
                  v-model="institution.shop"
                  :label="$t('institutions.table.shop')"
                  color="red darken-1"
                  prepend-icon="shop"
                />
              </v-flex>
              <!---------------------------------------Email--------------------------------------->
              <v-flex xs12>
                <v-text-field
                  v-model="institution.email"
                  :rules="rules.email"
                  :label="$t('institutions.form.email')"
                  color="red darken-1"
                  prepend-icon="email"
                />
              </v-flex>
              <!--------------------------------------STATUS--------------------------------------->
              <v-flex xs12>
                <v-switch
                  v-model="institution.user_status"
                  :label="$t('institutions.form.user_status')"
                  color="red darken-1"
                  prepend-icon="poll"
                />
              </v-flex>
              <!------------------------------EXPORT EBAY MULTIBREVES------------------------------>
              <v-flex
                v-if="$store.getters.role === 'SuperAdmin'"
                xs12
              >
                <v-switch
                  v-model="institution.export_ebay"
                  :label="$t('institutions.table.exportEbay')"
                  color="red darken-1"
                  prepend-icon="poll"
                />
              </v-flex>
              <!---------------------------------EXPORT EBAY LEKO---------------------------------->
              <!-- <v-flex xs12>
                <v-switch
                  v-model="institution.export_ebay_leko"
                  :label="$t('institutions.table.exportEbayLeko')"
                  color="red darken-1"
                  prepend-icon="poll"
                />
              </v-flex> -->
              <!----------------------------------EXPORT PARTS------------------------------------->
              <v-flex
                v-if="$store.getters.role === 'SuperAdmin'"
                xs12
              >
                <v-switch
                  v-model="institution.export_parts"
                  :label="$t('institutions.table.exportParts')"
                  color="red darken-1"
                  prepend-icon="poll"
                />
              </v-flex>
              <!--------------------------------EXPORT TEMPORARY PARTS----------------------------->
              <v-flex xs12>
                <v-switch
                  v-model="institution.export_temporary_parts"
                  :label="$t('institutions.table.exportTempParts')"
                  color="red darken-1"
                  prepend-icon="poll"
                />
              </v-flex>
              <!----------------------------------EXPORT SALES------------------------------------->
              <v-flex xs12>
                <v-switch
                  v-model="institution.export_sales"
                  :label="$t('institutions.table.exportSales')"
                  color="red darken-1"
                  prepend-icon="poll"
                />
              </v-flex>
              <!----------------------------------EXPORT STATS------------------------------------->
              <v-flex xs12>
                <v-switch
                  v-model="institution.stats"
                  :label="$t('institutions.form.stats')"
                  :disabled="!institution.email"
                  color="red darken-1"
                  prepend-icon="poll"
                />
              </v-flex>
              <!----------------------------------DISPLAY SHOP------------------------------------->
              <v-flex
                v-if="$store.getters.role === 'SuperAdmin'"
                xs12
              >
                <v-switch
                  v-model="institution.display_shop"
                  :label="$t('institutions.form.displayShop')"
                  color="red darken-1"
                  prepend-icon="poll"
                />
              </v-flex>
              <!---------------------------------TRANSFER CARS------------------------------------->
              <v-flex xs12>
                <v-switch
                  v-model="institution.transfer_cars"
                  :label="$t('institutions.form.transferCars')"
                  color="red darken-1"
                  prepend-icon="poll"
                />
              </v-flex>
              <!------------------------------------INFOCAR CAD------------------------------------>
              <v-flex xs12>
                <v-switch
                  v-if="institution && institution.preferences"
                  v-model="institution.preferences.displayFeatures.infoCarCad"
                  :label="$t('institutions.form.infoCarCad')"
                  color="red darken-1"
                  prepend-icon="poll"
                />
              </v-flex>
              <!---------------------------------------TYPE---------------------------------------->
              <v-flex xs12>
                <v-text-field
                  v-model="institution.type"
                  :rules="rules.requiredLetters"
                  :label="$t('institutions.form.type')"
                  color="red darken-1"
                  prepend-icon="domain"
                />
              </v-flex>
              <!---------------------------------------PARTS--------------------------------------->
              <v-flex xs12>
                <v-layout
                  v-if="institution.parts"
                  row
                  align-center
                  wrap
                >
                  <v-flex
                    xs12
                    sm4
                  >
                    <span class="subheading grey--text text--darken-1">
                      {{ $t('institutions.form.type_of_parts') }}:
                    </span>
                  </v-flex>
                  <v-flex>
                    <v-checkbox
                      v-model="institution.parts.new"
                      :label="$t('institutions.form.new')"
                      color="primary"
                    />
                  </v-flex>
                  <v-flex>
                    <v-checkbox
                      v-model="institution.parts.used"
                      :label="$t('institutions.form.used')"
                      color="primary"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
              <!--------------------------------------PLATES--------------------------------------->
              <v-flex xs6>
                <v-text-field
                  v-model="institution.plates.used"
                  disabled
                  :label="$t('institutions.form.used_plates')"
                  type="number"
                  color="red darken-1"
                />
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="institution.plates.remaining"
                  :label="$t('institutions.form.remaining_plates')"
                  type="number"
                  step="1"
                  min="0"
                  oninput="validity.valid||(value='');"
                  color="red darken-1"
                />
              </v-flex>
              <!----------------------------------------------------------------------------------->
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <!------------------------------------------ACTIONS------------------------------------------>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('institutions.form.close') }}
        </v-btn>
        <v-btn
          :disabled="!valid || !validLogo"
          :loading="loading"
          color="blue darken-1"
          flat
          @click.native="save"
        >
          {{ $t('institutions.form.save') }}
        </v-btn>
      </v-card-actions>
      <!------------------------------------------------------------------------------------------->
    </v-card>
  </v-dialog>
</template>

<script>
import postRequest from '../../api/postRequest';
import getRequest from '../../api/getRequest';

function dataURLtoBlob(dataUrl) {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataUrl.split(',')[1]);
  // separate out the mime component
  const mimeString = dataUrl
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];
  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  // create a view into the buffer
  const ia = new Uint8Array(ab);
  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
}

export default {
  data: () => ({
    valid: false,
    edit: null,
    logo: null,
    dialog: false,
    loading: false,
    loadingState: false,
    loadingCity: false,
    rules: {
      requiredLetters: [
        v => !!v || 'Please fill this field',
        v => /[A-Za-z]/.test(v) || 'Only letters are allowed',
      ],
      requiredNumbers: [
        v => !!v || 'Please fill this field',
        v => /[0-9]/.test(v) || 'Only numbers are allowed',
      ],
      email: [
        v => !!v || 'Please fill this field',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    },
    institution: { plates: { used: 0, remaining: 0 } },
    states: [],
    state: {},
    cities: [],
  }),
  computed: {
    validLogo() {
      if (this.edit === true) return true;
      else if (this.edit === false && this.logo !== null) return true;
      return false;
    },
  },
  watch: {
    state(value) {
      if (value) {
        this.institution.state = value.name;
        this.getCities(value._id);
      }
    },
  },
  methods: {
    open(edit, data) {
      this.edit = edit;
      /*
       * Add the plates object if it doesn't exist, so it won't throw an error when creating a
       * new institution.
       */
      if (!data.plates) data.plates = { used: 0, remaining: 0 }; // eslint-disable-line no-param-reassign, max-len
      this.institution = data;
      this.dialog = true;
      this.getStates();
    },
    close() {
      this.institution = { plates: { used: 0, remaining: 0 } };
      this.states = [];
      this.state = {};
      this.cities = [];
      this.logo = null;
      this.dialog = false;
      this.loading = false;
      this.$nextTick(() => { if (this.$refs.logo) { this.$refs.logo.value = ''; } });
      // this.$emit('close');
    },
    onInput(input) {
      const { files } = input.target;
      if (files && files.length > 0) {
        const { type } = files[0];
        const extension = type.split('/')[1]; // eslint-disable-line prefer-destructuring
        const fileReader = new FileReader();
        fileReader.readAsDataURL(files[0]);
        fileReader.addEventListener('load', () => {
          const dataUrl = fileReader.result;
          this.logo = { blob: dataURLtoBlob(dataUrl), extension };
        });
      }
    },
    getStates() {
      this.loadingState = true;
      getRequest('/state')
        .then((response) => {
          this.states = response.states;
          this.loadingState = false;
          if (this.institution.state) {
            // select institution's state
            this.states.forEach((state) => {
              if (state.name === this.institution.state) this.state = state;
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingState = false;
        });
    },
    getCities(id) {
      this.loadingCity = true;
      getRequest('/city', `state_id=${id}`)
        .then((response) => {
          this.cities = response.cities;
          this.loadingCity = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingCity = false;
        });
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.edit === null) {
          this.close();
        } else {
          const formData = new FormData();
          if (this.logo !== null) formData.append('logo', this.logo.blob, `logo.${this.logo.extension}`);
          formData.append('institution', JSON.stringify(this.institution));
          if (this.edit === true) {
            postRequest.formData('/institution/edit', `id=${this.institution._id}`, formData)
              .then(() => {
                this.$emit('save');
                this.close();
              })
              .catch((error) => {
                this.$emit('error', error);
                this.close();
              });
          } else if (this.edit === false) {
            postRequest.formData('/institution/new', '', formData)
              .then(() => {
                this.$emit('save');
                this.close();
              })
              .catch((error) => {
                this.$emit('error', error);
                this.close();
              });
          } else {
            this.$emit('error', new Error('edit value is invalid'));
            this.close();
          }
        }
      }
    },
  },
};
</script>
