<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        mb-3
        xs12
      >
        <h3 class="text-xs-center grey--text text--darken-2">
          {{ $t('parts.ordered_parts.weight') }}
        </h3>
      </v-flex>
      <v-flex
        xs8
        pr-1
      >
        <v-text-field
          v-model="weight.value"
          :rules="required"
          :label="$t('parts.ordered_parts.weight')"
        />
      </v-flex>
      <v-flex
        xs4
        pl-1
      >
        <v-select
          v-model="weight.unit"
          :rules="required"
          :items="weightUnits"
          :label="$t('parts.ordered_parts.unit')"
          item-text="title"
          item-value="value"
        />
      </v-flex>
      <v-flex
        mb-3
        xs12
      >
        <h3 class="text-xs-center grey--text text--darken-2">
          {{ $t('parts.ordered_parts.weight') }}
        </h3>
      </v-flex>
      <v-flex
        sm4
        xs6
        pl-1
        pr-1
      >
        <v-text-field
          v-model="dimensions.length"
          :rules="required"
          label="Length"
        />
      </v-flex>
      <v-flex
        sm4
        xs6
        pl-1
        pr-1
      >
        <v-text-field
          v-model="dimensions.width"
          :rules="required"
          :label="$t('parts.ordered_parts.width')"
        />
      </v-flex>
      <v-flex
        sm4
        xs6
        pr-1
        pl-1
      >
        <v-text-field
          v-model="dimensions.height"
          :rules="required"
          :label="$t('parts.ordered_parts.height')"
        />
      </v-flex>
      <v-flex
        sm12
        xs6
        pr-1
        pl-1
      >
        <v-select
          v-model="dimensions.unit"
          :rules="required"
          :items="dimensionsUnits"
          :label="$t('parts.ordered_parts.unit')"
          item-text="title"
          item-value="value"
        />
      </v-flex>
    </v-layout>
  </v-form>
</template>

<script>
import putRequest from '../../api/putRequest';

export default {
  props: ['data', 'part_id', 'order_id'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    valid: false,
    required: [
      v => !!v || 'Field is required',
    ],
    weight: {
      value: null,
      unit: 'kg',
    },
    dimensions: {
      length: null,
      width: null,
      height: null,
      unit: 'cm',
    },
    weightUnits: [
      {
        title: 'Kilogram',
        value: 'kg',
      },
      {
        title: 'Gram',
        value: 'g',
      },
    ],
    dimensionsUnits: [
      {
        title: 'Centimeter',
        value: 'cm',
      },
      {
        title: 'Millimeter',
        value: 'mm',
      },
    ],
  }),
  computed: {
    orderId() {
      return this.$props.order_id;
    },
    partId() {
      return this.$props.part_id;
    },
  },
  beforeMount() {
    if (this.$props.data && Object.keys(this.$props.data).length > 0) {
      this.weight = this.$props.data.weight;
      this.dimensions = this.$props.data.dimensions;
    }
  },
  beforeDestroy() {
    this.weight = { value: null, unit: 'kg' };
    this.dimensions = {
      length: null, width: null, height: null, unit: 'cm',
    };
  },
  methods: {
    confirm() {
      this.loading = true;
      putRequest(
        '/part/ordered/confirm',
        `order_id=${this.orderId}&part_id=${this.partId}`,
        { weight: this.weight, dimensions: this.dimensions },
      )
        .then((response) => {
          this.$emit('confirmed');
        })
        .catch((error) => {
          console.log(error);
          this.$emit('error');
        });
    },
    validate(callback) {
      if (this.$refs.form.validate()) {
        this.confirm();
        callback(true);
      } else {
        callback(false);
      }
    },
  },
};
</script>
