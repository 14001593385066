<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500px"
  >
    <v-card>
      <v-card-title class="redColor">
        <span
          v-if="edit == true"
          class="headline"
        >{{ $t('cars.form.edit_car') }}</span>
        <span
          v-if="edit == false"
          class="headline"
        >{{ $t('cars.form.create_car') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="car.title"
                  :rules="rules.requiredLetters"
                  :label="$t('cars.form.title')"
                  color="red darken-1"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="car.model"
                  :rules="rules.requiredLetters"
                  :label="$t('cars.form.model')"
                  color="red darken-1"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="car.type"
                  :rules="rules.requiredLetters"
                  :label="$t('cars.form.type')"
                  color="red darken-1"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="car.year"
                  :rules="rules.requiredNumbers"
                  :label="$t('cars.form.year')"
                  color="red darken-1"
                  type="number"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="car.brand"
                  :rules="rules.requiredLetters"
                  :label="$t('cars.form.brand')"
                  color="red darken-1"
                />
              </v-flex>
              <v-flex xs12>
                <label>{{ $t('cars.form.select_logo') }}</label>
                <br>
                <v-layout row>
                  <span class="mr-1 mb-1">
                    <img
                      :class="{ border: border.mb }"
                      src="@/assets/carLogos/mb-logo.png"
                      height="50"
                      width="50"
                      @click="selectLogo('mb')"
                    >
                  </span>
                  <span class="mr-1 mb-1">
                    <img
                      :class="{ border: border.bmw }"
                      src="@/assets/carLogos/bmw-logo.png"
                      height="50"
                      width="50"
                      @click="selectLogo('bmw')"
                    >
                  </span>
                  <span class="mr-1 mb-1">
                    <img
                      :class="{ border: border.audi }"
                      src="@/assets/carLogos/audi-logo.png"
                      height="50"
                      width="50"
                      @click="selectLogo('audi')"
                    >
                  </span>
                  <span class="mr-1 mb-1">
                    <img
                      :class="{ border: border.vw }"
                      src="@/assets/carLogos/vw-logo.png"
                      height="50"
                      width="50"
                      @click="selectLogo('vw')"
                    >
                  </span>
                  <span class="mr-1 mb-1">
                    <img
                      :class="{ border: border.opel }"
                      src="@/assets/carLogos/opel-logo.png"
                      height="50"
                      width="50"
                      @click="selectLogo('opel')"
                    >
                  </span>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('cars.form.close') }}
        </v-btn>
        <v-btn
          :disabled="!valid"
          :loading="loading"
          color="blue darken-1"
          flat
          @click.native="save"
        >
          {{ $t('cars.form.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import postRequest from '../../api/postRequest';
import putRequest from '../../api/putRequest';

export default {
  data: () => ({
    dialog: false,
    loading: false,
    edit: null,
    valid: true,
    border: {},
    rules: {
      requiredLetters: [
        v => !!v || 'Please fill this field',
        v => /[A-Za-z]/.test(v) || 'Only letters are allowed',
      ],
      requiredNumbers: [
        v => !!v || 'Please fill this field',
        v => /[0-9]/.test(v) || 'Only numbers are allowed',
      ],
    },
    car: {},
  }),
  methods: {
    selectLogo(logo) {
      if (logo === 'mb') {
        this.border = {};
        this.border.mb = true;
        this.car.logo = 'mb';
      } else if (logo === 'bmw') {
        this.border = {};
        this.border.bmw = true;
        this.car.logo = 'bmw';
      } else if (logo === 'vw') {
        this.border = {};
        this.border.vw = true;
        this.car.logo = 'vw';
      } else if (logo === 'audi') {
        this.border = {};
        this.border.audi = true;
        this.car.logo = 'audi';
      } else if (logo === 'opel') {
        this.border = {};
        this.border.opel = true;
        this.car.logo = 'opel';
      }
    },
    open(edit, data) {
      this.edit = edit;
      this.car = data;
      if (data.logo) this.selectLogo(data.logo);
      this.dialog = true;
    },
    close() {
      // this.$refs.form.reset();
      this.car = {};
      this.border = {};
      this.edit = null;
      this.dialog = false;
      this.loading = false;
      this.$emit('close');
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.edit === true) {
          putRequest('/car/', `id=${this.car._id}`, this.car).then((response) => {
            this.$emit('save');
            this.close();
          }).catch((error) => {
            console.log(error);
            this.$emit('error');
            this.close();
          });
        } else {
          postRequest.object('/car/', this.car).then((response) => {
            this.$emit('save');
            this.close();
          }).catch((error) => {
            console.log(error);
            this.$emit('error');
            this.close();
          });
        }
      }
    },
  },
};
</script>
