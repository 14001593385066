<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-card-title class="red darken-2">
        <v-flex
          xs10
          sm11
        >
          <span class="headline"> {{ $t('leko_employees.car_part_groups.select_parts') }} </span>
        </v-flex>
        <v-flex
          xs2
          sm1
        >
          <v-btn
            outline
            icon
            large
            color="black"
            @click="close"
          >
            <v-icon x-large>
              close
            </v-icon>
          </v-btn>
        </v-flex>
      </v-card-title>
      <v-card-text>
        <v-layout
          row
          wrap
        >
          <!--SELECT-->
          <v-flex xs12>
            <v-autocomplete
              v-model="part"
              :loading="loadingSearch"
              :items="parts"
              :search-input.sync="searchPart"
              :label="$t('leko_employees.car_part_groups.search_part')"
              :item-text="`title.${$store.getters.language}`"
              flat
              color="red darken-1"
              append-icon="add"
              single-line
              hide-details
              hide-no-data
              return-object
              @keyup.enter="addPart()"
              @click:append="addPart()"
            />
          </v-flex>
          <!--LIST-->
          <v-flex
            xs12
            mt-4
          >
            <v-layout
              v-if="loading"
              align-center
              justify-center
            >
              <v-progress-circular
                :size="50"
                color="red"
                indeterminate
              />
            </v-layout>

            <v-list v-else>
              <v-toolbar
                color="red darken-1"
                flat
              >
                <v-toolbar-title>
                  {{ $t('leko_employees.car_part_groups.position') }}:
                  {{ $t(`leko_employees.car_part_groups.position_${position}`) }}
                </v-toolbar-title>
              </v-toolbar>

              <v-list-tile
                v-for="item in items"
                :key="item._id"
                color="grey darken-4"
                @click="''"
              >
                <v-list-tile-content>
                  <v-list-tile-title v-text="item.title[$store.getters.language]" />
                </v-list-tile-content>
                <v-list-tile-action>
                  <v-btn
                    icon
                    @click="removePart(item._id)"
                  >
                    <v-icon> remove </v-icon>
                  </v-btn>
                </v-list-tile-action>
              </v-list-tile>
            </v-list>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('leko_employees.car_part_groups.close') }}
        </v-btn>
        <v-btn
          :loading="loadingSave"
          :disabled="items.length < 1"
          color="blue darken-1"
          flat
          @click.native="save"
        >
          {{ $t('leko_employees.car_part_groups.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';
import putRequest from '../../api/putRequest';

export default {
  data: () => ({
    dialog: false,
    loading: false,
    loadingSearch: false,
    loadingSave: false,
    position: null,
    searchPart: null,
    part: null,
    parts: [],
    items: [],
  }),
  watch: {
    searchPart(value) {
      if (value) {
        this.loadingSearch = true;
        getRequest('/info-car/parts', `title=${value}&language=${this.$store.getters.language}`)
          .then((response) => { this.parts = response.parts; })
          .catch(error => console.log(error))
          .finally(() => { this.loadingSearch = false; });
      }
    },
  },
  mounted() {},
  methods: {
    open(position) {
      this.dialog = true;
      this.loading = true;
      this.position = position;
      getRequest('/part-groups/', `position=${this.position}`)
        .then((response) => { if (response && response.parts) this.items = response.parts; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
    close() {
      this.position = null;
      this.searchPart = null;
      this.part = null;
      this.parts = [];
      this.items = [];
      this.dialog = false;
    },
    addPart() {
      this.items.push(this.part);
      this.part = null;
      this.searchPart = null;
      this.parts = [];
    },
    removePart(id) {
      const index = this.items.findIndex(i => i._id.toString() === id.toString());
      if (index > -1) this.items.splice(index, 1);
    },
    save() {
      this.loadingSave = true;
      putRequest('/part-groups/', `position=${this.position}`, { items: this.items })
        .then((response) => { console.log(response); })
        .catch((error) => { console.log(error); })
        .finally(() => {
          this.loadingSave = false;
          this.close();
        });
    },
  },
};
</script>
