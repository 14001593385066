<template>
  <v-container
    fluid
    grid-list-md
  >
    <v-layout
      row
      align-center
      justify-center
    >
      <v-progress-linear
        :active="loading"
        indeterminate
        color="red"
      />
    </v-layout>
    <v-data-iterator
      :items="parts"
      :rows-per-page-items="[4,8,10,20]"
      :pagination.sync="pagination"
      :total-items="totalItems"
      content-tag="v-layout"
      row
      wrap
    >
      <template v-slot:item="props">
        <v-flex
          xs12
          md6
          lg4
          xl3
          d-flex
        >
          <v-card class="ma-1 pa-1">
            <v-card-text>
              <p v-if="props.item.group === 'date'">
                {{ $t('parts.insert_parts.empty_parts.date_created') }}:
                {{ props.item.key | moment }}
              </p>
              <p v-if="props.item.group === 'shipment'">
                {{ $t('parts.insert_parts.empty_parts.shipment') }}:
                {{
                  props.item.key && props.item.opt
                    ? props.item.opt.name
                    : $t('parts.insert_parts.empty_parts.empty')
                }}
              </p>
              <p>
                {{ $t('parts.insert_parts.empty_parts.number_of_parts') }}:
                {{ props.item.parts.length }}
              </p>
            </v-card-text>
            <v-divider />
            <v-layout
              align-center
              justify-center
              row
              wrap
            >
              <v-flex>
                <v-layout justify-center>
                  <v-btn
                    depressed
                    color="primary"
                    @click="openQrCodes(props.item.parts)"
                  >
                    {{ $t('parts.insert_parts.empty_parts.open_qr_codes') }}
                    <v-icon right>
                      fas fa-qrcode
                    </v-icon>
                  </v-btn>
                </v-layout>
              </v-flex>
              <v-flex>
                <v-layout justify-center>
                  <v-btn
                    depressed
                    :disabled="disabled || !props.item.key"
                    color="error"
                    @click="deleteEmptyParts(props.item.group, props.item.key)"
                  >
                    {{ $t('parts.insert_parts.empty_parts.delete_qr_codes') }}
                    <v-icon right>
                      delete
                    </v-icon>
                  </v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import moment from 'moment';
import getRequest from '../../api/getRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LLL');
    },
  },
  props: ['groupby'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    loading: false,
    parts: [],
    totalItems: 0,
    pagination: {
      rowsPerPage: 4,
      page: 1,
    },
  }),
  computed: {
    groupBy() {
      return this.$props.groupby;
    },
    disabled() {
      if (this.$store.getters.role === 'LekoAdmin' || this.$store.getters.role === 'InstitutionAdmin' || this.$store.getters.role === 'SuperAdmin') {
        return false;
      }
      return true;
    },
  },
  watch: {
    groupBy() {
      this.getEmptyParts();
    },
    pagination: {
      handler() {
        this.getEmptyParts();
      },
      deep: true,
    },
  },
  methods: {
    getEmptyParts() {
      this.loading = true;
      const groupBy = this.groupBy === 'date' || this.groupBy === 'shipment' ? this.groupBy : 'date';
      getRequest(
        '/temporary-part/insert/empty-grouped/',
        `institution_id=${this.$store.getters.institutionId}&role=${this.$store.getters.role
        }&limit=${this.pagination.rowsPerPage}&page=${this.pagination.page}&group_by=${groupBy}`,
      )
        .then((response) => {
          this.parts = response.emptyParts;
          this.totalItems = response.count;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    deleteEmptyParts(group, key) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        deleteRequest(
          '/temporary-part/insert/empty',
          `group=${group}&key=${key}&user_id=${this.$store.getters.userId
          }&institution_id=${this.$store.getters.institutionId
          }&role=${this.$store.getters.role}`,
        )
          .then((response) => {
            this.getEmptyParts();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    openQrCodes(data) {
      this.$emit('qr', data);
    },
  },
};
</script>
