<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!---------->
    <v-snackbar
      v-if="snackbar.value"
      v-model="snackbar.value"
      top
      :color="snackbar.color"
    >
      {{ snackbar.message }}
      <v-btn
        icon
        @click="snackbar.value = false"
      >
        <v-icon>close</v-icon>
      </v-btn>
    </v-snackbar>
    <!---------->
    <toolbar
      ref="toolbar"
      :items="selectedItems"
      :selected="selected"
      @alert="openAlertDialog"
      @search="search"
      @adv-search="advancedSearch"
      @clear-search="clearSearch"
      @scan-qr="openQrScanner"
      @share="openShareDialog"
      @delete-parts="deleteParts"
      @export-archive="exportArchiveMethod"
      @export-pdf-advanced="exportPdfAdvanced"
      @export-pdf="exportPdfRegular"
      @synced="refreshParts"
      @infocar-cad="openInfoCarCad"
      @car-parts-list="openCarPartsDialog"
      @search-list="openSearchList"
      @add-multiple-ebay="addMultipleEbayItems"
      @delete-multiple-ebay="deleteMultipleEbayItems"
      @sync-with-ebay="syncWithEbay"
      @add-multiple-google="addMultipleGoogleItems"
      @delete-multiple-google="deleteMultipleGoogleItems"
    />
    <!---------->
    <datatable
      ref="datatable"
      :search="searchObject"
      @selected="selectItems"
      @items="getDatatableItems"
      @edit="openEditDialog"
      @share="openShareDialog"
      @view-car="openCarDialog"
      @delete="getParts"
      @actions="openActionsDialog"
      @details="openDetailsDialog"
      @movement="openMovementDialog"
      @images="openImagesCarousel"
      @qr="openQrDialog"
      @show-notes="openNotesDialog"
      @show-refusal-reason="openRefusalReasonDialog"
      @show-cars="openCarsDialog"
      @add-ebay="openAddEbay"
      @delete-ebay="openDeleteEbay"
      @edit-ebay-title="openEditEbayTitle"
      @average-prices="openAveragePrices"
      @check-ktypes="checkKtypes"
      @show-error-modal="showActionsErrorModal"
      @add-google="openAddGoogle"
      @delete-google="openDeleteGoogle"
    />
    <movement-dialog ref="movementDialog" />
    <qr-dialog ref="qrDialog" />
    <img-carousel ref="imgCarousel" />
    <details-dialog
      ref="detailsDialog"
      @close="''"
      @delete-applicability="getParts"
    />
    <actions-dialog
      ref="actionsDialog"
      @add-client="openClientsDialog"
      @close="getParts"
      @show-error-modal="showActionsErrorModal"
    />
    <clients-dialog
      ref="clientsDialog"
      @saved="getClients"
      @closed="showActionsDialog"
    />
    <qr-scanner
      ref="qrScanner"
      @details="openDetailsDialog"
      @actions="openActionsDialog"
      @edit="openEditDialog"
      @changed-location="getParts"
    />
    <edit-dialog
      ref="editDialog"
      :hide="hideEditDialog"
      @open-id-changer="openIdChanger"
      @open-img-dialog="openImgDialog"
      @open-img-editor="openImgEditor"
      @close="getParts"
      @show-error-modal="showActionsErrorModal"
    />
    <share-dialog
      ref="shareDialog"
    />
    <car-dialog
      ref="carDialog"
    />
    <id-changer
      ref="idChanger"
      @change="changePartsId"
      @close="closeIdChanger"
    />
    <img-dialog
      ref="imgDialog"
      @close="closeImgDialog"
    />
    <img-editor
      ref="imgEditor"
      @save="editImage"
      @close="closeImgDialog"
    />
    <notes-dialog ref="notesDialog" />
    <refusal-reason-dialog ref="refusalReasonDialog" />
    <cars-dialog ref="carsDialog" />
    <car-parts-dialog
      ref="carPartsDialog"
      @search="setSearchTitle"
    />
    <search-list
      ref="searchList"
      @title="setTitleFromSearchList"
      @group="setGroupFromSearchList"
    />
    <infocar-cad ref="infoCarCad" />
    <alert-dialog ref="alertDialog" />
    <ebay
      ref="ebayFile"
      @close="getParts"
    />
    <google
      ref="googleFile"
      @close="getParts"
    />
    <average-prices
      ref="averagePricesFile"
    />
    <!---------->
  </v-container>
</template>

<script>
import Datatable from '@/components/Inventory/datatable.vue';
import Toolbar from '@/components/Inventory/toolbar.vue';
import EditDialog from '@/components/Inventory/editDialog.vue';
import ShareDialog from '@/components/Inventory/shareDialog.vue';
import CarDialog from '@/components/carDialog.vue';
import ActionsDialog from '@/components/Inventory/actionsDialog.vue';
import IdChanger from '@/components/Inventory/idChanger.vue';
import QrScanner from '@/components/Inventory/qrScanner.vue';
import ebayDialog from '@/components/Inventory/ebay.vue';
import ClientsDialog from '@/components/Clients/editDialog.vue';
import averagePricesDialog from '@/components/Inventory/averagePricesDialog.vue';
import AlertDialog from '@/components/alertDialog.vue';
import DetailsDialog from '@/components/detailsDialog.vue';
import MovementDialog from '@/components/movementDialog.vue';
import ImgDialog from '@/components/imgDialog.vue';
import ImgEditor from '@/components/imgEditor.vue';
import ImgCarousel from '@/components/imgCarousel.vue';
import QrDialog from '@/components/qrDialog.vue';
import NotesDialog from '@/components/notesDialog.vue';
import RefusalReasonDialog from '@/components/refusalReasonDialog.vue';
import CarsDialog from '@/components/Inventory/carsDialog.vue';
import CarPartsDialog from '@/components/Inventory/carParts.vue';
import SearchList from '@/components/Inventory/searchList.vue';
import InfoCarCad from '@/components/infoCarCad.vue';
import googleDialog from '@/components/Inventory/google.vue';

import axios from 'axios';
import getRequest from '../api/getRequest';
import deleteRequest from '../api/deleteRequest';

export default {
  components: {
    datatable: Datatable,
    toolbar: Toolbar,
    'edit-dialog': EditDialog,
    'share-dialog': ShareDialog,
    'car-dialog': CarDialog,
    'actions-dialog': ActionsDialog,
    'alert-dialog': AlertDialog,
    'details-dialog': DetailsDialog,
    'movement-dialog': MovementDialog,
    'clients-dialog': ClientsDialog,
    'id-changer': IdChanger,
    'img-dialog': ImgDialog,
    'img-editor': ImgEditor,
    'img-carousel': ImgCarousel,
    'qr-dialog': QrDialog,
    'qr-scanner': QrScanner,
    'notes-dialog': NotesDialog,
    'cars-dialog': CarsDialog,
    'car-parts-dialog': CarPartsDialog,
    'search-list': SearchList,
    'infocar-cad': InfoCarCad,
    'refusal-reason-dialog': RefusalReasonDialog,
    ebay: ebayDialog,
    'average-prices': averagePricesDialog,
    google: googleDialog,

  },
  data: () => ({
    hideEditDialog: false,
    snackbar: { message: '', color: 'success', value: false },
    searchObject: {
      type: null,
      data: {},
    },
    datatableItems: [],
    selectedItems: [],
    selected: false,
  }),
  mounted() {
    const {
      price, titleCode, brand, model, version, id,
    } = this.$route.query;
    // Filer parts by price and show only the ones with price 1
    if (price && parseInt(price, 10) === 1) {
      // Automatically fill out the advanced search and trigger the search method.
      const { toolbar } = this.$refs;
      toolbar.advancedSearch = true;
      toolbar.advancedSearchItems.price = [0, 1];
      toolbar.searchMethod();
    }
    if (titleCode && brand && model && version) {
      const { toolbar } = this.$refs;
      toolbar.advancedSearch = true;
      toolbar.setCarDetails(
        parseInt(titleCode, 10),
        parseInt(brand, 10),
        parseInt(model, 10),
        parseInt(version, 10),
      ).then(() => { toolbar.searchMethod(); });
    }
    if (id) {
      const { toolbar } = this.$refs;
      toolbar.setId(id);
      toolbar.searchMethod();
    }
  },
  methods: {
    getParts() {
      // Fired when closing edit and actions dialog.
      if (this.searchObject.type === null) {
        this.clearSearch();
      } else if (this.searchObject.type === 'regularSearch') {
        this.search(this.searchObject.data);
      } else if (this.searchObject.type === 'advancedSearch') {
        this.advancedSearch(this.searchObject.data);
      }
    },
    showExportPdfAlert(value) {
      if (value) {
        this.snackbar.message = this.$i18n.t('parts.inventory.export_pdf_success');
        this.snackbar.color = 'success';
      }
      if (!value) {
        this.snackbar.message = this.$i18n.t('parts.inventory.export_pdf_fail');
        this.snackbar.color = 'error';
      }
      this.snackbar.value = true;
      setTimeout(() => { this.snackbar.value = false; }, 5000);
    },
    /* Search ----------------------------------------------------------------------------------- */
    search(data) {
      this.searchObject = {
        type: 'regularSearch',
        data,
      };
    },
    advancedSearch(data) {
      this.searchObject = {
        type: 'advancedSearch',
        data,
      };
    },
    clearSearch() {
      this.searchObject = {
        type: null,
        data: {},
      };
    },
    selectItems(data) {
      this.selectedItems = data.items;
      this.selected = data.value;
    },
    /* Export Archive ----------------------------------------------------------------------------*/
    exportArchiveMethod() {
      const { toolbar } = this.$refs;
      toolbar.startLoading('archive');
      const parts = this.selectedItems.map(i => i._id);
      const { token } = localStorage;
      axios.post(`${this.$serverUri}/part/inventory/export`, { parts }, {
        responseType: 'arraybuffer',
        headers: {
          authorization: token,
        },
      })
        .then(async (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'archive.zip');
          document.body.appendChild(link);
          link.click();
          toolbar.stopLoading('archive');
        })
        .catch((error) => {
          console.log(error);
          toolbar.stopLoading('archive');
        });
    },
    /* Export Pdf --------------------------------------------------------------------------------*/
    exportPdfRegular(data) {
      const { toolbar, datatable } = this.$refs;
      toolbar.startLoading('pdf');
      getRequest(
        '/part/inventory/export/regular',
        `institution_id=${this.$store.getters.institutionId
        }&email=${this.$store.getters.institutionEmail
        }&role=${this.$store.getters.role
        }&sort_by=${datatable.pagination.sortBy
        }&descending=${datatable.pagination.descending
        }&search_field=${data.searchField
        }&title_code=${data.titleCode
        }&type=${data.type
        }&language=${this.$store.getters.language}`,
      )
        .then(() => { this.showExportPdfAlert(true); })
        .catch((error) => {
          this.showExportPdfAlert(false);
          console.log(error);
        })
        .finally(() => { toolbar.stopLoading('pdf'); });
    },
    exportPdfAdvanced(data) {
      const { toolbar, datatable } = this.$refs;
      toolbar.startLoading('pdf');
      getRequest(
        '/part/inventory/export/advanced',
        `institution_id=${this.$store.getters.institutionId
        }&email=${this.$store.getters.institutionEmail
        }&role=${this.$store.getters.role
        }&sort_by=${datatable.pagination.sortBy
        }&descending=${datatable.pagination.descending
        }&search_field=${data.searchField
        }&language=${this.$store.getters.language
        }&title_code=${data.titleCode
        }&type=${data.type
        }&brand=${data.advancedSearchFields.brand
        }&model=${data.advancedSearchFields.model
        }&version=${data.advancedSearchFields.version
        }&institution=${data.advancedSearchFields.institution
        }&shipment=${data.advancedSearchFields.shipment
        }&status=${data.advancedSearchFields.status
        }&quality=${data.advancedSearchFields.quality
        }&price=${data.advancedSearchFields.price
        }&start_date=${data.advancedSearchFields.startDate
        }&end_date=${data.advancedSearchFields.endDate
        }&no_applicability=${data.advancedSearchFields.noApplicability
        }&no_notes=${data.advancedSearchFields.noNotes}`,
      )
        .then(() => { this.showExportPdfAlert(true); })
        .catch((error) => {
          console.log(error);
          this.showExportPdfAlert(false);
        })
        .finally(() => { toolbar.stopLoading('pdf'); });
    },
    /* Delete Parts ------------------------------------------------------------------------------*/
    deleteParts() {
      const { toolbar, datatable } = this.$refs;
      const selected = this.selectedItems
        .map(s => s._id)
        .join(',')
        .trim();

      toolbar.startLoading('delete-part');
      deleteRequest('/part/inventory', `parts=${selected}&user_id=${this.$store.getters.userId
      }&institution_id=${this.$store.getters.institutionId}`)
        .then(() => {
          this.getParts();
          datatable.clearSelected(); // Clear deleted items that are still saved in memory.
        })
        .catch((error) => { console.log(error); })
        .finally(() => { toolbar.stopLoading('delete-part'); });
    },
    /* Scan QR -----------------------------------------------------------------------------------*/
    openQrScanner() {
      const { qrScanner } = this.$refs;
      qrScanner.open();
    },
    /* Datatable Items -------------------------------------------------------------------------- */
    getDatatableItems(data) {
      this.datatableItems = data;
    },
    /* Details Dialog --------------------------------------------------------------------------- */
    openDetailsDialog(data) {
      const { detailsDialog } = this.$refs;
      detailsDialog.open(data);
    },
    /* Actions Dialog --------------------------------------------------------------------------- */
    openActionsDialog(data) {
      const { actionsDialog } = this.$refs;
      actionsDialog.open(data);
    },
    /* Movement Dialog -------------------------------------------------------------------------- */
    openMovementDialog(data) {
      const { movementDialog } = this.$refs;
      movementDialog.open(data);
    },
    /* Edit Dialog ------------------------------------------------------------------------------ */
    openEditDialog(data) {
      const { editDialog } = this.$refs;
      editDialog.open(data);
    },
    /* Share Dialog ----------------------------------------------------------------------------- */
    openShareDialog(data) {
      const { shareDialog } = this.$refs;
      shareDialog.open(this.selected ? this.selectedItems : [data]);
    },
    /* Car Dialog ------------------------------------------------------------------------------- */
    openCarDialog(data) {
      const { carDialog } = this.$refs;
      carDialog.open(data);
    },
    /* Car Parts Dialog ------------------------------------------------------------------------- */
    openCarPartsDialog(data) {
      const { carPartsDialog } = this.$refs;
      carPartsDialog.open(data);
    },
    setSearchTitle(data) {
      const { toolbar } = this.$refs;
      toolbar.setTitle(data);
    },
    /* Id Changer ------------------------------------------------------------------------------- */
    openIdChanger() {
      const { idChanger } = this.$refs;
      idChanger.open();
      this.hideEditDialog = true;
    },
    closeIdChanger() {
      this.hideEditDialog = false;
    },
    changePartsId(id) {
      const { editDialog } = this.$refs;
      const { idChanger } = this.$refs;
      editDialog.changeId(id);
      idChanger.close();
    },
    /* Img Dialog ------------------------------------------------------------------------------- */
    openImgDialog(image) {
      const { imgDialog } = this.$refs;
      imgDialog.open(image);
      this.hideEditDialog = true;
    },
    openImgEditor(image) {
      const { imgEditor } = this.$refs;
      imgEditor.open(image);
      this.hideEditDialog = true;
    },
    editImage(image) {
      const { editDialog } = this.$refs;
      editDialog.setEditedImage(image);
      this.hideEditDialog = false;
    },
    closeImgDialog() {
      this.hideEditDialog = false;
    },
    /* Img Carousel ----------------------------------------------------------------------------- */
    openImagesCarousel(data) {
      const { imgCarousel } = this.$refs;
      imgCarousel.open(data);
    },
    /* QR Dialog -------------------------------------------------------------------------------- */
    openQrDialog(data) {
      const { qrDialog } = this.$refs;
      qrDialog.open(data);
    },
    /* Clients Dialog --------------------------------------------------------------------------- */
    openClientsDialog() {
      const { clientsDialog } = this.$refs;
      const { actionsDialog } = this.$refs;
      clientsDialog.open(false, null, this.$store.getters.institutionId);
      actionsDialog.hide();
    },
    getClients() {
      const { actionsDialog } = this.$refs;
      actionsDialog.getClients();
    },
    showActionsDialog() {
      const { actionsDialog } = this.$refs;
      actionsDialog.show();
    },
    /* Refresh datatable ------------------------------------------------------------------------ */
    refreshParts() {
      const { datatable } = this.$refs;
      datatable.refreshParts();
    },
    /* Notes dialog ----------------------------------------------------------------------------- */
    openNotesDialog(notes) {
      const { notesDialog } = this.$refs;
      notesDialog.open(notes);
    },
    /* Refusal Reason dialog -------------------------------------------------------------------- */
    openRefusalReasonDialog(reason) {
      const { refusalReasonDialog } = this.$refs;
      refusalReasonDialog.open(reason);
    },
    /* Cars dialog ------------------------------------------------------------------------------ */
    openCarsDialog(data) {
      const { carsDialog } = this.$refs;
      carsDialog.open(data);
    },
    /* InfoCar Cad dialog ----------------------------------------------------------------------- */
    openInfoCarCad(data) {
      const { infoCarCad } = this.$refs;
      infoCarCad.open(data);
    },
    /* Alert dialog ----------------------------------------------------------------------------- */
    openAlertDialog(data) {
      const { alertDialog } = this.$refs;
      alertDialog.open(data);
    },
    /* Search List ------------------------------------------------------------------------------ */
    openSearchList() {
      const { searchList } = this.$refs;
      searchList.open();
    },
    setTitleFromSearchList(data) {
      const { toolbar } = this.$refs;
      toolbar.setTitle({ code: data.title.partCode });
    },
    setGroupFromSearchList(data) {
      const { toolbar } = this.$refs;
      toolbar.setGroup({ groupId: data.group._id });
    },
    /* ebay ------------------------------------------------------------------------------ */
    openAddEbay(data) {
      const { ebayFile } = this.$refs;
      ebayFile.openAddEbay(data);
    },
    openDeleteEbay(data) {
      const { ebayFile } = this.$refs;
      ebayFile.openDeleteEbay(data);
    },
    endItemEbay(data) {
      const { ebayFile } = this.$refs;
      ebayFile.endItemEbay(data);
    },
    openEditEbayTitle(data) {
      const { ebayFile } = this.$refs;
      ebayFile.openEditEbayTitleModal(data);
    },
    /* Average prices ------------------------------------------------------------------------ */
    openAveragePrices(data) {
      const { averagePricesFile } = this.$refs;
      averagePricesFile.openPriceAverages(data);
    },
    /* ebay multiple items ---------------------------------------------------------------- */
    addMultipleEbayItems(data) {
      const { ebayFile } = this.$refs;
      ebayFile.addMultipleEbayItems(data);
    },
    deleteMultipleEbayItems(data) {
      const { ebayFile } = this.$refs;
      ebayFile.deleteMultipleEbayItems(data);
    },
    showActionsErrorModal(data) {
      const { ebayFile } = this.$refs;
      ebayFile.showActionsErrorModal(data);
    },
    syncWithEbay() {
      const { ebayFile } = this.$refs;
      ebayFile.syncWithEbay();
    },
    checkKtypes(data) {
      const { ebayFile } = this.$refs;
      ebayFile.checkKtypes(data);
    },
    /* google ------------------------------------------------------------------------------ */
    openAddGoogle(data) {
      const { googleFile } = this.$refs;
      googleFile.openAddGoogle(data);
    },
    openDeleteGoogle(data) {
      const { googleFile } = this.$refs;
      googleFile.openDeleteGoogle(data);
    },
    addMultipleGoogleItems(data) {
      const { googleFile } = this.$refs;
      googleFile.addMultipleGoogleItems(data);
    },
    deleteMultipleGoogleItems(data) {
      const { googleFile } = this.$refs;
      googleFile.deleteMultipleGoogleItems(data);
    },
  },
};
</script>
