import { render, staticRenderFns } from "./detailsDialog.vue?vue&type=template&id=881048b8&scoped=true&"
import script from "./detailsDialog.vue?vue&type=script&lang=js&"
export * from "./detailsDialog.vue?vue&type=script&lang=js&"
import style0 from "./detailsDialog.vue?vue&type=style&index=0&id=881048b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "881048b8",
  null
  
)

export default component.exports