<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title class="redColor">
        <span
          v-if="edit === true"
          class="headline"
        >{{ $t('shipments.form.edit_shipment') }}</span>
        <span
          v-if="edit === false"
          class="headline"
        >{{ $t('shipments.form.create_shipment') }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="shipment.name"
                  :rules="rules.required"
                  :label="$t('shipments.form.name')"
                  color="red darken-1"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="shipment.date"
                  :label="$t('shipments.form.date')"
                  :min="currentDate"
                  color="red darken-1"
                  type="date"
                  oninput="validity.valid||(value='');"
                />
              </v-flex>
              <v-flex
                v-if="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin'"
                xs12
              >
                <v-select
                  v-model="shipment.institution_id"
                  :rules="rules.required"
                  :items="institutions"
                  :label="$t('shipments.form.select_institution')"
                  color="red darken-1"
                  item-text="title"
                  item-value="_id"
                />
              </v-flex>
              <v-flex>
                <v-switch
                  v-model="shipment.default"
                  :label="$t('shipments.form.default_shipment')"
                  color="red darken-1"
                />
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="shipment.description"
                  :label="$t('shipments.form.description')"
                  color="red darken-1"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('shipments.form.close') }}
        </v-btn>
        <v-btn
          :loading="loading"
          :disabled="!valid"
          color="blue darken-1"
          flat
          @click.native="save"
        >
          {{ $t('shipments.form.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';

import getRequest from '../../api/getRequest';
import postRequest from '../../api/postRequest';
import putRequest from '../../api/putRequest';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LL');
    },
  },
  data: () => ({
    dialog: false,
    valid: false,
    loading: false,
    rules: {
      required: [v => !!v || 'Please fill this field'],
    },
    shipment: {},
    institutions: [],
    edit: null,
  }),
  computed: {
    currentDate() {
      return new Date().toISOString().split('T')[0];
    },
  },
  methods: {
    open(edit, shipment) {
      this.edit = edit;
      if (shipment) this.shipment = shipment;
      this.dialog = true;
      if (this.$store.getters.role === 'LekoAdmin' || this.$store.getters.role === 'LekoEmployee' || this.$store.getters.role === 'SuperAdmin') {
        this.getInstitutions();
      }
    },
    close() {
      this.$emit('close');
      this.dialog = false;
      this.loading = false;
      this.shipment = {};
      this.institutions = [];
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.$store.getters.role === 'User'
          || this.$store.getters.role === 'InstitutionManager'
          || this.$store.getters.role === 'InstitutionAdmin') {
          this.shipment.institution_id = this.$store.getters.institutionId;
        }

        if (this.edit === true) {
          putRequest('/shipment/', `id=${this.shipment._id}`, this.shipment)
            .then((response) => {
              if (response.status === 200) {
                this.$emit('save', response);
              }
              this.close();
            })
            .catch((error) => {
              console.log(error);
              this.close();
            });
        } else {
          postRequest.object('/shipment/', this.shipment)
            .then((response) => {
              if (response.status === 201) {
                this.$emit('save', response);
              }
              this.close();
            })
            .catch((error) => {
              console.log(error);
              this.close();
            });
        }
      }
    },
    getInstitutions() {
      getRequest('/institution/')
        .then((response) => {
          this.institutions = response.institutions;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
