<template>
  <v-data-table
    :headers="headers"
    :items="qualities"
    :search="searchField"
    :loading="loading"
    hide-actions
    class="elevation-1"
  >
    <v-progress-linear
      slot="progress"
      color="red"
      indeterminate
    />

    <template
      slot="items"
      slot-scope="props"
    >
      <tr
        :class="{tableRowColorSelected : props.index == activeRow}"
        class="tableRowColor"
        @click="activeRow = props.index"
      >
        <td class="text-xs">
          {{ props.item.title }}
        </td>
        <td>
          <v-layout row>
            <v-rating
              :value="props.item.rating"
              color="red"
              background-color="red darken-4"
              readonly
            />
          </v-layout>
        </td>
        <td class="text-xs">
          {{ props.item.value }}
        </td>
        <td class="text-xs">
          {{ props.item.description }}
        </td>
        <td class="justify-center">
          <v-layout row>
            <v-icon
              class="mr-2 pointer"
              @click="editQuality(props.item)"
            >
              edit
            </v-icon>
            <v-icon
              class="mr-2 pointer"
              @click="deleteQuality(props.item._id)"
            >
              delete
            </v-icon>
          </v-layout>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import getRequest from '../../api/getRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  data: () => ({
    loading: false,
    searchField: '',
    headers: [
      {
        text: 'Title',
        sortable: false,
        value: 'title',
      },
      {
        text: 'Rating',
        sortable: false,
        value: 'rating',
      },
      {
        text: 'Value',
        sortable: false,
        value: 'value',
      },
      {
        text: 'Description',
        sortable: false,
        value: 'description',
      },
      {
        text: 'Actions',
        sortable: false,
      },
    ],
    qualities: [],
    activeRow: null,
  }),
  mounted() {
    this.getLocale();
    this.getQualities();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('quality.table.title');
      this.headers[1].text = this.$i18n.t('quality.table.rating');
      this.headers[2].text = this.$i18n.t('quality.table.value');
      this.headers[3].text = this.$i18n.t('quality.table.description');
      this.headers[4].text = this.$i18n.t('quality.table.actions');
    },
    getQualities() {
      this.loading = true;
      getRequest('/quality/').then((response) => {
        this.qualities = response.qualities;
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        this.loading = false;
      });
    },
    editQuality(data) {
      this.$emit('edit', data);
    },
    deleteQuality(id) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loading = true;
        deleteRequest('/quality/', `id=${id}`).then((response) => {
          this.loading = false;
          this.getQualities();
        })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      }
    },
    search(value) {
      this.searchField = value;
    },
  },
};
</script>
