var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-toolbar',{attrs:{"flat":"","color":"red darken-3"}},[_c('v-toolbar-title',{staticClass:"hidden-sm-and-down"},[_vm._v(" "+_vm._s(_vm.$t("users.label"))+" ")]),_c('v-divider',{staticClass:"mx-5 hidden-sm-and-down",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"label":_vm.$t('users.search'),"flat":"","solo":"","color":"red darken-1","append-icon":"search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer',{staticClass:"hidden-sm-and-down"}),(_vm.$store.getters.role == 'LekoAdmin'
        || _vm.$store.getters.role == 'LekoEmployee'
        || _vm.$store.getters.role == 'SuperAdmin'
      )?_c('v-flex',{attrs:{"xs12":"","sm5":""}},[_c('v-autocomplete',{staticClass:"ml-2 mr-2 mt-2",attrs:{"items":_vm.institutions,"loading":_vm.loadingInstitution,"label":_vm.$t('parts.advanced_search.institution'),"item-text":"title","item-value":"_id","flat":"","solo":"","color":"red darken-1"},model:{value:(_vm.institution),callback:function ($$v) {_vm.institution=$$v},expression:"institution"}})],1):_vm._e(),(_vm.$store.getters.role == 'LekoAdmin'
        || _vm.$store.getters.role == 'LekoEmployee'
        || _vm.$store.getters.role == 'SuperAdmin'
      )?_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.clearSearch()}}},[_c('v-icon',{staticClass:"ma-0 pa-0",attrs:{"small":""}},[_vm._v(" mdi-broom ")])],1):_vm._e(),(_vm.$store.getters.role == 'LekoAdmin'
        || _vm.$store.getters.role == 'LekoEmployee'
        || _vm.$store.getters.role == 'InstitutionAdmin'
        || _vm.$store.getters.role == 'SuperAdmin'
      )?_c('v-btn',{attrs:{"color":"grey darken-4","dark":""},on:{"click":_vm.newUser}},[_vm._v(" "+_vm._s(_vm.$t("users.button"))+" ")]):_vm._e()],1),_c('v-alert',{attrs:{"value":_vm.alert.value,"type":"error"}},[_vm._v(" "+_vm._s(_vm.alert.message)+" ")]),_c('edit-dialog',{ref:"editDialog",on:{"close":_vm.getUsers,"error":function($event){''}}}),_c('datatable',{ref:"datatable",attrs:{"search":_vm.search,"institution":_vm.institution},on:{"edit":_vm.editUser}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }