<template>
  <v-dialog
    v-model="dialog"
    width="650"
    persistent
  >
    <v-card>
      <v-card-title class="redColor">
        <span class="headline">Actions</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <!---------------------------------------ALERT--------------------------------------->
              <v-flex xs12>
                <v-alert
                  :value="alert.value"
                  type="warning"
                  outline
                >
                  <v-layout
                    align-center
                    justify-center
                    row
                    wrap
                  >
                    <v-flex
                      xs12
                      sm6
                    >
                      {{ alert.message }}
                    </v-flex>
                    <v-flex
                      xs12
                      sm6
                    >
                      <v-layout row>
                        <v-btn
                          outline
                          small
                          color="success"
                          @click="confirm = true, alert.value = false"
                        >
                          {{ $t('parts.inventory.actions.alert.yes') }}
                        </v-btn>
                        <v-btn
                          outline
                          small
                          color="error"
                          @click="confirm = false, alert.value = false"
                        >
                          {{ $t('parts.inventory.actions.alert.no') }}
                        </v-btn>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-alert>
              </v-flex>
              <!--------------------------------------BUTTONS-------------------------------------->
              <v-flex xs12>
                <v-layout wrap>
                  <v-flex
                    xs6
                    sm3
                  >
                    <v-switch
                      v-model="switchS"
                      :disabled="$store.getters.role === 'User'"
                      :label="$t('parts.inventory.actions.sell')"
                      color="green"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
              <!------------------------------------CLIENTS ROW------------------------------------>
              <v-flex
                v-if="switchS && $store.getters.role !== 'User'"
                xs12
              >
                <v-layout
                  align-center
                  wrap
                >
                  <v-flex
                    xs6
                    sm8
                    md9
                  >
                    <v-autocomplete
                      v-model="client"
                      :items="clients"
                      :label="$t('parts.inventory.actions.select_client')"
                      :rules="rules.requiredSelect"
                      color="red darken-1"
                      item-text="name"
                      item-value="_id"
                      prepend-icon="person"
                      return-object
                    >
                      <template
                        slot="selection"
                        slot-scope="data"
                      >
                        <span v-if="data && data.item">
                          {{ data.item.name }} {{ data.item.surname }}
                        </span>
                        <span v-else />
                      </template>
                      <template
                        slot="item"
                        slot-scope="data"
                      >
                        <span v-if="data && data.item">
                          {{ data.item.name }} {{ data.item.surname }}
                        </span>
                        <span v-else />
                      </template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex
                    xs6
                    sm4
                    md3
                  >
                    <v-btn
                      color="success"
                      depressed
                      small
                      @click="addClient"
                    >
                      {{ $t('parts.inventory.actions.add_client') }}
                    </v-btn>
                  </v-flex>
                </v-layout>
              </v-flex>
              <!----------------------------------------------------------------------------------->
              <v-flex
                v-if="switchS && $store.getters.role !== 'User'"
                xs12
              >
                <v-textarea
                  v-model="notes"
                  outline
                  label="Notes"
                  color="red darken-2"
                />
              </v-flex>

              <v-flex
                v-if="switchS && $store.getters.role !== 'User'"
                xs12
              >
                <v-layout
                  row
                  wrap
                >
                  <!----------------------------------TOTAL PRICE---------------------------------->
                  <v-flex xs4>
                    <v-text-field
                      v-model="part.price"
                      :label="$t('parts.inventory.actions.total_price')"
                      color="red darken-1"
                      type="number"
                      min="0"
                      step="0.01"
                      oninput="validity.valid||(value='');"
                    />
                  </v-flex>
                  <!-----------------------------CHANGE OR DIFFERENCE------------------------------>
                  <v-flex xs4>
                    <v-text-field
                      v-if="cash - part.price > 0"
                      v-model="difference"
                      :label="$t('parts.inventory.actions.change')"
                      color="red darken-1"
                      disabled
                    />
                    <v-text-field
                      v-if="cash - part.price <= 0"
                      v-model="difference"
                      :label="$t('parts.inventory.actions.difference')"
                      color="red darken-1"
                      disabled
                    />
                  </v-flex>
                  <!-------------------------------------CASH-------------------------------------->
                  <v-flex xs4>
                    <v-text-field
                      v-model="cash"
                      :label="$t('parts.inventory.actions.cash')"
                      color="red darken-1"
                      type="number"
                      min="0"
                      step="0.01"
                      :max="part.price"
                      oninput="validity.valid||(value='');"
                    />
                  </v-flex>
                  <!------------------------------------------------------------------------------->
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('parts.inventory.actions.cancel') }}
        </v-btn>
        <v-btn
          v-if="switchS"
          :disabled="!valid
            || (client && typeof client === 'object' && Object.keys(client).length < 1)"
          :loading="loading"
          color="green"
          flat
          @click.native="sell"
        >
          {{ $t('parts.inventory.actions.sell') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';
import putRequest from '../../api/putRequest';

export default {
  data: () => ({
    dialog: false,
    valid: false,
    loading: false,
    switchS: true,
    alert: {
      value: false,
      message: '',
    },
    confirm: false,
    part: {},
    sale: {
      part: {},
      client: {},
    },
    client: {},
    clients: [],
    cash: 0,
    notes: '',
    rules: {
      requiredLetters: [
        v => !!v || 'Please fill this field',
        v => /[A-Za-z]/.test(v) || 'Only letters are allowed',
      ],
      requiredNumbers: [
        v => !!v || 'Please fill this field',
        v => /[0-9]/.test(v) || 'Only numbers are allowed',
      ],
      requiredSelect: [v => (v && !!v.name) || 'Item is required'],
    },
  }),
  computed: {
    difference() {
      if (this.cash - this.part.price >= 0) {
        const difference = (this.cash - this.part.price).toString();
        const trimedNumber = difference.substring(0, 6);
        return trimedNumber;
      }
      const difference = (this.part.price - this.cash).toString();
      const trimedNumber = difference.substring(0, 6);
      return trimedNumber;
    },
  },
  watch: {
    switchS(value) {
      if (value) {
        this.alert.value = false;
        this.alert.message = '';
      }
    },
    client(value) {
      if (value && typeof value === 'object' && Object.keys(value).length < 1) {
        this.sale.client_id = value._id;
        this.sale.client.name = value.name;
        this.sale.client.surname = value.surname;
        this.sale.client.email = value.email;
        this.sale.client.phone = value.phone;
      }
    },
  },
  methods: {
    open(part) {
      this.getClients();
      this.part = part;
      this.cash = part.price;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.part = {};
      this.sale = {
        part: {},
        client: {},
      };
      this.confirm = false;
      this.alert.value = false;
      this.alert.message = '';
      this.cash = 0;
      this.notes = '';
      this.client = {};
      this.clients = [];
      this.switchS = true;
      this.$emit('close');
    },
    sell() {
      this.loading = true;
      putRequest('/temporary-part/insert/sell', '', {
        user_id: this.$store.getters.userId,
        institution_id: this.$store.getters.institutionId,
        part_id: this.part._id,
        client_id: this.client._id,
        price: this.part.price,
        currency: 'EUR',
        cash: this.cash,
        notes: this.notes,
      })
        .then((response) => {
          this.loading = false;
          this.close();
        })
        .catch((error) => {
          console.log(error.response);
          this.loading = false;
          this.close();
        });
    },
    searchClient(search) {
      getRequest(
        '/client/search',
        `search=${search
        }&role=${this.$store.getters.role
        }&institution_id=${this.$store.getters.institutionId}`,
      )
        .then(response => console.log(response))
        .catch(error => console.log(error));
    },
    getClients() {
      getRequest(
        '/client/',
        `role=${this.$store.getters.role}&institution_id=${
          this.$store.getters.institutionId
        }`,
      )
        .then((response) => {
          this.clients = response.clients;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addClient() {
      this.$emit('add-client');
    },
    hide() {
      this.dialog = false;
    },
    show() {
      this.dialog = true;
    },
  },
};
</script>
