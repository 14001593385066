<template>
  <v-data-table
    :headers="headers"
    :items="warranties"
    :search="searchField"
    :loading="loading"
    hide-actions
    class="elevation-1"
  >
    <v-progress-linear
      slot="progress"
      color="red"
      indeterminate
    />
    <template
      slot="items"
      slot-scope="props"
    >
      <tr
        :class="{tableRowColorSelected : props.index == activeRow}"
        class="tableRowColor"
        @click="activeRow = props.index"
      >
        <td class="text-xs">
          {{ props.item.title }}
        </td>
        <td class="text-xs">
          {{ props.item.content }}
        </td>
        <td class="text-xs">
          {{ props.item.expire_date | moment }}
        </td>
        <td class="text-xs">
          {{ props.item.return_days / 86400 }} {{ $t('warranties.days') }}
        </td>
        <td class="text-xs">
          {{ props.item.return_before_warranty | moment }}
        </td>
        <td class="text-xs">
          {{ props.item.language }}
        </td>
        <td class="ma-1 pt-1 pb-1">
          <v-layout column>
            <qr-code
              :size="50"
              :text="props.item._id"
            />
            <h4>{{ props.item._id.slice(props.item._id.length-4, props.item._id.length) }}</h4>
          </v-layout>
        </td>
        <td
          v-if="$store.getters.role == 'LekoAdmin'
            || $store.getters.role == 'LekoEmployee'
            || $store.getters.role == 'InstitutionAdmin'
            || $store.getters.role == 'SuperAdmin'
          "
          class="justify-center"
        >
          <v-layout
            row
            align-center
          >
            <v-icon
              v-if="props.item.content_document"
              class="mr-2 pointer"
              @click="getDocument(props.item._id, props.item.content_document)"
            >
              view_list
            </v-icon>
            <v-icon
              class="mr-2 pointer"
              @click="editWarranty(props.item)"
            >
              edit
            </v-icon>
            <v-icon
              class="pointer"
              @click="deleteWarranty(props.item._id)"
            >
              delete
            </v-icon>
          </v-layout>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import moment from 'moment';
import getRequest from '../../api/getRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('MMMM Do YYYY');
    },
  },
  data: () => ({
    loading: false,
    activeRow: null,
    searchField: '',
    headers: [
      {
        text: '',
        sortable: false,
        value: 'title',
      },
      {
        text: '',
        sortable: false,
        value: 'content',
      },
      {
        text: "Expire Date: Manufacture's Warranty",
        sortable: false,
        value: 'expire_date',
      },
      {
        text: 'Warranty Days',
        sortable: false,
        value: 'return_days',
      },
      {
        text: 'Return, before activating warranty',
        sortable: false,
        value: 'return_before_warranty',
      },
      {
        text: '',
        sortable: false,
        value: 'language',
      },
      {
        text: 'Qr Code',
        sortable: false,
        value: 'qr_code',
      },
      {
        text: '',
        sortable: false,
      },
    ],
    warranties: [],
  }),
  created() {
    this.getLocale();
    this.getWarranties();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('warranties.table.title');
      this.headers[1].text = this.$i18n.t('warranties.table.content');
      this.headers[2].text = this.$i18n.t('warranties.table.expire');
      this.headers[3].text = this.$i18n.t('warranties.table.warranty_date');
      this.headers[4].text = this.$i18n.t('warranties.table.return');
      this.headers[5].text = this.$i18n.t('warranties.table.language');
      this.headers[6].text = this.$i18n.t('warranties.table.qr_code');
      this.headers[7].text = this.$i18n.t('warranties.table.actions');
    },
    search(data) {
      this.searchField = data;
    },
    getDocument(id, filename) {
      getRequest('/warranty/document/', `q=${id}-${filename}`)
        .then((response) => {
          const base64EncodedStr = btoa(unescape(encodeURIComponent(response)));
          window.open(`data:application/pdf;base64, ${base64EncodedStr}`, '', 'height=650,width=840');
        })
        .catch((error) => {
          this.$emit('error', error);
        });
    },
    getWarranties() {
      this.loading = true;
      getRequest('/warranty/')
        .then((response) => {
          this.warranties = response.warranties;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.$emit('error', error);
        });
    },
    editWarranty(data) {
      this.$emit('edit', data);
    },
    deleteWarranty(warrantyId) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loading = true;
        deleteRequest('/warranty/', `id=${warrantyId}`)
          .then((response) => {
            this.loading = false;
            this.getWarranties();
          })
          .catch((error) => {
            this.loading = false;
            this.$emit('error', error);
          });
      }
    },
  },
};
</script>
