<template>
  <div>
    <!-------------------------------------------TOOLBAR------------------------------------------->
    <toolbar @search-dialog="openSearchDialog" />
    <!---------------------------------------GENERAL DETAILS--------------------------------------->
    <stats v-if="$store.getters.role !== 'LekoBot'" />
    <!----------------------------------------USER HISTORY----------------------------------------->
    <div v-if="$store.getters.role !== 'LekoBot'">
      <!--TOOLBAR-->
      <v-toolbar
        color="grey darken-4"
        flat
        dark
        dense
      >
        <v-toolbar-title
          v-if="($vuetify.breakpoint.xsOnly && !userHistory ) || $vuetify.breakpoint.smAndUp"
          @click="userHistory = !userHistory"
        >
          {{ $t('home.user_history') }}
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-select
            v-if="userHistory"
            v-model="selectedItems"
            :items="selectionItems"
            :label="$t('home.select_status')"
            solo
            flat
            dense
            dark
            item-text="title"
            item-value="value"
            color="red darken-2"
            background-color="grey darken-4"
            multiple
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.title }}</span>
              </v-chip>
              <span
                v-if="index === 1"
                class="grey--text caption"
              >(+{{ selectedItems.length - 1 }} others)</span>
            </template>
          </v-select>

          <v-btn
            v-if="userHistory && $vuetify.breakpoint.smAndUp"
            :disabled="selectedItems.length < 1"
            flat
            icon
            @click="loadCharts"
          >
            <v-icon>cached</v-icon>
          </v-btn>

          <v-btn
            flat
            icon
            @click="userHistory = !userHistory"
          >
            <v-icon v-if="userHistory">
              expand_less
            </v-icon>
            <v-icon v-else>
              expand_more
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <!--USER SELECTION (ADMIN & OWNER)-->
      <v-layout
        v-if="
          userHistory &&
            ($store.getters.role === 'LekoAdmin'
              || $store.getters.role === 'LekoEmployee'
              || $store.getters.role === 'InstitutionAdmin'
              || $store.getters.role === 'SuperAdmin')
        "
        mt-2
        align-center
        justify-center
        row
        wrap
      >
        <v-flex
          pl-2
          pr-2
          xs12
          sm6
          md3
        >
          <v-select
            v-model="selectedUserRole"
            :items="filteredUserRoles"
            :label="$t('home.user_role')"
            :hint="$t('home.select_user_role')"
            solo
            flat
            item-text="title"
            item-value="value"
            persistent-hint
            color="red darken-2"
          />
        </v-flex>

        <v-flex
          pl-2
          pr-2
          xs12
          sm6
          md3
        >
          <v-select
            v-model="selectedUser"
            :items="users"
            :hint="$t('home.select_user')"
            :loading="loading"
            solo
            flat
            item-text="name"
            item-value="_id"
            persistent-hint
            color="red darken-2"
            prepend-inner-icon="clear"
            @click:prepend-inner="selectedUser = null"
          />
        </v-flex>
      </v-layout>

      <!--BTN XS-->
      <v-layout
        v-if="userHistory && $vuetify.breakpoint.xsOnly"
        align-center
        justify-center
        row
      >
        <v-btn
          depressed
          color="black"
          dark
          @click="loadCharts"
        >
          {{ $t('home.get_activity') }}
        </v-btn>
      </v-layout>

      <!--CHARTS-->
      <charts
        v-if="userHistory"
        ref="charts"
      />
    </div>
    <!-------------------------------------DELETE INSTITUTION-------------------------------------->
    <div v-if="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'SuperAdmin'">
      <!--TOOLBAR-->
      <v-toolbar
        color="grey darken-4"
        flat
        dark
        dense
      >
        <v-toolbar-title
          v-if="($vuetify.breakpoint.xsOnly && !dInstitution ) || $vuetify.breakpoint.smAndUp"
          @click="dInstitution = !dInstitution"
        >
          {{ $t('home.delete_institution') }}
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-btn
            flat
            icon
            @click="dInstitution = !dInstitution"
          >
            <v-icon v-if="dInstitution">
              expand_less
            </v-icon>
            <v-icon v-else>
              expand_more
            </v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <!--CONTENT-->
      <v-card
        v-if="dInstitution"
        flat
        class="ma-2"
      >
        <v-layout
          row
          wrap
          justify-center
        >
          <v-flex
            xs12
            class="pa-2"
          >
            <v-alert
              :value="alert.value"
              :type="alert.type"
            >
              {{ alert.message }}
            </v-alert>
          </v-flex>

          <v-flex
            xs12
            sm4
            class="pa-1"
          >
            <v-autocomplete
              v-model="selectedInstitution"
              :items="institutions"
              :label="$t('home.institution_title')"
              :hint="$t('home.select_institution')"
              :loading="loadingInstitutions"
              solo
              flat
              item-text="title"
              item-value="_id"
              persistent-hint
              color="red darken-2"
            />
          </v-flex>

          <v-flex
            xs12
            sm4
            class="pa-1"
          >
            <v-text-field
              id="password"
              v-model="password"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              prepend-inner-icon="password"
              color="red darken-2"
              @click:append="showPassword = !showPassword"
            />
          </v-flex>

          <v-flex
            xs12
            sm4
            class="pa-1 pl-4"
          >
            <v-btn
              color="red darken-4"
              dark
              :loading="loadingDelete"
              @click="deleteInstitution"
            >
              {{ $t('home.delete') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </div>

    <search-dialog ref="searchDialog" />
  </div>
</template>

<script>
import Toolbar from '@/components/Home/toolbar.vue';
import Charts from '@/components/Home/charts.vue';
import Stats from '@/components/Home/stats.vue';
import SearchDialog from '@/components/Home/searchDialog.vue';
import getRequest from '../api/getRequest';
import putRequest from '../api/putRequest';

export default {
  components: {
    charts: Charts,
    toolbar: Toolbar,
    stats: Stats,
    searchDialog: SearchDialog,
  },
  data: () => ({
    loading: false,
    userHistory: true,
    userRoles: [
      { title: 'Leko Employee', value: 'LekoEmployee' },
      { title: 'Owner', value: 'InstitutionAdmin' },
      { title: 'Manager', value: 'InstitutionManager' },
      { title: 'Worker', value: 'User' },
      { title: 'Admin', value: 'LekoAdmin' },
      { title: 'SuperAdmin', value: 'SuperAdmin' },
    ],
    selectedUserRole: '',
    users: [],
    selectedUser: null,
    selectionItems: [
      // { title: 'Inserted Empty Parts', value: '0.1' },
      // { title: 'Deleted Empty Parts', value: '0.2' },
      { title: 'Inserted Temporary Parts', value: '1.0' },
      // { title: 'Deleted Temporary Parts', value: '1.5' },
      { title: 'Parts Sent For Approval', value: '1.1' },
      { title: 'Parts Sent For Review', value: '1.2' },
      { title: 'Refused Parts', value: '1.3' },
      { title: 'Approved Parts', value: '1.4' },
      { title: 'Inserted Inventory', value: '2.0' },
      { title: 'Saved in Approved Parts', value: '1.6' },
      // { title: 'Deleted Inventory', value: '2.1' },
      // { title: 'Returned from Sales', value: '3.0' },
      // { title: 'Returned from Shop', value: '3.1' },
      // { title: 'Re-Entered', value: '4.0' },
      // { title: 'Found', value: '5.0' },
      { title: 'Sold', value: '6.0' },
      // { title: 'Lost', value: '7.0' },
      // { title: 'Damaged', value: '8.0' },
      // { title: 'Damaged from Sales', value: '8.1' },
      // { title: 'Damaged from Shop', value: '8.2' },
      // { title: 'Damaged from Lost Parts', value: '8.3' },
    ],
    selectedItems: [],
    statusString: '',
    selectedInstitution: null,
    institutions: [],
    loadingInstitutions: false,
    password: null,
    showPassword: false,
    loadingDelete: false,
    alert: {
      value: false,
      type: 'success',
      message: '',
    },
    dInstitution: false,
  }),
  computed: {
    filteredUserRoles() {
      // Get the logged-in user's role
      const currentRole = this.$store.getters.role;
      // Only include `SuperAdmin` if the logged-in user is a `SuperAdmin`
      return this.userRoles.filter(role => role.value !== 'SuperAdmin' || currentRole === 'SuperAdmin');
    },
  },
  watch: {
    selectedItems(value) {
      let str = '';
      value.forEach((element) => {
        str += `${element},`;
      });
      this.statusString = str;
    },
    selectedUserRole(value) {
      this.getUsersByRole(value);
    },
    selectedUser(value) {
      if (value !== null && this.selectedItems.length > 0) {
        this.loadCharts();
      }
    },
  },
  mounted() {
    this.getLocale();
    this.getInstitutions();
  },
  created() {
    const currentUsersRole = this.$store.getters.role;
    this.selectedUserRole = currentUsersRole;
    this.selectedUser = this.$store.getters.userId;
    // If logged in user's role is admin he can see all kind of roles.
    if (currentUsersRole === 'InstitutionAdmin') { // Owner
      this.userRoles = this.userRoles.filter(obj => obj.value !== 'LekoAdmin' && obj.value !== 'LekoEmployee' && obj.value !== 'SuperAdmin');
    } else if (currentUsersRole === 'LekoEmployee') { // LekoEmployee
      this.userRoles = this.userRoles.filter(obj => obj.value !== 'LekoAdmin' && obj.value !== 'SuperAdmin');
    } else if (currentUsersRole === 'InstitutionManager') { // Manager
      this.userRoles = this.userRoles.filter(obj => obj.value !== 'LekoAdmin' && obj.value !== 'InstitutionAdmin' && obj.value !== 'LekoEmployee' && obj.value !== 'SuperAdmin');
    } else if (currentUsersRole === 'User') { // Worker
      this.userRoles = this.userRoles.filter(obj => obj.value !== 'LekoAdmin' && obj.value !== 'InstitutionAdmin' && obj.value !== 'InstitutionManager' && obj.value !== 'LekoEmployee' && obj.value !== 'SuperAdmin');
    }
  },
  methods: {
    getLocale() {
      // this.selectionItems[0].title = this.$i18n.t('part_status.inserted_empty_parts');
      // this.selectionItems[1].title = this.$i18n.t('part_status.deleted_empty_parts');
      this.selectionItems[0].title = this.$i18n.t('part_status.inserted_temporary_parts');
      // this.selectionItems[3].title = this.$i18n.t('part_status.deleted_empty_parts');
      this.selectionItems[1].title = this.$i18n.t('part_status.parts_sent_for_approval');
      this.selectionItems[2].title = this.$i18n.t('part_status.parts_sent_for_review');
      this.selectionItems[3].title = this.$i18n.t('part_status.refused_parts');
      this.selectionItems[4].title = this.$i18n.t('part_status.approved_parts');
      this.selectionItems[5].title = this.$i18n.t('part_status.inserted_inventory');
      this.selectionItems[6].title = this.$i18n.t('part_status.saved_approve_parts');
      // this.selectionItems[9].title = this.$i18n.t('part_status.deleted_inventory');
      // this.selectionItems[10].title = this.$i18n.t('part_status.returned_sales');
      // this.selectionItems[11].title = this.$i18n.t('part_status.returned_shop');
      // this.selectionItems[12].title = this.$i18n.t('part_status.re_entered');
      // this.selectionItems[13].title = this.$i18n.t('part_status.found');
      this.selectionItems[7].title = this.$i18n.t('part_status.sold');
      // this.selectionItems[15].title = this.$i18n.t('part_status.lost');
      // this.selectionItems[16].title = this.$i18n.t('part_status.damaged');
      // this.selectionItems[17].title = this.$i18n.t('part_status.damaged_sales');
      // this.selectionItems[18].title = this.$i18n.t('part_status.damaged_shop');
      // this.selectionItems[19].title = this.$i18n.t('part_status.damaged_lost');

      if (this.userRoles[0]) {
        this.userRoles[0].title = this.$i18n.t('user_status.leko_employee');
      }
      if (this.userRoles[1]) {
        this.userRoles[1].title = this.$i18n.t('user_status.owner');
      }
      if (this.userRoles[2]) {
        this.userRoles[2].title = this.$i18n.t('user_status.manager');
      }
      if (this.userRoles[3]) {
        this.userRoles[3].title = this.$i18n.t('user_status.worker');
      }
      if (this.userRoles[4]) {
        this.userRoles[4].title = this.$i18n.t('user_status.admin');
      }
    },
    loadCharts() {
      const { charts } = this.$refs;
      if (this.selectedUser !== null && this.selectedItems.length < 1) {
        /**
         * THROWS ERROR
         */
      } else {
        charts.loadCharts(this.statusString, this.selectedUser === null
          ? this.$store.getters.userId
          : this.selectedUser);
      }
    },
    getUsersByRole(role) {
      this.loading = true;
      getRequest('/user/', `role=${this.$store.getters.role}&query_role=${role}&institution_id=${this.$store.getters.institutionId}`)
        .then((response) => {
          this.users = response.users;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getInstitutions() {
      this.loadingInstitutions = true;
      getRequest('/institution/')
        .then((response) => {
          this.institutions = response.institutions;
          this.loadingInstitutions = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingInstitutions = false;
        });
    },
    deleteInstitution() {
      this.loadingDelete = true;
      putRequest('/institution/thorough-delete', `id=${this.selectedInstitution}`, { password: this.password })
        .then((result) => {
          this.alert = {
            value: true,
            type: 'success',
            message: result.data,
          };
        })
        .catch((error) => {
          this.alert = {
            value: true,
            type: 'error',
            message: error,
          };
        })
        .finally(() => {
          this.loadingDelete = false;
          setTimeout(() => { this.alert.value = false; }, 2000);
        });
    },
    openSearchDialog() {
      const { searchDialog } = this.$refs;
      searchDialog.open();
    },
  },
};
</script>
