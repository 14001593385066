<template>
  <div>
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t('tests.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
      <v-text-field
        v-model="search"
        :label="$t('tests.search')"
        flat
        solo
        color="red darken-1"
        append-icon="search"
        single-line
        hide-details
      />
      <v-spacer class="hidden-sm-and-down" />

      <v-dialog
        v-model="dialog"
        persistent
        max-width="500px"
      >
        <v-btn
          v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'LekoEmployee' || $store.getters.role == 'SuperAdmin'"
          slot="activator"
          color="grey darken-4"
          dark
        >
          {{ $t('tests.button') }}
        </v-btn>
        <v-card>
          <v-card-title class="redColor">
            <span
              v-if="edit == true"
              class="headline"
            >{{ $t('tests.form.edit_test') }}</span>
            <span
              v-if="edit == false"
              class="headline"
            >{{ $t('tests.form.create_test') }}</span>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12>
                    <v-select
                      v-model="test.part_id"
                      :items="parts"
                      :label="$t('tests.form.select_part')"
                      color="red darken-1"
                      item-text="title"
                      item-value="_id"
                      return-object
                    />
                  <!-- <v-menu transition="slide-y-transition" bottom>
                    <v-btn outline @click="getParts" slot="activator" color="primary" dark>
                      {{$t('tests.form.select_part')}}
                      <v-icon right>arrow_drop_down</v-icon>
                    </v-btn>
                    <v-list>
                      <v-list-tile
                        v-for="(part, index) in parts"
                        :key="index"
                        @click="getPartId(part._id)"
                      >
                        <v-list-tile-title>{{ part.title }}</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu> -->
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="test.title"
                      :rules="rules.requiredLetters"
                      :label="$t('tests.form.title')"
                      color="red darken-1"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="test.description"
                      :rules="rules.requiredLetters"
                      :label="$t('tests.form.description')"
                      color="red darken-1"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <!-- <v-text-field v-model="test.photo" label="Photos"></v-text-field> -->

                    <label for="photo">{{ $t('tests.form.upload_photo') }}</label>
                    <br>
                    <input
                      id="photo"
                      ref="photos"
                      name="photos"
                      type="file"
                      enctype="multipart/form-data"
                      multiple
                      @change="getPhotos()"
                    >
                  </v-flex>
                  <v-flex xs12>
                    <!-- <v-text-field v-model="test.video" label="Videos"></v-text-field> -->

                    <label for="video">{{ $t('tests.form.upload_video') }}</label>
                    <br>
                    <input
                      id="video"
                      name="videos"
                      type="file"
                      enctype="multipart/form-data"
                      multiple
                      @change="getVideos($event)"
                    >
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      v-model="test.results"
                      :rules="rules.requiredLetters"
                      :label="$t('tests.form.results')"
                      color="red darken-1"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-select
                      v-model="test.warranty_id"
                      :items="warranties"
                      :label="$t('tests.form.select_warranty')"
                      color="red darken-1"
                      item-text="title"
                      item-value="_id"
                      return-object
                    />
                  <!-- <v-menu transition="slide-y-transition" bottom>
                    <v-btn outline @click="getWarranties" slot="activator" color="primary" dark>
                      {{$t('tests.form.select_warranty')}}
                      <v-icon right>arrow_drop_down</v-icon>
                    </v-btn>
                    <v-list>
                      <v-list-tile
                        v-for="(warranty, index) in warranties"
                        :key="index"
                        @click="getWarrantyId(warranty._id)"
                      >
                        <v-list-tile-title>{{warranty.title }}</v-list-tile-title>
                      </v-list-tile>
                    </v-list>
                  </v-menu> -->
                  </v-flex>
                </v-layout>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="red darken-1"
              flat
              @click.native="close"
            >
              {{ $t('tests.form.close') }}
            </v-btn>
            <v-btn
              :disabled="!valid"
              color="blue darken-1"
              flat
              @click.native="save"
            >
              {{ $t('tests.form.save') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <v-alert
      :value="alert.value"
      type="error"
    >
      {{ alert.message }}
    </v-alert>

    <v-data-table
      :headers="headers"
      :items="tests.tests"
      :search="search"
      :loading="loading"
      hide-actions
      class="elevation-1"
    >
      <v-progress-linear
        slot="progress"
        color="red"
        indeterminate
      />
      <template
        slot="items"
        slot-scope="props"
      >
        <tr
          :class="{tableRowColorSelected : props.index == activeRow}"
          class="tableRowColor"
          @click="activeRow = props.index"
        >
          <td class="text-xs">
            {{ props.item.title }}
          </td>
          <td class="text-xs">
            {{ props.item.description }}
          </td>
          <td class="text-xs">
            {{ props.item.part_id }}
          </td>
          <!-- <td class="text-xs">{{ props.item.photo }}</td> -->
          <td>
            <img
              v-for="(photo, index) in props.item.photo"
              :key="index"
              :src="`${server}/images/?q=${props.item._id}-${props.item.photo[index]}`"
              height="35"
              width="35"
            >
          </td>
          <td class="text-xs">
            {{ props.item.video }}
          </td>
          <td class="text-xs">
            {{ props.item.results }}
          </td>
          <td class="text-xs">
            {{ props.item.warranty_id }}
          </td>
          <td class="ma-1 pb-1 pt-1">
            <v-layout column>
              <qr-code
                :size="50"
                :text="props.item._id"
              />
              <h4>{{ props.item._id.slice(props.item._id.length-4, props.item._id.length) }}</h4>
            </v-layout>
          </td>
          <td
            v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'LekoEmployee' || $store.getters.role == 'SuperAdmin'"
            class="justify-center"
          >
            <v-layout align-center>
              <v-icon
                class="mr-2 pointer"
                @click="editTest(props.item._id)"
              >
                edit
              </v-icon>
              <v-icon
                class="pointer"
                @click="deleteTest(props.item._id)"
              >
                delete
              </v-icon>
            </v-layout>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import VueQrCode from 'vue-qr-generator';

import getRequest from '../../api/getRequest';
import postRequest from '../../api/postRequest';
import putRequest from '../../api/putRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  components: {
    'qr-code': VueQrCode,
  },
  data: () => ({
    loading: true,
    search: '',
    valid: true,
    dialog: false,
    alert: {
      value: false,
      message: String,
    },
    headers: [{
      text: '',
      sortable: false,
      value: 'title',
    },
    {
      text: '',
      sortable: false,
      value: 'description',
    },
    {
      text: '',
      sortable: false,
      value: 'part_id',
    },
    {
      text: '',
      sortable: false,
      value: 'photo',
    },
    {
      text: '',
      sortable: false,
      value: 'video',
    },
    {
      text: '',
      sortable: false,
      value: 'results',
    },
    {
      text: '',
      sortable: false,
      value: 'warranty_id',
    },
    {
      text: 'Qr Code',
      sortable: false,
      value: 'qr_code',
    },
    {
      text: '',
      sortable: false,
    },
    ],
    files: {},
    test: {},
    tests: {},
    parts: [],
    warranties: [],
    rules: {
      requiredLetters: [
        v => !!v || 'Please fill this field',
        v => /[A-Za-z]/.test(v) || 'Only letters are allowed',
      ],
    },
    edit: false,
    // eslint-disable-next-line no-undef
    token: localStorage.token,
    activeRow: null,
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
  },
  created() {
    this.getTests();
    this.getParts();
    this.getWarranties();
    this.getLocale();
    this.edit = false;
    if (this.$store.getters.role === 'User') {
      this.headers.pop();
    }
    this.activeRow = null;
  },
  methods: {
    getLocale() {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      const locale = require(`@/assets/locales/${this.$store.getters.language}.json`);
      this.headers[0].text = locale.tests.table.title;
      this.headers[1].text = locale.tests.table.description;
      this.headers[2].text = locale.tests.table.part_id;
      this.headers[3].text = locale.tests.table.photo;
      this.headers[4].text = locale.tests.table.video;
      this.headers[5].text = locale.tests.table.results;
      this.headers[6].text = locale.tests.table.warranty_id;
      this.headers[7].text = locale.tests.table.qr_code;
      this.headers[8].text = locale.tests.table.actions;
    },
    uploadFiles(fileId) {
      const formData = new FormData();
      for (let i = 0; i < this.files.length; i += 1) {
        const file = this.files[i];
        formData.append('photos', file);
      }
      postRequest.object('/test/photos/', `q=${fileId}`, formData, {
        headers: {
          authorization: this.token,
          'Content-Type': 'multipart/form-data',
        },
      }).then((response) => {
        if (response.status === 200) {
          this.getTests();
        }
      });
    },
    getPhotos() {
      const photos = [];
      for (let i = 0; i < this.$refs.photos.files.length; i += 1) {
        photos.push(this.$refs.photos.files[i].name);
      }
      // console.log(this.$refs.photos.files);
      this.files = this.$refs.photos.files;
      this.test.photo = photos;
    },
    getVideos(video) {
      const videos = [];
      for (let i = 0; i < video.target.files.length; i += 1) {
        videos.push(video.target.files[i].name);
      }
      this.test.video = videos;
    },
    getTests() {
      getRequest('/test/').then((response) => {
        this.tests = response;
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        this.loading = false;
      });
    },
    close() {
      this.$refs.form.reset();
      this.edit = false;
      this.dialog = false;
      this.test = {};
      this.getTests();
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.edit === true) {
          putRequest('/test/', `id=${this.test._id}`, this.test).then((response) => {
            this.uploadFiles(response.data.id);
          }).catch((error) => {
            console.log(error);
            this.alert.value = true;
            this.alert.message = 'Something went wrong';
            setTimeout(() => {
              this.alert.value = false;
            }, 2000);
          });
        } else {
          postRequest.object('/test/', this.test).then((response) => {
            this.uploadFiles(response.data.id);
          }).catch((error) => {
            console.log(error);
            this.alert.value = true;
            this.alert.message = 'Something went wrong';
            setTimeout(() => {
              this.alert.value = false;
            }, 2000);
          });
        }
        this.dialog = false;
        this.edit = false;
        this.test = {};
      }
    },
    editTest(testId) {
      this.edit = true;
      getRequest('/test/', `id=${testId}`).then((response) => {
        this.test = response.test[0]; // eslint-disable-line prefer-destructuring
      });
      this.dialog = true;
    },
    deleteTest(testId) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loading = true;
        deleteRequest('/test/', `id=${testId}`).then((response) => {
          if (response.status === 200) {
            this.getTests();
          }
        });
      }
    },
    getParts() {
      getRequest(
        '/part/',
        `user_id=${this.$store.getters.userId}&role=${this.$store.getters.role}`,
      )
        .then((response) => {
          this.parts = response.parts;
        }).catch((error) => {
          console.log(error);
        });
    },
    getWarranties() {
      getRequest('/warranty').then((response) => {
        this.warranties = response.warranties;
      }).catch((error) => {
        console.log(error);
      });
    },
  },
};
</script>
