<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="650px"
  >
    <v-card>
      <v-card-title class="redColor">
        <v-layout
          align-center
          row
        >
          <v-flex
            xs8
            sm10
          >
            <span class="headline">
              {{ $t("leko_employees.approve_parts.form.approve_part") }}
            </span>
          </v-flex>
          <!---------------------------------AUTOBOT BUTTON CODE--------------------------------->
          <!-- -->
          <v-flex

            xs2
            sm1
          >
            <v-btn
              v-if="part.car_id != null && part.car_id != '' "
              outline
              icon
              large
              color="black"
              :loading="loading"
              @click="autoBotApi(part)"
            >
              <v-icon large>
                mdi-robot
              </v-icon>
            </v-btn>
          </v-flex>

          <!---------------------------------CLOSE BUTTON CODE--------------------------------->
          <v-flex
            xs2
            sm1
          >
            <v-btn
              outline
              icon
              large
              color="black"
              @click="close"
            >
              <v-icon x-large>
                close
              </v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-alert
          v-model="alert.value"
          :type="alert.type"
        >
          {{ $t(alert.message) }}
        </v-alert>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout
              row
              wrap
            >
              <!---------------------------------MANUFACTURER CODE--------------------------------->
              <v-flex xs6>
                <v-text-field
                  v-model="part.manufacturer_code"
                  :label="$t('leko_employees.approve_parts.form.manufacturer_code')"
                  :loading="loadingManufacturer"
                  color="red darken-1"
                  append-icon="find_replace"
                  @keyup.enter="searchByManufacturer()"
                  @click:append="searchByManufacturer()"
                />
              </v-flex>
              <!-----------------------------------INFOCAR CODE------------------------------------>
              <v-flex xs6>
                <!--TEXTFIELD-->
                <v-text-field
                  v-model="infoCarCode"
                  :color="found ? 'success': 'error'"
                  :append-icon="found ? 'check_circle': 'error'"
                  :label="$t('leko_employees.approve_parts.form.info_car_code')"
                  :loading="loadingInfoCar"
                  :disabled="customInfoCarTitle"
                  @keyup.enter="searchCode()"
                  @click:append="searchCode()"
                />
              </v-flex>
              <!----------------------------------INFOCAR RESULT----------------------------------->
              <v-flex xs12>
                <v-select
                  v-model="selectedInfoCar"
                  :items="infoCarResult"
                  :disabled="!found"
                  item-text="_id"
                  item-value="_id"
                  :label="$t('leko_employees.approve_parts.form.info_car_result')"
                  color="red darken-1"
                  return-object
                >
                  <template v-slot:item="{ item }">
                    {{ item.oemCode }} - {{ item.description.descriptionId.category.it }}
                    : {{ item.description.descriptionId.title.it }}
                  </template>

                  <template v-slot:selection="{ item }">
                    {{ item.oemCode }} - {{ item.description.descriptionId.category.it }}
                    : {{ item.description.descriptionId.title.it }}
                  </template>
                </v-select>
              </v-flex>
              <!-----------------------------------CUSTOM TITLE------------------------------------>
              <v-flex xs12>
                <v-autocomplete
                  v-model="customInfoCarTitle"
                  :loading="loadingCustomInfoCarTitle"
                  :items="infoCarTitles"
                  :search-input.sync="searchInfoCarTitle"
                  :label="$t('leko_employees.approve_parts.form.info_car_custom_title')"
                  :filter="() => true"
                  :item-text="`title.${$store.getters.language}`"
                  :disabled="found"
                  item-value="partCode"
                  color="red darken-1"
                  return-object
                  :append-icon="customInfoCarTitle === null ? 'search' : 'keyboard_backspace'"
                  @click:append="customInfoCarTitle = null"
                />
              </v-flex>
              <!-------------------------------------OEM CODES------------------------------------->
              <v-flex>
                <v-text-field
                  v-model="oemCode"
                  :label="$t('leko_employees.approve_parts.form.oem_code')"
                  color="red darken-1"
                  append-icon="add"
                  @keyup.enter="addOemCode()"
                  @click:append="addOemCode()"
                />
              </v-flex>
              <v-flex v-if="part.oemCodes && part.oemCodes.length > 0">
                <div class="caption grey--text text--darken-1">
                  {{ $t('leko_employees.approve_parts.form.oem_codes') }}
                </div>
                <v-chip
                  v-for="(item, index) in part.oemCodes"
                  :key="index"
                >
                  {{ item }}
                  <v-icon
                    right
                    @click="removeOemCode(index)"
                  >
                    remove_circle
                  </v-icon>
                </v-chip>
              </v-flex>
              <!---------------------------------------NOTES--------------------------------------->
              <v-flex xs12>
                <v-text-field
                  v-model="part.notes"
                  :label="$t('leko_employees.approve_parts.form.notes')"
                  color="red darken-1"
                  disabled
                />
              </v-flex>
              <!--------------------------------------WARRANTY------------------------------------->
              <v-flex xs6>
                <v-text-field
                  v-if="part.warranty_id"
                  v-model="warrantyValue"
                  :label="$t('leko_employees.approve_parts.form.warranty')"
                  color="red darken-1"
                  disabled
                />
                <v-text-field
                  v-else
                  :label="$t('leko_employees.approve_parts.form.warranty')"
                  color="red darken-1"
                  disabled
                />
              </v-flex>
              <!---------------------------------------PRICE--------------------------------------->
              <v-flex xs6>
                <v-text-field
                  v-model="priceAndCurrency"
                  :label="$t('leko_employees.approve_parts.form.price')"
                  color="red darken-1"
                  disabled
                />
              </v-flex>
              <!--------------------------------------QUALITY-------------------------------------->
              <v-flex xs12>
                <v-layout justify-center>
                  <v-chip>
                    <v-rating
                      v-if="part.quality"
                      v-model="part.quality.rating"
                      color="red"
                      background-color="red"
                      readonly
                    />
                  </v-chip>
                </v-layout>
              </v-flex>
              <!-----------------------------------INTERNAL NOTES---------------------------------->
              <v-flex xs12>
                <v-text-field
                  v-model="part.internal_notes"
                  :label="$t('leko_employees.approve_parts.form.internal_notes')"
                  color="red darken-1"
                />
              </v-flex>
              <!-------------------------------------LEKO NOTES------------------------------------>
              <v-flex xs12>
                <v-text-field
                  v-model="part.leko_notes"
                  :label="$t('leko_employees.approve_parts.form.leko_notes')"
                  color="red darken-1"
                />
              </v-flex>
              <!-----------------------------------REFUSE REASON----------------------------------->
              <v-flex xs12>
                <v-text-field
                  v-model="part.refusal_reason"
                  :label="$t('leko_employees.approve_parts.form.refusal_reason')"
                  color="red darken-1"
                />
              </v-flex>
              <!---------------------------------------TESTS--------------------------------------->
              <v-flex xs12>
                <v-select
                  v-model="part.tests"
                  :items="tests"
                  :label="$t('leko_employees.approve_parts.form.tests')"
                  :hint="$t('leko_employees.approve_parts.form.tests_label')"
                  color="red darken-1"
                  item-text="title"
                  item-value="title"
                  multiple
                  persistent-hint
                />
              </v-flex>
              <!--------------------------------------IMAGES--------------------------------------->
              <v-flex xs12>
                <v-container
                  grid-list-sm
                  fluid
                >
                  <v-layout
                    row
                    wrap
                  >
                    <v-flex
                      v-for="image in part.images"
                      :key="image.image"
                      xs6
                      sm4
                      d-flex
                    >
                      <v-hover>
                        <v-card
                          slot-scope="{ hover }"
                          flat
                          tile
                          class="d-flex"
                        >
                          <v-img :src="`${server}/images/?q=${image.thumb}`">
                            <v-layout
                              row
                              ma-1
                            >
                              <v-icon
                                v-if="hover"
                                x-large
                                class="pointer"
                                :color="isSelected(image) ? 'primary' : ''"
                                @click="setSelectedImage(image)"
                              >
                                {{ isSelected(image) ? 'check_box' : 'check_box_outline_blank' }}
                              </v-icon>
                              <v-icon
                                v-if="hover"
                                x-large
                                class="pointer"
                                @click="viewImage({ type: 'string', data: image })"
                              >
                                image
                              </v-icon>
                              <v-spacer />
                            </v-layout>
                          </v-img>
                        </v-card>
                      </v-hover>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>


              <!----------------------------------------------------------------------------------->
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <!------------------------------------------ACTIONS------------------------------------------>
      <v-card-actions>
        <v-layout
          align-center
          justify-center
          :justify-end="$vuetify.breakpoint.smAndUp"
          row
          wrap
        >
          <v-spacer v-if="$vuetify.breakpoint.smAndUp" />
          <!--CLOSE-->
          <v-btn
            color="red darken-1"
            flat
            @click="close"
          >
            {{ $t('leko_employees.approve_parts.form.close') }}
          </v-btn>
          <!--REFUSE-->
          <!--
          v-if="!part.kit
          || (part.kit && part.kit === false)
          || (part.kit && part.kit === true && part.kit_main_part && part.kit_main_part === true)"
          -->

          <v-btn
            :disabled="!part.refusal_reason"
            :loading="loadingRefuse"
            color="error"
            flat
            @click.native="refuse"
          >
            {{ $t('leko_employees.approve_parts.form.refuse') }}
          </v-btn>
          <!--REVIEW-->
          <!--
          v-if="!part.kit
          || (part.kit && part.kit === false)
          || (part.kit && part.kit === true && part.kit_main_part && part.kit_main_part === true)"
          -->
          <v-btn
            :loading="loadingReview"
            color="warning"
            flat
            @click.native="review"
          >
            {{ $t('leko_employees.approve_parts.form.review') }}
          </v-btn>
          <!--SAVE-->
          <v-btn
            color="green darken-1"
            flat
            @click="save"
          >
            {{ $t('leko_employees.approve_parts.form.save') }}
          </v-btn>
          <!--APPROVE-->
          <!--
          v-if="!part.kit
          || (part.kit && part.kit === false)
          || (part.kit && part.kit === true && part.kit_main_part && part.kit_main_part === true)"
          -->
          <v-btn
            :disabled="!found && !selectedInfoCar"
            :loading="loadingApprove"
            color="success"
            flat
            @click.native="approve"
          >
            {{ $t('leko_employees.approve_parts.form.approve') }}
          </v-btn>
        </v-layout>
      </v-card-actions>
      <!------------------------------------------------------------------------------------------->
      <v-card-actions />
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';
import postRequest from '../../api/postRequest';
import putRequest from '../../api/putRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  props: ['hide'], // eslint-disable-line vue/require-prop-types
  data: () => ({
    dialog: false,
    valid: false,
    found: false,
    loading: false,
    loadingManufacturer: false,
    loadingInfoCar: false,
    loadingApprove: false,
    loadingRefuse: false,
    loadingReview: false,
    loadingSave: false,
    loadingCustomInfoCarTitle: false,
    infoCarCode: null,
    infoCarResult: [],
    selectedInfoCar: null,
    oemCode: null,
    customInfoCarTitle: null,
    searchInfoCarTitle: null,
    infoCarTitles: [],
    selectedImage: null,
    part: {},
    tests: [],
    alert: {
      value: false,
      message: '',
      type: 'error',
    },
    imageUrl: null,
  }),
  computed: {
    server() {
      return this.$serverUri;
    },
    priceAndCurrency() {
      if (this.part.price && this.part.currency) {
        return `${this.part.price} ${this.part.currency}`;
      }
      return '';
    },
    warrantyValue() {
      if (this.part.warranty_id) {
        return `${parseInt(this.part.warranty_id.return_days, 10) / 86400} ${this.$i18n.t('warranties.days')}`;
      }
      return '';
    },
    hideDialog() {
      return this.$props.hide;
    },
  },
  watch: {
    hideDialog(value) {
      if (value) {
        this.dialog = false;
      } else {
        this.dialog = true;
      }
    },
    searchInfoCarTitle(value) {
      if (value) {
        this.loadingCustomInfoCarTitle = true;
        getRequest('/info-car/parts', `title=${value}&language=${this.$store.getters.language}&all=true`)
          .then((response) => { this.infoCarTitles = response.parts; })
          .catch(error => console.log(error))
          .finally(() => { this.loadingCustomInfoCarTitle = false; });
      } else {
        this.customInfoCarTitle = null;
      }
    },
    customInfoCarTitle(value) {
      if (value) {
        this.part.infoCar = {
          description: {
            descriptionCode: value.partCode,
            positionCode: 'S000', // Can't determine the postition.
            descriptionId: value._id,
          },
        };
      } else { delete this.part.infoCar; }
    },
  },
  methods: {
    open(data) {
      this.part = data;
      this.dialog = true;
      this.getTests();
      this.postActivePart(data._id);
      // this.getImages(data);
      // this.getTitleFromImage(data);
    },
    close() {
      this.removeActivePart(this.part._id);
      this.dialog = false;
      this.part = {};
      this.infoCarResult = [];
      this.selectedInfoCar = null;
      this.infoCarCode = null;
      this.customInfoCarTitle = null;
      this.searchInfoCarTitle = null;
      this.infoCarTitles = [];
      this.found = false;
      this.tests = [];
      this.$emit('close');
      this.selectedImage = null;
    },
    save() {
      putRequest('/temporary-part/approve/save', '', {
        part: this.part,
        userId: this.$store.getters.userId,
        institutionId: this.$store.getters.institutionId,
      })
        .then(() => {
          this.loadingSave = false;
          this.$emit('save');
          this.close();
        })
        .catch(() => {
          this.loadingSave = false;
          this.close();
        });
    },
    approve() {
      this.loadingApprove = true;
      this.part.infoCar = {
        /* WARNING!
         * Do not send applicability because the payload gets too large.
         */
        _id: this.selectedInfoCar._id,
        oemCode: this.selectedInfoCar.oemCode,
        price: this.selectedInfoCar.price,
        description: this.selectedInfoCar.description,
        replacementCodes: this.selectedInfoCar.replacementCodes,
        kTypes: this.selectedInfoCar.kTypes,
        metadata: this.selectedInfoCar.metadata,
      };

      this.part.ebayShippingPrice = this.selectedInfoCar.description.descriptionId.ebayShippingReferencePrice; // eslint-disable-line max-len

      putRequest('/temporary-part/approve-new/', '', {
        part: this.part,
        userId: this.$store.getters.userId,
        institutionId: this.$store.getters.institutionId,
      })
        .then(() => {
          this.loadingApprove = false;
          this.$emit('approve');
          const ebayErrorObject = response.data.find(item => item.ebayError);
          if (ebayErrorObject && ebayErrorObject.ebayError && ebayErrorObject.ebayError != null) {
            this.$emit('show-error-modal', ebayErrorObject.ebayError);
          }
          this.close();
        })
        .catch(() => {
          this.loadingApprove = false;
          this.close();
        });
    },
    refuse() {
      this.loadingRefuse = true;
      putRequest('/temporary-part/approve/refuse', '', {
        part: this.part,
        userId: this.$store.getters.userId,
        institutionId: this.$store.getters.institutionId,
      })
        .then(() => {
          this.loadingRefuse = false;
          this.$emit('refuse');
          this.close();
        })
        .catch(() => {
          this.loadingRefuse = false;
          this.close();
        });
    },
    review() {
      this.loadingReview = true;
      putRequest('/temporary-part/approve/review', '', {
        part: this.part,
        userId: this.$store.getters.userId,
        institutionId: this.$store.getters.institutionId,
      })
        .then(() => {
          this.loadingReview = false;
          this.$emit('review');
          this.close();
        })
        .catch(() => {
          this.loadingReview = false;
          this.close();
        });
    },
    searchCode() { // Search code.
      this.loadingInfoCar = true;
      getRequest('/info-car/search/', `code=${this.infoCarCode}&populated=true`)
        .then((response) => {
          this.loadingInfoCar = false;
          this.infoCarResult = response;
          [this.selectedInfoCar] = response;
          this.found = true;
        })
        .catch(() => {
          this.loadingInfoCar = false;
          this.infoCarResult = [];
          this.found = false;
        });
    },
    searchByManufacturer() { // Find InfoCar code by searching Parts by Manufacturer Code.
      this.loadingManufacturer = true;
      getRequest('/part/manufacturer', `code=${this.part.manufacturer_code}`)
        .then((response) => {
          this.infoCarCode = response.code;
          this.searchCode();
        })
        .catch(() => {
          this.activateAlert('warning', this.$i18n.t('leko_employees.approve_parts.code_not_found'));
        })
        .finally(() => { this.loadingManufacturer = false; });
    },
    getTests() {
      getRequest('/test')
        .then((response) => {
          this.tests = response.tests;
        })
        .catch(() => {});
    },
    postActivePart(id) {
      /* Save part's id to activeParts collection, to indicate if an user is currently
      ** editing the part.
      */
      postRequest.object('/active-part/', { part_id: id })
        .then(() => {})
        .catch(() => {});
    },
    removeActivePart(id) {
      deleteRequest('/active-part/', `id=${id}`)
        .then(() => {})
        .catch(() => {});
    },
    viewImage(data) {
      this.$emit('open-img-dialog', data);
    },
    activateAlert(type, message) {
      this.alert.type = type;
      this.alert.message = message;
      this.alert.value = true;
      setTimeout(() => {
        this.alert.value = false;
      }, 2000);
    },
    addOemCode() {
      this.part.oemCodes.push(this.oemCode);
      this.oemCode = null;
    },
    removeOemCode(index) { this.part.oemCodes.splice(index, 1); },

    setSelectedImage(image) {
      if (this.selectedImage && this.selectedImage.image === image.image) {
      // Deselect the image if it's already selected
        this.selectedImage = null;
      } else {
      // Select the new image
        this.selectedImage = image;
      }
    },
    isSelected(image) {
      return this.selectedImage && this.selectedImage.image === image.image;
    },
    autoBotApi(part) {
      const title = part.classificationWithAI;
      this.loading = true;
      // const { selectedImage } = this;
      // if (!selectedImage) {
      //   console.error('No image selected.');
      //   this.loading = false;
      //   return;
      // }
      const partId = this.part._id;
      const requestBody = {
        title,
        // selectedImage: selectedImage
      };

      postRequest.object(`/temporary-part/applicability/${partId}`, requestBody).then((response) => {
        // Update the infoCarCode property
        this.infoCarCode = response.data.oemCodeForPart;
        if (this.infoCarCode !== undefined) {
          // eslint-disable-next-line max-len
          const codesToAdd = response.data.top3IndividualCodes && response.data.top3IndividualCodes.length > 0
            ? response.data.top3IndividualCodes
            : [response.data.oemCodeForPart];

          // Add elements to part.oemCodes
          this.part.oemCodes.push(...codesToAdd);
          this.searchCode();
          this.loading = false;
        } else {
          this.loading = false;
          this.alert = {
            value: true,
            type: 'error',
            message: 'parts.oem_not_found',
          };
          setTimeout(() => { this.alert.value = false; }, 5000);
        }
      })
        .catch((err) => {
          if (err) {
            this.loading = false;
            this.alert = {
              value: true,
              type: 'error',
              message: 'parts.oem_not_found',
            };
            setTimeout(() => { this.alert.value = false; }, 5000);
          }
        });
    },
  },
};
</script>
