<template>
  <v-data-table
    :headers="headers"
    :items="cars"
    :search="searchField"
    :loading="loading"
    hide-actions
    class="elevation-1"
  >
    <v-progress-linear
      slot="progress"
      color="red"
      indeterminate
    />
    <template
      slot="items"
      slot-scope="props"
    >
      <tr
        :class="{tableRowColorSelected : props.index == activeRow}"
        class="tableRowColor"
        @click="activeRow = props.index"
      >
        <td class="text-xs">
          {{ props.item.title }}
        </td>
        <td class="text-xs">
          {{ props.item.model }}
        </td>
        <td class="text-xs">
          {{ props.item.type }}
        </td>
        <td class="text-xs">
          {{ props.item.year }}
        </td>
        <td class="text-xs">
          {{ props.item.brand }}
        </td>
        <!-- <td class="text-xs">{{ props.item.logo }}</td> -->
        <!--<td><img :src="'../assets/'+props.item.logo+'-logo.png'" height=35 width=35></td>-->
        <td>
          <img
            :src="getLogo(props.item.logo)"
            height="35"
            width="35"
          >
        </td>

        <td
          v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'LekoEmployee' || $store.getters.role == 'SuperAdmin'"
          class="justify-center"
        >
          <v-layout row>
            <v-icon
              class="mr-2 pointer"
              @click="editCar(props.item)"
            >
              edit
            </v-icon>
            <v-icon
              class="pointer"
              @click="deleteCar(props.item._id)"
            >
              delete
            </v-icon>
          </v-layout>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script>
import getRequest from '../../api/getRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  data: () => ({
    loading: false,
    activeRow: null,
    searchField: '',
    headers: [
      {
        text: '',
        sortable: false,
        value: 'title',
      },
      {
        text: '',
        sortable: false,
        value: 'model',
      },
      {
        text: '',
        sortable: false,
        value: 'type',
      },
      {
        text: '',
        sortable: false,
        value: 'year',
      },
      {
        text: '',
        sortable: false,
        value: 'brand',
      },
      {
        text: '',
        sortable: false,
        value: 'logo',
      },
      {
        text: '',
        sortable: false,
      },
    ],
    cars: [],
  }),
  created() {
    this.getLocale();
    this.getCars();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('cars.table.title');
      this.headers[1].text = this.$i18n.t('cars.table.model');
      this.headers[2].text = this.$i18n.t('cars.table.type');
      this.headers[3].text = this.$i18n.t('cars.table.year');
      this.headers[4].text = this.$i18n.t('cars.table.brand');
      this.headers[5].text = this.$i18n.t('cars.table.logo');
      this.headers[6].text = this.$i18n.t('cars.table.actions');
    },
    getLogo(logo) {
      const img = require.context('../../assets/carLogos', false, /\.png$/);
      return img(`./${logo}-logo.png`);
    },
    search(value) {
      this.searchField = value;
    },
    getCars() {
      this.loading = true;
      getRequest('/car/').then((response) => {
        this.cars = response.cars;
        this.loading = false;
      }).catch((error) => {
        console.log(error);
        this.loading = false;
      });
    },
    editCar(car) {
      this.$emit('edit', car);
    },
    deleteCar(id) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loading = true;
        deleteRequest('/car/', `id=${id}`).then((response) => {
          this.getCars();
        });
      }
    },
  },
};
</script>
