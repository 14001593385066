<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title>
        {{ $i18n.t('vehicle_entry.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
      <v-spacer class="hidden-sm-and-down" />

      <!--SCAN QR-->
      <v-btn
        color="grey darken-4"
        dark
        @click="scanQr"
      >
        <v-icon left>
          fas fa-qrcode
        </v-icon>
        {{ $t('parts.inventory.scan_qr') }}
      </v-btn>

      <!--ADD VEHICLE-->
      <v-btn
        color="grey darken-4"
        dark
        @click="openAddVehicleDialog"
      >
        <v-icon left>
          directions_car
        </v-icon>
        {{ $i18n.t('vehicle_entry.select_vehicle') }}
      </v-btn>
    </v-toolbar>
    <v-tabs
      v-model="mode"
      fixed-tabs
      color="red darken-3"
    >
      <v-tabs-slider color="grey darken-4" />

      <v-tab :key="0">
        {{ $i18n.t('vehicle_entry.vehicles_inventory') }}
      </v-tab>
      <v-tab :key="1">
        {{ $i18n.t('vehicle_entry.vehicles_history') }}
      </v-tab>
    </v-tabs>

    <!----------------------------------------ADD VEHICLE------------------------------------------>
    <v-dialog
      v-model="addVehicleDialog"
      row
      wrap
    >
      <v-card>
        <v-card-title class="red darken-2">
          <span
            class="headline"
          >
            {{ $i18n.t('vehicle_entry.add_car') }}
          </span>

          <v-spacer />
          <v-btn
            outline
            icon
            large
            color="black"
            @click="closeAddVehicleDialog"
          >
            <v-icon x-large>
              close
            </v-icon>
          </v-btn>
        </v-card-title>

        <!--ALERT-->
        <v-alert
          :value="alert.value"
          :type="alert.type"
        >
          {{ alert.message }}
        </v-alert>

        <!--STEPPER-->
        <v-stepper v-model="stepper">
          <v-stepper-header>
            <v-stepper-step
              :complete="stepper > 1"
              step="1"
            >
              {{ $t('vehicle_entry.car_details') }}
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              :complete="stepper > 2"
              step="2"
            >
              {{ $t('vehicle_entry.car_cad') }}
            </v-stepper-step>

            <v-divider />

            <v-stepper-step step="3">
              {{ $t('vehicle_entry.car_files') }}
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <!-------------------------------------CAR DETAILS------------------------------------->
            <v-stepper-content step="1">
              <!-- SELECT VEHICLE -->
              <select-vehicle
                ref="selectVehicle"
                @selected="setSelectedVehicle"
              />
              <!-- VEHICLE DETAILS -->
              <vehicle-details
                ref="vehicleDetails"
                @car="selectVehicle"
              />
              <!--ACTIONS-->
              <v-card-actions class="mt-3">
                <v-spacer />
                <v-btn
                  color="grey darken-4"
                  dark
                  @click="closeAddVehicleDialog"
                >
                  {{ $t('vehicle_entry.cancel') }}
                </v-btn>

                <v-btn
                  color="primary"
                  :disabled="!vehicle.details"
                  @click="stepper = 2"
                >
                  {{ $t('vehicle_entry.continue') }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
            <!---------------------------------------CAR CAD--------------------------------------->
            <v-stepper-content step="2">
              <!-- VEHICLE VIEW -->
              <vehicle-view
                ref="vehicleView"
                @position="openPartSelectionDialog"
              />
              <!--ACTIONS-->
              <v-card-actions class="mt-3">
                <v-spacer />
                <v-btn
                  color="grey darken-4"
                  dark
                  @click="stepper = 1"
                >
                  {{ $t('vehicle_entry.back') }}
                </v-btn>

                <v-btn
                  color="primary"
                  @click="stepper = 3"
                >
                  {{ $t('vehicle_entry.continue') }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
            <!--------------------------------------CAR FILES-------------------------------------->
            <v-stepper-content step="3">
              <!--PHOTOS-->
              <v-divider />
              <v-layout align-center>
                <div>{{ $i18n.t('vehicle_entry.photos') }}</div>

                <v-spacer />
                <v-btn
                  color="primary"
                  depressed
                  flat
                  small
                  @click="openPhotosDialog"
                >
                  {{ $i18n.t('vehicle_entry.add_photos') }}
                  <v-icon right>
                    add
                  </v-icon>
                </v-btn>
              </v-layout>
              <v-layout
                row
                wrap
              >
                <v-flex
                  v-for="(data, index) in vehicle.photos"
                  :key="index"
                  xs6
                  sm4
                  md3
                  lg2
                  xl1
                  d-flex
                  class="pa-1"
                >
                  <v-img :src="data.dataUrl" />
                </v-flex>
              </v-layout>

              <!--VIDEOS-->
              <v-divider />
              <v-layout align-center>
                <div>{{ $i18n.t('vehicle_entry.videos') }}</div>

                <v-spacer />
                <v-btn
                  color="primary"
                  depressed
                  flat
                  small
                  @click="openVideosDialog"
                >
                  {{ $i18n.t('vehicle_entry.add_video') }}
                  <v-icon right>
                    add
                  </v-icon>
                </v-btn>
              </v-layout>
              <v-layout
                row
                wrap
              >
                <v-flex v-if="vehicle.video">
                  <video
                    :src="vehicle.video.dataUrl"
                    :width="videoWidth"
                    controls
                  />
                </v-flex>
              </v-layout>

              <!--DOCUMENTS-->
              <v-divider />
              <v-layout align-center>
                <div> {{ $i18n.t('vehicle_entry.upload_document') }} </div>
                <v-spacer />
                <v-btn
                  color="primary"
                  depressed
                  flat
                  small
                  @click="openPdfInputComponent"
                >
                  {{ $i18n.t('vehicle_entry.upload_document') }}
                  <v-icon right>
                    add
                  </v-icon>
                </v-btn>
              </v-layout>

              <v-btn
                v-if="vehicle.pdf"
                fab
                flat
                large
                @click="viewPdf"
              >
                <v-icon
                  large
                  color="grey darken-4"
                >
                  description
                </v-icon>
              </v-btn>

              <input
                ref="pdfInput"
                type="file"
                style="display: none"
                accept="application/pdf"
                @change="onPdfInput"
              >

              <!--ACTIONS-->
              <v-card-actions class="mt-3">
                <v-btn
                  color="primary"
                  :disabled="!vehicle.details"
                  @click="openInventoryList"
                >
                  {{ $i18n.t('vehicle_entry.inventory_list.label') }}
                </v-btn>

                <v-btn
                  color="primary"
                  depressed
                  @click="openPartsList({ component: 'main' })"
                >
                  {{ $i18n.t('vehicle_entry.parts_list.label') }}
                  <v-icon right>
                    fas fa-file-pdf
                  </v-icon>
                </v-btn>

                <v-spacer />

                <v-btn
                  color="grey darken-4"
                  dark
                  @click="stepper = 2"
                >
                  {{ $t('vehicle_entry.back') }}
                </v-btn>

                <v-btn
                  color="success"
                  :loading="loadingSave"
                  :disabled="!vehicle.details"
                  @click="save"
                >
                  {{ $i18n.t('vehicle_entry.save') }}
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <!---- HIDDEN COMPONENTS ---->
    <parts-selection
      ref="partsSelection"
      @save="saveParts"
      @close="showAddVehicleDialog"
    />
    <inventory-list
      ref="inventoryList"
      @close="showAddVehicleDialog"
    />
    <photos
      ref="photos"
      @open-img-dialog="openImgDialog"
      @save="setPhotos"
      @close="showAddVehicleDialog"
    />
    <videos
      ref="videos"
      @save="setVideo"
      @close="showAddVehicleDialog"
    />
    <img-dialog
      ref="imgDialog"
      @close="showPhotos"
    />
    <!------------------------------------ VEHICLES INVENTORY ------------------------------------->
    <vehicle-filters
      v-if="mode === 0"
      ref="vehicleFilters"
      @search="data => setFilters('inventory', data)"
      @infocar-cad="openInfoCarCad"
    />

    <vehicles-inventory
      v-if="mode === 0"
      ref="vehiclesInventory"
      @images="openImgCarousel"
      @video="openVideoDialog"
      @qr="openQrDialog"
      @share="openShareDialog"
      @view-details="openDetailsDialog"
      @generate-qr="openQrGenerationDialog"
      @edit-vehicle="openEditVehicleDialog"
      @removed="getVehicles('all')"
      @infocar-cad="openInfoCarCad"
      @transfer="openTransferDialog"
    />
    <!------------------------------------- VEHICLES HISTORY -------------------------------------->
    <vehicle-filters
      v-if="mode === 1"
      ref="vehicleFilters"
      @search="data => setFilters('history', data)"
      @infocar-cad="openInfoCarCad"
    />

    <vehicles-history
      v-if="mode === 1"
      ref="vehiclesHistory"
      @images="openImgCarousel"
      @video="openVideoDialog"
      @qr="openQrDialog"
      @share="openShareDialog"
      @view-details="openDetailsDialog"
      @generate-qr="openQrGenerationDialog"
      @returned="getVehicles('all')"
      @infocar-cad="openInfoCarCad"
    />

    <!--------------------------------------------------------------------------------------------->
    <!-- HIDDEN COMPONENTS -->
    <details-dialog
      ref="detailsDialog"
      @parts-list="openPartsList"
      @goToInventory="goToInventory"
    />
    <generate-qr-codes
      ref="generateQrCodes"
      @view-qr-codes="openPartsQrDialog"
    />
    <edit-vehicle
      ref="editVehicle"
      @close="getVehicles('inventory')"
      @parts-list="openPartsList"
    />

    <qr-dialog ref="qrDialog" />

    <parts-list
      ref="partsList"
      @close="closePartsList"
      @save="savePartsList"
    />

    <parts-qr-dialog
      ref="partsQrDialog"
      @close="showQrGenerationDialog"
    />

    <img-carousel ref="imgCarousel" />

    <video-dialog ref="videoDialog" />

    <qr-scanner
      ref="qrScanner"
      @details="openDetailsDialog"
      @generate-qr="openQrGenerationDialog"
      @edit="openEditVehicleDialog"
      @changed-location="getVehicles('all')"
    />

    <share-dialog ref="shareDialog" />
    <infocar-cad ref="infoCarCad" />
    <transfer-dialog
      ref="transferDialog"
      @close="getVehicles('all')"
    />
  </v-container>
</template>

<script>
import SelectVehicle from '@/components/VehicleEntry/selectVehicle.vue';
import VehicleDetails from '@/components/VehicleEntry/vehicleDetails.vue';
import VehicleView from '@/components/VehicleEntry/vehicleView.vue';

import PartsSelection from '@/components/VehicleEntry/partsSelection.vue';
import InventoryList from '@/components/VehicleEntry/inventoryList.vue';
import PartsList from '@/components/VehicleEntry/partsList.vue';
import Photos from '@/components/VehicleEntry/photos.vue';
import Videos from '@/components/VehicleEntry/videos.vue';
import ImgDialog from '@/components/imgDialog.vue';

import VehiclesInventory from '@/components/VehicleEntry/vehiclesInventory.vue';
import VehiclesHistory from '@/components/VehicleEntry/vehiclesHistory.vue';

import VehicleFilters from '@/components/VehicleEntry/vehicleFilters.vue';

import DetailsDialog from '@/components/VehicleEntry/detailsDialog.vue';
import GenerateQrCodes from '@/components/VehicleEntry/generateQrCodes.vue';
import EditVehicle from '@/components/VehicleEntry/editVehicle.vue';

import PartsQrDialog from '@/components/VehicleEntry/partsQrDialog.vue';
import QrDialog from '@/components/qrDialogExtended.vue';
import ImgCarousel from '@/components/imgCarousel.vue';
import VideoDialog from '@/components/VehicleEntry/videoDialog.vue';
import QrScanner from '@/components/VehicleEntry/qrScanner.vue';
import ShareDialog from '@/components/VehicleEntry/shareDialog.vue';
import InfoCarCad from '@/components/infoCarCad.vue';
import TransferDialog from '@/components/VehicleEntry/transferDialog.vue';

import postRequest from '../api/postRequest';
import getRequest from '../api/getRequest';

function dataURLtoBlob(dataUrl) {
  // convert base64 to raw binary data held in a string
  const byteString = atob(dataUrl.split(',')[1]);
  // separate out the mime component
  const mimeString = dataUrl
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];
  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length);
  // create a view into the buffer
  const ia = new Uint8Array(ab);
  // set the bytes of the buffer to the correct values
  for (let i = 0; i < byteString.length; i += 1) {
    ia[i] = byteString.charCodeAt(i);
  }
  // write the ArrayBuffer to a blob, and you're done
  return new Blob([ab], { type: mimeString });
}

export default {
  components: {
    'select-vehicle': SelectVehicle,
    'vehicle-details': VehicleDetails,
    'vehicle-view': VehicleView,
    'vehicles-inventory': VehiclesInventory,
    'parts-list': PartsList,
    'vehicles-history': VehiclesHistory,
    'vehicle-filters': VehicleFilters,
    'parts-selection': PartsSelection,
    'inventory-list': InventoryList,
    'details-dialog': DetailsDialog,
    'generate-qr-codes': GenerateQrCodes,
    'edit-vehicle': EditVehicle,
    photos: Photos,
    videos: Videos,
    'img-dialog': ImgDialog,
    'video-dialog': VideoDialog,
    'parts-qr-dialog': PartsQrDialog,
    'qr-dialog': QrDialog,
    'img-carousel': ImgCarousel,
    'qr-scanner': QrScanner,
    'share-dialog': ShareDialog,
    'infocar-cad': InfoCarCad,
    'transfer-dialog': TransferDialog,
  },
  data: () => ({
    alert: { value: false, type: 'error', message: '' },
    mode: 0,
    stepper: 1,
    addVehicleDialog: false,
    loadingSave: false,
    vehicle: {
      details: null,
      info: null,
      parts: null,
      partsList: null,
      pdf: null,
      photos: [],
    },
    __hiddenComponent: null,
  }),
  computed: {
    videoWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 240;
        case 'sm':
          return 320;
        case 'md':
          return 480;
        default:
          return 480;
      }
    },
  },
  mounted() { // Force set filters.
    const { brand, model } = this.$route.query;
    if (brand && model) {
      const { vehicleFilters } = this.$refs;
      vehicleFilters.forceSetFilters(brand, model);
    }
  },
  methods: {
    selectVehicle(data) {
      const { selectVehicle } = this.$refs;
      selectVehicle.setVehicle(data);
    },
    setSelectedVehicle(details) {
      this.vehicle.details = details;
    },
    openAddVehicleDialog() {
      this.addVehicleDialog = true;
    },
    closeAddVehicleDialog() {
      this.addVehicleDialog = false;
      const { vehicleDetails, selectVehicle } = this.$refs;
      vehicleDetails.clear();
      selectVehicle.clear();
      this.stepper = 1;
      this.vehicle = {
        details: null,
        info: null,
        parts: null,
        photos: [],
      };
      this.targa = null;
      this.foundTarga = null;
      this.loadingSave = false;
    },
    hideAddVehicleDialog() {
      this.addVehicleDialog = false;
    },
    showAddVehicleDialog() {
      this.addVehicleDialog = true;
    },
    openPartSelectionDialog(position) {
      const { partsSelection } = this.$refs;
      partsSelection.open(position, this.vehicle);
      this.hideAddVehicleDialog();
    },
    openInventoryList() {
      const { inventoryList } = this.$refs;
      inventoryList.open(this.vehicle.details);
      this.hideAddVehicleDialog();
    },
    openPartsList(data) {
      const { partsList } = this.$refs;
      if (data.component === 'main') {
        partsList.open(data.vehicle, this.vehicle.partsList);
        this.hideAddVehicleDialog();
      } else {
        partsList.open(data.vehicle, data.partsList, !!(data.component === 'details'));
        const { editVehicle, detailsDialog } = this.$refs;
        if (data.component === 'edit') editVehicle.showDialog();
        if (data.component === 'details') detailsDialog.showDialog();
      }
      this.__hiddenComponent = data.component;
    },
    closePartsList() {
      const { editVehicle, detailsDialog } = this.$refs;
      if (this.__hiddenComponent === 'main') this.showAddVehicleDialog();
      if (this.__hiddenComponent === 'edit') editVehicle.showDialog();
      if (this.__hiddenComponent === 'details') detailsDialog.showDialog();
      this.__hiddenComponent = null;
    },
    savePartsList(partsList) {
      const { editVehicle } = this.$refs;
      if (!this.__hiddenComponent) this.vehicle.partsList = partsList;
      if (this.__hiddenComponent === 'edit') editVehicle.vehicle.partsList = partsList;
    },
    saveParts(data) {
      if (!this.vehicle.parts) this.vehicle.parts = {};
      this.vehicle.parts[data.position] = data.items;
      this.vehicle.parts.comment = data.comment;
    },
    openPhotosDialog() {
      const { photos } = this.$refs;
      photos.open(this.vehicle.photos);
      this.hideAddVehicleDialog();
    },
    openVideosDialog() {
      const { videos } = this.$refs;
      videos.open(this.vehicle.video);
      this.hideAddVehicleDialog();
    },
    showPhotos() {
      const { photos } = this.$refs;
      photos.show();
    },
    openImgDialog(data) {
      const { photos, imgDialog } = this.$refs;
      imgDialog.open(data);
      photos.hide();
    },
    openImgCarousel(data) {
      const { imgCarousel } = this.$refs;
      imgCarousel.open({ type: 'string', images: data });
    },
    openVideoDialog(data) {
      const { videoDialog } = this.$refs;
      videoDialog.open({ type: 'string', data });
    },
    openQrDialog(data) {
      const { qrDialog } = this.$refs;
      qrDialog.open(data);
    },
    openDetailsDialog(vehicle) {
      const { detailsDialog } = this.$refs;
      detailsDialog.open(vehicle);
    },
    openQrGenerationDialog(data) {
      const { generateQrCodes } = this.$refs;
      generateQrCodes.open(data);
    },
    showQrGenerationDialog() {
      const { generateQrCodes } = this.$refs;
      generateQrCodes.show();
    },
    openPartsQrDialog(parts) {
      const { partsQrDialog, generateQrCodes } = this.$refs;
      generateQrCodes.hide();
      partsQrDialog.open(parts);
    },
    openEditVehicleDialog(vehicle) {
      const { editVehicle } = this.$refs;
      editVehicle.open(vehicle);
    },
    openTransferDialog(data) {
      const { transferDialog } = this.$refs;
      transferDialog.open(data);
    },
    openShareDialog(data) {
      const { shareDialog } = this.$refs;
      shareDialog.open(data);
    },
    async save() {
      try {
        this.loadingSave = true;
        const { vehicleDetails, selectVehicle } = this.$refs;
        this.vehicle.info = vehicleDetails.getDetails();

        // Assign all the part groups by default.
        const partGroups = await getRequest('/part-groups/');
        if (!this.vehicle.parts) this.vehicle.parts = {};
        partGroups.forEach((group) => {
          if (!Object.keys(this.vehicle.parts).includes(group.position)) {
            this.vehicle.parts[group.position] = group.parts
              .map((part) => { const _part = part; _part.selected = true; return _part; });
          }
        });

        const formData = new FormData();
        formData.append('data', JSON.stringify({
          institution_id: this.$store.getters.institutionId,
          brand: this.vehicle.details.brand,
          model: this.vehicle.details.model,
          version: this.vehicle.details.version,
          targa: this.vehicle.info.targa,
          vin: this.vehicle.info.vin,
          transmissionType: this.vehicle.info.transmissionType,
          transmissionLayout: this.vehicle.info.transmissionLayout,
          externalColor: this.vehicle.info.externalColor,
          internalColor: this.vehicle.info.internalColor,
          internalState: this.vehicle.info.internalState,
          vehicleCondition: this.vehicle.info.vehicleCondition,
          // frame: this.vehicle.info.frame,
          pra: this.vehicle.info.pra,
          km: this.vehicle.info.km,
          location: this.vehicle.info.location,
          notes: this.vehicle.info.notes,
          registrationYear: this.vehicle.info.registrationYear,
          withdrawnPIVA: this.vehicle.info.withdrawnPIVA,
          workingEngine: this.vehicle.info.workingEngine,
          parts: this.vehicle.parts,
          partsList: this.vehicle.partsList,
          photos: this.vehicle.photos.map(p => `${this.$store.getters.institutionId}-${this.vehicle.details.brand.brandCode}${this.vehicle.details.model.modelCode}${this.vehicle.details.version.versionCode}-${p.title}`),
          video: this.vehicle.video ? `${this.$store.getters.institutionId}-${this.vehicle.details.brand.brandCode}${this.vehicle.details.model.modelCode}${this.vehicle.details.version.versionCode}-${this.vehicle.video.title}` : null,
          document: this.vehicle.pdf ? `${this.$store.getters.institutionId}-${this.vehicle.details.brand.brandCode}${this.vehicle.details.model.modelCode}${this.vehicle.details.version.versionCode}-${this.vehicle.pdf.title}` : null,
        }));
        this.vehicle.photos.forEach(p => formData.append('car-photos', p.blob, `${this.$store.getters.institutionId}-${this.vehicle.details.brand.brandCode}${this.vehicle.details.model.modelCode}${this.vehicle.details.version.versionCode}-${p.title}`));
        if (this.vehicle.pdf && this.vehicle.pdf.blob) { formData.append('car-documents', this.vehicle.pdf.blob, `${this.$store.getters.institutionId}-${this.vehicle.details.brand.brandCode}${this.vehicle.details.model.modelCode}${this.vehicle.details.version.versionCode}-${this.vehicle.pdf.title}`); }
        if (this.vehicle.video && this.vehicle.video.blob) { formData.append('car-videos', this.vehicle.video.blob, `${this.$store.getters.institutionId}-${this.vehicle.details.brand.brandCode}${this.vehicle.details.model.modelCode}${this.vehicle.details.version.versionCode}-${this.vehicle.video.title}`); }

        vehicleDetails.clear();
        selectVehicle.clear();

        const response = await postRequest.formData('/car', '', formData);
        this.getVehicles('inventory');
        this.closeAddVehicleDialog();
      } catch (error) {
        this.getVehicles('inventory');
        this.alert.value = true;
        this.alert.message = 'The car already exists';
        setTimeout(() => {
          this.alert.value = false;
          this.closeAddVehicleDialog();
        }, 3000);
      }
    },
    getVehicles(type) {
      const { vehiclesInventory, vehiclesHistory } = this.$refs;
      if ((type === 'all' || type === 'inventory') && this.mode === 0) vehiclesInventory.getCars();
      if ((type === 'all' || type === 'history') && this.mode === 1) vehiclesHistory.getCars();
    },
    setFilters(type, data) {
      const { vehiclesInventory, vehiclesHistory } = this.$refs;
      if ((type === 'all' || type === 'inventory') && this.mode === 0) vehiclesInventory.setFilters(data);
      if ((type === 'all' || type === 'history') && this.mode === 1) vehiclesHistory.setFilters(data);
    },
    setCars(cars) { this.cars = cars; },
    setPhotos(data) { this.vehicle.photos = data.photos; },
    setVideo(data) { this.vehicle.video = data.video; },
    openPdfInputComponent() { this.$refs.pdfInput.click(); },
    onPdfInput(input) {
      const { files } = input.target;
      const fileReader = new FileReader();
      for (const file of files) { // eslint-disable-line no-restricted-syntax
        if (file) { fileReader.readAsDataURL(file); }
      }

      const random = Math.random()
        .toString(36)
        .substring(9);

      fileReader.addEventListener('load', () => {
        const dataUrl = fileReader.result;
        const blob = dataURLtoBlob(dataUrl);
        this.vehicle.pdf = { dataUrl, blob, title: `${random}${new Date().getTime().toString(16)}.pdf` };
      });
    },
    viewPdf() {
      const objectUrl = window.URL.createObjectURL(this.vehicle.pdf.blob);
      window.open(objectUrl);
      window.URL.revokeObjectURL(objectUrl);
    },
    scanQr() {
      const { qrScanner } = this.$refs;
      qrScanner.open();
    },
    openInfoCarCad(data) {
      const { infoCarCad } = this.$refs;
      infoCarCad.open(data);
    },
    goToInventory(data) {
      this.$router.push(`/inventory?titleCode=${data.code}&brand=${data.brand}&model=${data.model}&version=${data.version}`);
    },
  },
};
</script>
