<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="800px"
  >
    <v-card>
      <v-card-title class="redColor">
        <span
          v-if="edit === true"
          class="headline"
        >{{ $t("clients.form.edit_client") }}</span>
        <span
          v-if="edit === false"
          class="headline"
        >{{ $t("clients.form.create_client") }}</span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="client.name"
                  :rules="rules.requiredLetters"
                  :label="$t('clients.form.name')"
                  color="red darken-1"
                  prepend-icon="person"
                />
              </v-flex>
              <v-flex
                xs12
                sm6
                md6
              >
                <v-text-field
                  v-model="client.surname"
                  :rules="rules.requiredLetters"
                  :label="$t('clients.form.surname')"
                  color="red darken-1"
                  prepend-icon="person"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="client.email"
                  :rules="rules.email"
                  :label="$t('clients.form.email')"
                  color="red darken-1"
                  prepend-icon="email"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="client.phone"
                  :rules="rules.requiredNumbers"
                  :label="$t('clients.form.phone')"
                  color="red darken-1"
                  prepend-icon="call"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="client.address"
                  :rules="rules.required"
                  :label="$t('clients.form.address')"
                  color="red darken-1"
                  prepend-icon="list_alt"
                />
              </v-flex>
              <v-flex
                v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'LekoEmployee' || $store.getters.role == 'SuperAdmin'"
                xs12
              >
                <v-select
                  v-model="client.institution"
                  :items="institutions"
                  :label="$t('clients.form.select_institution')"
                  color="red darken-1"
                  prepend-icon="domain"
                  item-text="title"
                  item-value="_id"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="client.discount"
                  color="red darken-1"
                  prepend-icon="remove_circle_outline"
                  type="number"
                  min="0"
                  max="100"
                  oninput="validity.valid||(value='')"
                  label="Discount Percentage"
                />
              </v-flex>
              <v-flex xs12>
                <v-textarea
                  v-model="client.comment"
                  :label="$t('clients.form.comment')"
                  color="red darken-1"
                  prepend-icon="notes"
                />
              </v-flex>
              <v-flex xs12>
                <!--
                <span v-if="client.cars && client.cars.length > 0">
                  {{ $t('clients.cars.vehicles') }}
                </span>
                -->
                <v-container
                  v-for="(item, index) in client.cars"
                  :key="index"
                >
                  <v-layout
                    row
                    wrap
                    align-center
                    justify-center
                  >
                    <v-flex xs10>
                      <v-layout
                        row
                        wrap
                      >
                        <v-flex xs6>
                          <v-layout
                            column
                            wrap
                          >
                            <span class="caption">{{ $t('clients.cars.brand') }}</span>
                            <span class="body-2">
                              {{ item.brand.title }}
                            </span>
                          </v-layout>
                        </v-flex>
                        <v-flex xs6>
                          <v-layout
                            column
                            wrap
                          >
                            <span class="caption">{{ $t('clients.cars.model') }}</span>
                            <span class="body-2">
                              {{ item.model.fullDescription }}
                            </span>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12>
                          <v-layout
                            column
                            wrap
                          >
                            <span class="caption">{{ $t('clients.cars.version') }}</span>
                            <span class="body-2">
                              {{ item.version.fullDescription }}
                            </span>
                          </v-layout>
                        </v-flex>
                        <v-flex>
                          <v-layout
                            column
                            wrap
                          >
                            <span class="caption">{{ $t('clients.cars.vin') }}</span>
                            <span class="body-1">
                              {{ item.vin }}
                            </span>
                          </v-layout>
                        </v-flex>
                        <v-flex>
                          <v-layout
                            column
                            wrap
                          >
                            <span class="caption">{{ $t('clients.cars.notes') }}</span>
                            <span class="body-1">
                              {{ item.notes }}
                            </span>
                          </v-layout>
                        </v-flex>
                        <v-flex
                          v-for="(_item, _index) in item.specifications"
                          :key="_index"
                        >
                          <v-layout
                            column
                            wrap
                          >
                            <span class="caption">{{ _item.key }}</span>
                            <span class="body-1">
                              {{ _item.value }}
                            </span>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex
                      xs2
                      pl-4
                    >
                      <v-btn
                        icon
                        @click="removeVehicle(index)"
                      >
                        <v-icon>remove_circle_outline</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-flex>
              <v-flex xs12>
                <v-layout
                  align-center
                  justify-center
                >
                  <v-btn
                    color="primary"
                    @click="openVehicleDialog"
                  >
                    {{ $t('clients.cars.add_vehicle') }}
                  </v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('clients.form.close') }}
        </v-btn>
        <v-btn
          :disabled="!valid"
          :loading="loading"
          color="blue darken-1"
          flat
          @click.native="save"
        >
          {{ $t('clients.form.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';
import postRequest from '../../api/postRequest';
import putRequest from '../../api/putRequest';

export default {
  data: () => ({
    edit: null,
    valid: false,
    dialog: false,
    loading: false,
    rules: {
      required: [v => !!v || 'Please fill this field'],
      requiredLetters: [
        v => !!v || 'Please fill this field',
        v => /[A-Za-z]/.test(v) || 'Only letters are allowed',
      ],
      requiredNumbers: [
        v => !!v || 'Please fill this field',
        v => /^[0-9]*$/.test(v) || 'Only numbers are allowed',
      ],
      email: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid',
      ],
    },
    client: { cars: [] },
    institutions: [],
    shopClient: null,
  }),
  computed: {},
  methods: {
    getInstitutions() {
      getRequest('/institution/').then((response) => {
        this.institutions = response.institutions;
      });
    },
    open(edit, client, institutitonId, shopClient) {
      this.valid = false;

      this.edit = edit;
      if (this.$store.getters.role === 'LekoAdmin' || this.$store.getters.role === 'LekoEmployee' || this.$store.getters.role === 'SuperAdmin') {
        this.getInstitutions();
      }
      if (this.edit) {
        this.client = client;
        this.client.user = client._id; // shopUser id.
      } else {
        if (client) {
          this.client = client;
          this.client.user = client._id; // shopUser id.
        }
        this.client.institution = institutitonId;
      }
      this.shopClient = shopClient;
      this.dialog = true;
    },
    close() {
      this.edit = null;
      this.loading = false;
      this.dialog = false;
      this.client = { cars: [] };
      this.institutions = [];
      this.$emit('closed');
    },
    save() {
      this.loading = true;
      if (this.edit) {
        putRequest('/client/', `id=${this.client._id}&shop=${this.shopClient}`, this.client)
          .then((response) => {
            console.log(response);
            this.$emit('saved');
            this.close();
          })
          .catch((error) => {
            console.log(error.response);
            this.close();
          });
      } else {
        postRequest
          .object(`/client/?shop=${this.shopClient}`, this.client)
          .then((response) => {
            console.log(response);
            this.$emit('saved');
            this.close();
          })
          .catch((error) => {
            console.log(error.response);
            this.close();
          });
      }
    },
    hide() { this.dialog = false; },
    show() { this.dialog = true; },
    openVehicleDialog() {
      this.$emit('add-vehicle', { client: this.client });
      this.hide();
    },
    addVehicle(data) { this.client.cars.push(data); },
    removeVehicle(index) { this.client.cars.splice(index, 1); },
  },
};
</script>
