<template>
  <v-dialog
    v-model="dialog"
    max-width="600px"
  >
    <v-card>
      <v-card-title>{{ this.$i18n.t('parts.inventory.cars_alert') }}</v-card-title>
      <v-card-text>
        <v-layout
          align-center
          justify-center
        >
          <v-btn
            color="red"
            @click="$router.push({
              path: `/vehicle-entry?brand=${items.brand}&model=${items.model}`
            })"
          >
            {{ this.$i18n.t('parts.inventory.go_to_cars') }}
          </v-btn>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  components: {},
  data: () => ({
    dialog: false,
    items: null,
  }),
  watch: {
    dialog(value) { if (!value) this.close(); },
  },
  methods: {
    open(data) {
      this.items = data;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.items = null;
      this.$emit('close');
    },
  },
};
</script>
