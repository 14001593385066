<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="650px"
  >
    <v-card>
      <v-card-title class="red darken-2">
        <v-layout
          align-center
          row
        >
          <span class="headline">
            {{ $t("leko_employees.approve_parts.automatic_approval.label") }}
          </span>
          <v-spacer />
          <v-btn
            outline
            icon
            large
            color="black"
            @click="close"
          >
            <v-icon x-large>
              close
            </v-icon>
          </v-btn>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <v-layout
          column
          wrap
          align-center
          justify-center
        >
          <v-flex>
            <v-alert
              :value="true"
              type="info"
            >
              {{ $t("leko_employees.approve_parts.automatic_approval.alert") }}
            </v-alert>
          </v-flex>

          <v-flex>
            <v-select
              v-model="institution"
              :items="institutions"
              item-text="title"
              item_value="_id"
              :label="$t('leko_employees.approve_parts.automatic_approval.institution')"
              color="red darken-1"
              return-object
            />
          </v-flex>
          <v-flex>
            <div v-if="logs.length > 0">
              Logs
            </div>
            <v-list
              two
              line
            >
              <template v-for="(log, index) in logs">
                <v-list-tile :key="index">
                  <v-list-tile-content> {{ log }} </v-list-tile-content>
                  <v-btn
                    icon
                    @click="downloadLog(log)"
                  >
                    <v-icon color="grey darken-2">
                      download
                    </v-icon>
                  </v-btn>
                  <v-btn
                    icon
                    @click="deleteLog(log)"
                  >
                    <v-icon color="grey darken-2">
                      close
                    </v-icon>
                  </v-btn>
                </v-list-tile>
                <v-divider
                  v-if="log"
                  :key="`d${index}`"
                />
              </template>
            </v-list>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          flat
          color="success"
          :loading="loading"
          :disabled="!institution"
          @click="approve"
        >
          {{ $t('leko_employees.approve_parts.automatic_approval.approve') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import getRequest from '../../api/getRequest';
import putRequest from '../../api/putRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  data: () => ({
    dialog: false,
    loading: false,
    institution: null,
    logs: [],
    institutions: [],
  }),
  computed: {
  },
  watch: {
  },
  methods: {
    open() {
      this.dialog = true;
      this.getInstitutions();
      this.getLogs();
    },
    close() {
      this.dialog = false;
      this.institutions = [];
      this.institution = null;
      this.$emit('close');
    },
    approve() {
      this.loading = true;
      putRequest('/temporary-part/automatic-approval', `user_id=${this.$store.getters.userId}&institution_id=${this.institution._id}`, {})
        .then((response) => {
          // console.log(response);
        })
        .catch((error) => { console.log(error); })
        .finally(() => {
          this.loading = false;
          this.close();
        });
    },
    getInstitutions() {
      getRequest('/institution')
        .then((response) => { this.institutions = response.institutions; })
        .catch((error) => { console.log(error); });
    },
    getLogs() {
      getRequest('/temporary-part/approve/logs')
        .then((response) => { this.logs = response.files; })
        .catch((error) => { console.log(error); });
    },
    downloadLog(log) {
      getRequest('/temporary-part/approve/logs', `log=${log}`)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', log);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => { console.log(error); });
    },
    deleteLog(log) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        deleteRequest('/temporary-part/approve/logs', `log=${log}`)
          .then((response) => {
            //  console.log(response);
          })
          .catch((error) => { console.log(error); })
          .finally(() => { this.getLogs(); });
      }
    },
  },
};
</script>
