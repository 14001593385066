import Router from 'vue-router';
import axios from 'axios';

import store from './store';
import Auth from './pages/Auth.vue';

import ApproveUsers from './pages/ApproveUsers.vue';
import TestIts from './pages/TestIts.vue';
import Tools from './pages/Tools.vue';
import CarPartGroups from './pages/CarPartGroups.vue';
import Quality from './pages/Quality.vue';
import Users from './pages/Users.vue';
import Institutions from './pages/Institutions.vue';
import Cars from './pages/Cars.vue';
import Cities from './pages/Cities.vue';
import Clients from './pages/Clients.vue';
import States from './pages/States.vue';
import Repositories from './pages/Repositories.vue';
import Shipments from './pages/Shipments.vue';
import OrderedParts from './pages/OrderedParts.vue';
import Tests from './pages/Tests.vue';
import Warranties from './pages/Warranties.vue';
import Home from './pages/Home.vue';
import Settings from './pages/Settings.vue';
import ApproveParts from './pages/ApproveParts.vue';
import InsertParts from './pages/InsertParts.vue';
import Inventory from './pages/Inventory.vue';
import DamagedParts from './pages/DamagedParts.vue';
import LostParts from './pages/LostParts.vue';
import RecycledParts from './pages/RecycledParts.vue';
import Sales from './pages/Sales.vue';
import Shippings from './pages/Shippings.vue';
import Movements from './pages/Movements.vue';
import Reservations from './pages/Reservations.vue';
import PartRequests from './pages/PartRequests.vue';
import VehicleEntry from './pages/VehicleEntry.vue';
import CarLocations from './pages/CarLocations.vue';
import CarVersions from './pages/CarVersions.vue';
import Introduction from './pages/Introduction.vue';
import Documentation from './pages/Documentation.vue';
import LekoTechAi from './pages/LekoTechAI.vue';
import CommonCodes from './pages/CommonCodes.vue';
import EbayShippingPrice from './pages/EbayShippingPrice.vue';

import institutionDetails from '@/components/Institutions/institutionDetails.vue';

import config from './config';

const baseRoutes = [{
  path: '/auth',
  name: 'auth',
  component: Auth,
  meta: {
    authRoute: true,
  },
},
{
  path: '*',
  redirect: {
    name: 'inventory',
  },
},
{
  path: '/home',
  name: 'home',
  component: Home,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/settings',
  name: 'settings',
  component: Settings,
  meta: {
    requiresAuth: true,
    adminRole: true,
  },
},
{
  path: '/approve-users',
  name: 'approve-users',
  component: ApproveUsers,
  meta: {
    requiresAuth: true,
    employeeRole: true,
  },
},
{
  path: '/car-part-groups',
  name: 'car-part-groups',
  component: CarPartGroups,
  meta: {
    requiresAuth: true,
    employeeRole: true,
  },
},
{
  path: '/shippings',
  name: 'shippings',
  component: Shippings,
  meta: {
    requiresAuth: true,
    employeeRole: true,
  },
},
{
  path: '/test-its',
  name: 'test-its',
  component: TestIts,
  meta: {
    requiresAuth: true,
    employeeRole: true,
  },
},
{
  path: '/tools',
  name: 'tools',
  component: Tools,
  meta: {
    requiresAuth: true,
    employeeRole: true,
  },
},
{
  path: '/part-requests',
  name: 'part-requests',
  component: PartRequests,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/quality',
  name: 'quality',
  component: Quality,
  meta: {
    requiresAuth: true,
    employeeRole: true,
  },
},
{
  path: '/sales',
  name: 'sales',
  component: Sales,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/reservations',
  name: 'reservations',
  component: Reservations,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/users',
  name: 'users',
  component: Users,
  meta: {
    requiresAuth: true,
    adminRole: true,
  },
},
{
  path: '/clients',
  name: 'clients',
  component: Clients,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/institutions',
  name: 'institutions',
  component: Institutions,
  meta: {
    requiresAuth: true,
    employeeRole: true,
  },
},
{
  path: '/cars',
  name: 'cars',
  component: Cars,
  meta: {
    requiresAuth: true,
    employeeRole: true,
  },
},
{
  path: '/approve-parts',
  name: 'approve-parts',
  component: ApproveParts,
  meta: {
    requiresAuth: true,
    employeeRole: true,
  },
},
{
  path: '/inventory',
  name: 'inventory',
  component: Inventory,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/damaged',
  name: 'damaged',
  component: DamagedParts,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/lost',
  name: 'lost',
  component: LostParts,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/recycled',
  name: 'recycled',
  component: RecycledParts,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/insert-parts',
  name: 'insert-parts',
  component: InsertParts,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/movements',
  name: 'movements',
  component: Movements,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/ordered',
  name: 'ordered',
  component: OrderedParts,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/shipments',
  name: 'shipments',
  component: Shipments,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/repositories',
  name: 'repositories',
  component: Repositories,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/cities',
  name: 'cities',
  component: Cities,
  meta: {
    requiresAuth: true,
    employeeRole: true,
  },
},
{
  path: '/states',
  name: 'states',
  component: States,
  meta: {
    requiresAuth: true,
    employeeRole: true,
  },
},
{
  path: '/warranties',
  name: 'warranties',
  component: Warranties,
  meta: {
    requiresAuth: true,
    adminRole: true,
  },
},
{
  path: '/tests',
  name: 'tests',
  component: Tests,
  meta: {
    requiresAuth: true,
    employeeRole: true,
  },
},
{
  path: '/vehicle-entry',
  name: 'vehicle-entry',
  component: VehicleEntry,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/car-locations',
  name: 'car-locations',
  component: CarLocations,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/car-versions',
  name: 'car-versions',
  component: CarVersions,
  meta: {
    requiresAuth: true,
  },
},
// {
//   path: '/documentation',
//   name: 'documentation',
//   component: Documentation,
//   meta: {
//     requiresAuth: true,
//   },
// },
{
  path: '/lekoTechAi',
  name: 'lekoTechAi',
  component: LekoTechAi,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/introduction',
  name: 'introduction',
  component: Introduction,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/institution/:id',
  name: 'institutionDetails',
  component: institutionDetails,
  meta: {
    requiresAuth: true,
    // employeeRole: true,
    superAdminRole: true,
  },
  props: true,
},
{
  path: '/common-codes',
  name: 'common-codes',
  component: CommonCodes,
  meta: {
    requiresAuth: true,
  },
},
{
  path: '/ebay-shipping-price',
  name: 'ebay-shipping-price',
  component: EbayShippingPrice,
  meta: {
    requiresAuth: true,
    adminRole: true,
  },
},
];


// const routes = baseRoutes.concat(messagesRoutes, peopleRoutes);
const routes = baseRoutes;
const router = new Router({
  mode: 'history',
  routes,
});

function getToken() {
  if (localStorage.token) {
    return localStorage.token;
  }
  return false;
}

function getLanguage() {
  if (localStorage.language) {
    return localStorage.language;
  }
  return store.getters.language;
}

// Sends a post request to verify if the token stored in localstorage is valid.
function verifyToken() {
  const serverUri = config.getServerUri();

  return new Promise(async (resolve) => {
    try {
      store.dispatch('startLoading');
      const verificationResult = await axios.post(`${serverUri}/verify-token`, { token: localStorage.token });
      store.dispatch('verifyToken', verificationResult.data);
      const institutionResult = await axios.get(`${serverUri}/institution?id=${verificationResult.data.institution_id}`, { headers: { Authorization: localStorage.token } });
      const exportEbayLeko = institutionResult.data.institution[0].export_ebay_leko;
      store.dispatch('institutionExportEbayLekoAction', exportEbayLeko);
      const exportGoogleLeko = institutionResult.data.institution[0].export_google_merchant_leko;
      store.dispatch('institutionExportGoogleLekoAction', exportGoogleLeko);
      const { displayFeatures } = institutionResult.data.institution[0].preferences;
      store.dispatch('institutionDisplayFeaturesAction', displayFeatures);
      store.dispatch('finishLoading');
      resolve(verificationResult.status);
    } catch (error) {
      localStorage.removeItem('exp');
      localStorage.removeItem('token');
      store.dispatch('logoutAction');
      store.dispatch('finishLoading');
      router.push({
        path: '/auth',
      });
    }
  });
}

router.beforeEach((to, from, next) => {
  const language = getLanguage();
  store.dispatch('languageAction', language);
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (to.matched.some(record => record.meta.adminRole)) {
      const token = getToken();
      if (!token) {
        router.push({
          path: '/auth',
        });
      } else {
        verifyToken().then((status) => {
          if (status === 200) {
            if (
              store.getters.role === 'User'
              || store.getters.role === 'InstitutionManager'
            ) {
              router.push({
                path: '/home',
              });
            } else if (
              store.getters.role === 'LekoAdmin'
              || store.getters.role === 'LekoEmployee'
              || store.getters.role === 'InstitutionAdmin'
              || store.getters.role === 'SuperAdmin'
            ) {
              next();
            }
          }
        });
      }
    } else if (to.matched.some(record => record.meta.employeeRole)) {
      const token = getToken();
      if (!token) {
        router.push({
          path: '/auth',
        });
      } else {
        verifyToken().then((status) => {
          if (status === 200) {
            if (
              store.getters.role === 'User'
              || store.getters.role === 'InstitutionManager'
              || store.getters.role === 'InstitutionAdmin'
            ) {
              router.push({
                path: '/home',
              });
            } else if (
              store.getters.role === 'LekoAdmin'
              || store.getters.role === 'LekoEmployee'
              || store.getters.role === 'LekoBot'
              || store.getters.role === 'SuperAdmin'
            ) {
              next();
            }
          }
        });
      }
    } else if (to.matched.some(record => record.meta.superAdminRole)) {
      const token = getToken();
      if (!token) {
        router.push({
          path: '/auth',
        });
      } else {
        verifyToken().then((status) => {
          if (status === 200) {
            if (
              store.getters.role === 'User'
              || store.getters.role === 'InstitutionManager'
              || store.getters.role === 'InstitutionAdmin'
              || store.getters.role === 'LekoAdmin'
              || store.getters.role === 'LekoEmployee'
              || store.getters.role === 'LekoBot'
            ) {
              router.push({
                path: '/home',
              });
            } else if (store.getters.role === 'SuperAdmin') {
              next();
            }
          }
        });
      }
    } else {
      const token = getToken();
      if (!token) {
        router.push({
          path: '/auth',
        });
      } else {
        verifyToken().then((status) => {
          if (status === 200) {
            next();
          }
        });
      }
    }
  } else if (to.matched.some(record => record.meta.authRoute)) {
    const token = getToken();
    if (!token) {
      next();
    } else {
      verifyToken().then((status) => {
        if (status === 200) {
          router.push({
            path: '/home',
          });
        }
      });
    }
  } else {
    next();
  }
});

export default router;
