<template>
  <v-dialog
    v-model="dialog"
    width="800"
    persistent
  >
    <v-card
      v-if="loading"
      flat
    >
      <v-layout
        justify-center
        class="pa-5"
      >
        <v-progress-circular
          indeterminate
          color="red"
          size="64"
        />
      </v-layout>
    </v-card>
    <v-card
      v-if="!loading"
      flat
    >
      <v-toolbar
        flat
        color="red darken-3"
      >
        <v-toolbar-title>
          {{ $t('part_requests.offers.label') }}
        </v-toolbar-title>
      </v-toolbar>

      <!----------------------------------------MAIN OFFER----------------------------------------->
      <v-layout
        v-if="assigned && status && status !== 4"
        row
        wrap
        class="pt-2 pl-4 pr-4"
      >
        <!--PRICE-->
        <v-flex
          xs4
          class="pr-1"
        >
          <v-text-field
            v-model="offer.price"
            :disabled="!editable"
            :label="$t('part_requests.offers.price')"
            color="red"
            type="number"
            min="0"
            oninput="validity.valid||(value='');"
          />
        </v-flex>
        <!--FEE-->
        <v-flex
          xs4
          class="pl-1 pr-1"
        >
          <v-text-field
            v-model="offer.fee"
            :disabled="!editable"
            :label="$t('part_requests.offers.fee')"
            color="red"
            type="number"
            min="0"
            oninput="validity.valid||(value='');"
          />
        </v-flex>
        <!--CURRENCY-->
        <v-flex
          xs4
          class="pl-1"
        >
          <v-select
            v-model="offer.currency"
            :disabled="!editable"
            :items="currencies"
            :label="$t('part_requests.offers.currency')"
            color="red"
          />
        </v-flex>
        <!--DESCRIPTION-->
        <v-flex xs12>
          <v-textarea
            v-model="offer.description"
            :disabled="!editable"
            :label="$t('part_requests.offers.description')"
            color="red"
          />
        </v-flex>
        <!--IMAGE GRID-->
        <v-flex xs12>
          <v-container
            grid-list-sm
            fluid
          >
            <v-layout
              row
              wrap
            >
              <!--EXISTING IMAGES-->
              <v-flex
                v-for="(image, index) in offer.images"
                :key="image"
                xs6
                sm4
                d-flex
              >
                <v-hover>
                  <v-card
                    slot-scope="{ hover }"
                    flat
                    title
                    class="d-flex"
                  >
                    <v-img
                      :src="`${server}/images/part-request/?q=${$store.getters.institutionId
                      }-${image}`"
                    >
                      <v-layout
                        row
                        ma-1
                      >
                        <v-spacer />
                        <v-icon
                          v-if="hover"
                          x-large
                          color="red"
                          class="pointer"
                          @click="deleteImage(true, index, image)"
                        >
                          delete_forever
                        </v-icon>
                      </v-layout>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-flex>
              <!--NEW IMAGES-->
              <v-flex
                v-for="(image, index) in images"
                :key="index"
                xs6
                sm4
                d-flex
              >
                <v-hover>
                  <v-card
                    slot-scope="{ hover }"
                    flat
                    title
                    class="d-flex"
                  >
                    <v-img :src="image.dataUrl">
                      <v-layout
                        row
                        ma-1
                      >
                        <v-spacer />
                        <v-icon
                          v-if="hover"
                          x-large
                          color="red"
                          class="pointer"
                          @click="deleteImage(false, index, image)"
                        >
                          delete_forever
                        </v-icon>
                      </v-layout>
                    </v-img>
                  </v-card>
                </v-hover>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
        <!--IMAGES-->
        <v-flex xs12>
          <v-layout justify-center>
            <v-btn
              v-if="!showCamera"
              :disabled="!editable"
              outline
              color="primary"
              @click="showCamera = true"
            >
              <v-icon left>
                camera
              </v-icon>
              {{ $t('part_requests.offers.take_pictures') }}
            </v-btn>
          </v-layout>
          <!--CAMERA COMPONENT-->
          <camera
            v-if="showCamera"
            @takePicture="takePicture"
          />
        </v-flex>
      </v-layout>

      <!------------------------------------------------------------------------------------------->
      <v-divider
        v-if="($store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin')
          && otherInstitutions.length > 0"
        class="mt-4 mb-2"
      />
      <!------------------------------------------------------------------------------------------->

      <!---------------------------------------OTHER OFFERS---------------------------------------->
      <v-layout
        v-if="($store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin')
          && otherInstitutions.length > 0"
        justify-center
        class="mb-1"
      >
        <span class="title">{{ $t('part_requests.offers.other_offers') }}</span>
      </v-layout>
      <v-layout
        v-if="($store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin')
          && otherInstitutions.length > 0"
        row
        wrap
        class="pl-2 pr-2"
      >
        <v-flex
          v-for="(institution, index) in otherInstitutions"
          :key="index"
          xs6
          sm4
          ma-1
        >
          <v-card
            flat
            class="mx-auto"
            :color="getOfferStatus(institution).color"
            :dark="getOfferStatus(institution).dark"
          >
            <v-card-title>
              <v-layout
                align-center
                justify-center
              >
                <span class="title"> {{ getOfferStatus(institution).status }} </span>
              </v-layout>
            </v-card-title>

            <v-layout
              column
              class="ml-2"
            >
              <v-flex>
                <div class="caption">
                  {{ $t('part_requests.offers.institution') }}:
                </div>
                <div class="subheading font-weight-medium">
                  {{ institution.id.title }}
                </div>
                <div class="caption">
                  {{ $t('part_requests.offers.price') }}:
                </div>
                <div class="body-2">
                  {{ institution.offer.price }} {{ institution.offer.currency }}
                </div>
              </v-flex>
              <v-flex>
                <div class="caption">
                  {{ $t('part_requests.offers.description') }}:
                </div>
                <div class="body-2">
                  {{ institution.offer.description }}
                </div>
              </v-flex>
              <v-flex>
                <v-layout
                  row
                  wrap
                >
                  <v-flex
                    v-for="(image, i) in institution.offer.images"
                    :key="i"
                    xs6
                    sm4
                    d-flex
                    class="pa-1"
                  >
                    <v-img
                      :src="`${server}/images/part-request/?q=${institution.id._id
                      }-${image}`"
                    />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-card>
        </v-flex>
      </v-layout>

      <v-card-actions>
        <v-btn
          :disabled="!assigned || status === 4 || !hasOffers || !editable"
          flat
          color="error"
          @click="deleteOffer"
        >
          {{ $t('part_requests.offers.delete_offer') }}
        </v-btn>
        <v-spacer />
        <v-btn
          flat
          color="error"
          @click="close"
        >
          {{ $t('part_requests.close') }}
        </v-btn>
        <v-btn
          :disabled="!assigned || status === 4 || !offer.price || !editable"
          flat
          color="primary"
          :loading="loadingSave"
          @click="saveOffer"
        >
          {{ $t('part_requests.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import axios from 'axios';

import camera from '../camera.vue';
import getRequest from '../../api/getRequest';
import postRequest from '../../api/postRequest';
import deleteRequest from '../../api/deleteRequest';

function generateRandomHexString(length) {
  const chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f'];
  let randomstring = '';
  for (let i = 0; i < length; i += 1) {
    const random = Math.floor(Math.random() * Math.floor(chars.length));
    randomstring += chars[random];
  }
  const timestamp = new Date().getTime().toString(16);
  return `${randomstring}${timestamp}`;
}

export default {
  components: {
    camera,
  },
  data: () => ({
    dialog: false,
    editable: false,
    loading: false,
    loadingSave: false,
    loadingDelete: false,
    showCamera: false,
    requestId: null,
    currencies: ['EUR'], // 'ALL',
    hasOffers: false,
    offer: {
      price: null,
      fee: null,
      currency: 'EUR',
      description: '',
      images: [],
    },
    otherInstitutions: [],
    images: [],
    imagesToDelete: [],
    assigned: false,
    status: null,
  }),
  computed: {
    server() { return this.$serverUri; },
  },
  methods: {
    open(data) {
      this.requestId = data.id;
      this.editable = data.editable;

      this.assigned = !!data.assignedInstitutions
        .find(i => i.id._id.toString() === this.$store.getters.institutionId.toString());

      this.dialog = true;
      this.loading = true;

      getRequest(
        '/part-request/offers',
        `request_id=${this.requestId}&institution_id=${this.$store.getters.institutionId}&role=${this.$store.getters.role}`,
      ).then((response) => {
        if (response.offer) this.offer = response.offer;
        if (response.otherInstitutions) this.otherInstitutions = response.otherInstitutions;
        this.hasOffers = response.found;
        this.status = response.status;
        this.loading = false;
      })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    close() {
      this.dialog = false;
      this.requestId = null;
      this.assigned = false;
      this.loading = false;
      this.loadingSave = false;
      this.loadingDelete = false;
      this.showCamera = false;
      this.images = [];
      this.imagesToDelete = [];
      this.offer = {
        price: null,
        fee: null,
        currency: 'EUR',
        description: '',
        images: [],
      };
      this.otherInstitutions = [];
      this.hasOffers = false;
      this.status = null;
      this.$emit('close');
    },
    takePicture(image) {
      this.images.push({
        dataUrl: image.dataUrl,
        blob: image.blob,
        title: `offerimage-${generateRandomHexString(6)}.png`,
      });
    },
    deleteImage(existing, index, image) {
      if (existing) {
        this.imagesToDelete.push(image);
        this.offer.images.splice(index, 1);
      } else {
        this.images.splice(index, 1);
      }
    },
    saveOffer() {
      this.loadingSave = true;
      const formData = new FormData();

      // Check if there are any images to delete.
      const indexes = this.imagesToDelete
        .map(image => this.offer.images.findIndex(i => i === image));
      indexes.forEach((i) => { if (i > -1) this.offer.images.splice(i, 1); });

      const images = [];
      // Push existing images to the final array.
      this.offer.images.forEach(i => images.push(i));
      // Push newly added images to the final array.
      this.images.forEach(i => images.push(i.title));

      // Append data.
      formData.append('data', JSON.stringify({
        imagesToDelete: this.imagesToDelete,
        institutionId: this.$store.getters.institutionId,
        offer: {
          proposed: true,
          price: this.offer.price,
          fee: this.offer.fee,
          currency: this.offer.currency,
          description: this.offer.description,
          images,
        },
      }));

      // Append new image files.
      this.images.forEach(image => formData.append('request-images', image.blob, `${this.$store.getters.institutionId}-${image.title}`));

      postRequest.formData('/part-request/offer', `request_id=${this.requestId}`, formData)
        .then((response) => {
          this.close();
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingSave = false; });
    },
    deleteOffer() {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loadingDelete = true;
        deleteRequest('/part-request/offer', `request_id=${this.requestId}&institution_id=${this.$store.getters.institutionId}`)
          .then((response) => {
            this.loadingDelete = false;
            this.close();
          })
          .catch((error) => {
            console.log(error);
            this.loadingDelete = false;
            this.close();
          });
      }
    },

    getOfferStatus(institution) {
      if (institution.offer.selected) return { dark: true, color: 'green', status: this.$i18n.t('part_requests.offers.selected') };
      if (institution.offer.refused) return { dark: true, color: 'red', status: this.$i18n.t('part_requests.offers.refused') };
      return { dark: false, color: '', status: '' };
    },
  },
};
</script>
