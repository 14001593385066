<template>
  <div>
    <v-dialog
      v-model="applicabilityDialog"
    >
      <v-card>
        <v-card-actions>
          <v-spacer />
          <v-btn
            outline
            icon
            large
            color="red"
            class="mt-2 mr-2"
            @click="closeAppl"
          >
            <v-icon x-large>
              close
            </v-icon>
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-layout
            v-if="applicability"
            column
          >
            <v-flex xs12>
              <v-toolbar
                flat
                dark
              >
                <v-toolbar-title>{{ $t('parts.infoCar.applicability') }}</v-toolbar-title>
              </v-toolbar>
            </v-flex>
            <v-flex xs12>
              <v-data-table
                :headers="headers"
                :items="applicability"
                :expand="expand"
                item-key="_id"
                hide-actions
                class="flat inventoryDialogDatatable"
              >
                <template
                  slot="items"
                  slot-scope="props"
                >
                  <tr
                    class="inventoryDialogDatatableRow white--text"
                    @click="props.expanded = !props.expanded"
                  >
                    <td class="text-xs">
                      {{ props.item.brand }}
                    </td>
                    <td class="text-xs">
                      <span v-if="props.item.modelId"> {{ props.item.modelId.description }} </span>
                    </td>
                    <td class="text-xs">
                      <span v-if="props.item.modelId">
                        {{ props.item.modelId.startingDate | moment }}
                        &nbsp;-&nbsp;
                        {{ props.item.modelId.endingDate | moment }}
                      </span>
                    </td>
                  </tr>
                </template>
                <template
                  slot="expand"
                  slot-scope="props"
                >
                  <v-data-table
                    :headers="subheaders"
                    :items="props.item.versions"
                    hide-actions
                    class="inventoryDialogDatatableExpand"
                  >
                    <template
                      slot="items"
                      slot-scope="sprops"
                    >
                      <tr class="tableRowColor">
                        <td class="text-xs">
                          {{ sprops.item.version }}
                        </td>
                        <td class="text-xs">
                          <span v-if="sprops.item.versionId">
                            {{ sprops.item.versionId.startingDate | moment }}
                            &nbsp;-&nbsp;
                            {{ sprops.item.versionId.endingDate | moment }}
                          </span>
                        </td>
                        <td class="text-xs">
                          <span v-if="sprops.item.versionId">
                            {{ sprops.item.versionId.numberOfDoors }}
                            &nbsp;
                            {{ $t('parts.infoCar.doors') }}
                          </span>
                        </td>
                        <td class="text-xs">
                          <span v-if="sprops.item.versionId">
                            {{ sprops.item.versionId.power.kw }} kw
                          </span>
                        </td>
                        <td class="text-xs">
                          <span v-if="sprops.item.versionId">
                            {{ sprops.item.versionId.engineCode }}
                          </span>
                        </td>
                        <td class="text-xs">
                          <span v-if="sprops.item.versionId">
                            {{ getSupplyLocale(sprops.item.versionId.supplyCode) }}
                          </span>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--------------------------------------------------------------------------------------------->
    <v-dialog
      v-model="cadDialog"
      persistent
      width="790"
    >
      <v-card
        flat
        fill-height
      >
        <v-toolbar color="red darken-3">
          <v-spacer />
          <v-flex
            xs2
            sm1
            class=""
          >
            <v-btn
              outline
              icon
              large
              color="black"
              @click="close"
            >
              <v-icon x-large>
                close
              </v-icon>
            </v-btn>
          </v-flex>
        </v-toolbar>
        <v-card-text>
          <!--LOADER-->
          <v-layout
            v-if="loadingCad"
            align-center
            justify-center
          >
            <v-progress-circular
              indeterminate
              size="50"
              color="red darken-2"
            />
          </v-layout>

          <v-container
            v-else
            fluid
            fill-height
            pa-0
          >
            <v-layout
              row
              wrap
              justify-center
              align-center
              fill-height
            >
              <v-spacer />
              <v-flex>
                <v-text-field
                  v-model="code"
                  solo
                  single-line
                  hide-details
                  :label="$t('vehicle_entry.code')"
                  @keyup.enter="getApplicability"
                />
              </v-flex>

              <v-flex>
                <v-btn
                  color="red darken-2"
                  dark
                  :loading="loadingAppl"
                  @click="getApplicability"
                >
                  {{ $t('vehicle_entry.get_applicability') }}
                </v-btn>
              </v-flex>
              <v-flex
                xs12
                pt-2
              >
                <iframe
                  v-if="source"
                  :src="source"
                  width="100%"
                  height="610px"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from 'moment';
import getRequest from '../api/getRequest';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      const currentMonth = new Date().toISOString();
      let returnedDate = '';
      if (!date) {
        returnedDate = moment(currentMonth).format('MM/YYYY');
      } else {
        returnedDate = moment(date).format('MM/YYYY');
      }
      return returnedDate;
    },
  },
  data: () => ({
    code: null,

    cadDialog: false,
    loadingCad: false,
    source: null,

    applicabilityDialog: false,
    loadingAppl: false,
    applicability: null,
    expand: false,
    headers: [
      {
        text: 'Brand',
        sortable: false,
        value: '',
      },
      {
        text: 'Model',
        sortable: false,
        value: '',
      },
      {
        text: 'Year',
        sortable: false,
        value: '',
      }],
    subheaders: [
      {
        text: 'Version',
        sortable: false,
        value: '',
      },
      {
        text: 'Year',
        sortable: false,
        value: '',
      },
      {
        text: 'Body',
        sortable: false,
        value: '',
      },
      {
        text: 'Power',
        sortable: false,
        value: '',
      },
      {
        text: 'Engine',
        sortable: false,
        value: '',
      },
      {
        text: 'Fuel',
        sortable: false,
        value: '',
      }],
  }),
  watch: {
    applicabilityDialog(value) { if (!value) { this.closeAppl(); } },
  },
  created() {
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('parts.infoCar.brand');
      this.headers[1].text = this.$i18n.t('parts.infoCar.model');
      this.headers[2].text = this.$i18n.t('parts.infoCar.year');
      this.subheaders[0].text = this.$i18n.t('parts.infoCar.version');
      this.subheaders[1].text = this.$i18n.t('parts.infoCar.year');
      this.subheaders[2].text = this.$i18n.t('parts.infoCar.body');
      this.subheaders[3].text = this.$i18n.t('parts.infoCar.power');
      this.subheaders[4].text = this.$i18n.t('parts.infoCar.engine');
      this.subheaders[5].text = this.$i18n.t('parts.infoCar.supply.title');
    },
    getSupplyLocale(supply) {
      switch (supply) {
        case 'B':
          return this.$i18n.t('parts.infoCar.supply.petrol');
        case 'D':
          return this.$i18n.t('parts.infoCar.supply.diesel');
        case 'G':
          return this.$i18n.t('parts.infoCar.supply.gas');
        default:
          return supply;
      }
    },


    open(data) {
      this.cadDialog = true;
      this.loadingCad = true;
      getRequest('/info-car/cad', `brandCode=${data.brandCode}&modelCode=${data.modelCode}&versionCode=${data.versionCode}`)
        .then((response) => { this.source = `https://infocar-repair.quattroruotepro.it/LRW/application.m?token=${response.token}&DWI=10&Lang=IT-it`; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingCad = false; });
    },
    getApplicability() {
      this.loadingAppl = true;
      getRequest('/info-car/search', `code=${this.code}&populated=true`)
        .then((response) => {
          if (response && response.length) { this.openAppl(response[0].applicability); }
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingAppl = false; });
    },


    openAppl(applicability) {
      this.cadDialog = false;
      this.applicabilityDialog = true;
      this.applicability = applicability;
    },
    closeAppl() {
      this.cadDialog = true;
      this.applicabilityDialog = false;
      this.loadingAppl = false;
      this.applicability = null;
      this.expand = false;
    },
    close() {
      this.closeAppl();
      this.cadDialog = false;
      this.loadingCad = false;
      this.source = null;
      this.code = null;
    },
  },
};
</script>

