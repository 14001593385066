<template>
  <div>
    <canvas ref="chart" />
  </div>
</template>
<script>
import Chart from 'chart.js';

export default {
  props: ['type', 'data'], // eslint-disable-line vue/require-prop-types
  mounted() {
    this.render(this.$props.type, this.$props.data);
  },
  methods: {
    getLocale(d) {
      const data = d;
      data.datasets.forEach((dataset, index) => {
        switch (dataset.label) {
          case 'InsertedEmptyParts':
            data.datasets[index].label = this.$i18n.t('part_status.inserted_empty_parts');
            break;
          case 'DeletedEmptyParts':
            data.datasets[index].label = this.$i18n.t('part_status.deleted_empty_parts');
            break;
          case 'InsertedTemporaryParts':
            data.datasets[index].label = this.$i18n.t('part_status.inserted_temporary_parts');
            break;
          case 'DeletedTemporaryParts':
            data.datasets[index].label = this.$i18n.t('part_status.deleted_temporary_parts');
            break;
          case 'PartsSentForApproval':
            data.datasets[index].label = this.$i18n.t('part_status.parts_sent_for_approval');
            break;
          case 'PartsSentForReview':
            data.datasets[index].label = this.$i18n.t('part_status.parts_sent_for_review');
            break;
          case 'RefusedParts':
            data.datasets[index].label = this.$i18n.t('part_status.refused_parts');
            break;
          case 'ApprovedParts':
            data.datasets[index].label = this.$i18n.t('part_status.approved_parts');
            break;
          case 'InsertedInventory':
            data.datasets[index].label = this.$i18n.t('part_status.inserted_inventory');
            break;
          case 'DeletedInventory':
            data.datasets[index].label = this.$i18n.t('part_status.deleted_inventory');
            break;
          case 'ReturnedFromSales':
            data.datasets[index].label = this.$i18n.t('part_status.returned_sales');
            break;
          case 'ReturnedFromShop':
            data.datasets[index].label = this.$i18n.t('part_status.returned_shop');
            break;
          case 'ReEntered':
            data.datasets[index].label = this.$i18n.t('part_status.re_entered');
            break;
          case 'Found':
            data.datasets[index].label = this.$i18n.t('part_status.found');
            break;
          case 'Sold':
            data.datasets[index].label = this.$i18n.t('part_status.sold');
            break;
          case 'Lost':
            data.datasets[index].label = this.$i18n.t('part_status.lost');
            break;
          case 'Damaged':
            data.datasets[index].label = this.$i18n.t('part_status.damaged');
            break;
          case 'DamagedFromSales':
            data.datasets[index].label = this.$i18n.t('part_status.damaged_sales');
            break;
          case 'DamagedFromShop':
            data.datasets[index].label = this.$i18n.t('part_status.damaged_shop');
            break;
          case 'DamagedFromLostParts':
            data.datasets[index].label = this.$i18n.t('part_status.damaged_lost');
            break;
          default:
            break;
        }
      });
      data.labels.forEach((label, index) => {
        switch (label) {
          case 'Total Activity':
            data.labels[index] = this.$i18n.t('home.charts.total_activity');
            break;
          case 'Past 24h Activity':
            data.labels[index] = this.$i18n.t('home.charts.past_24h_activity');
            break;
          default:
            break;
        }
      });
      return data;
    },
    render(type, data) {
      const ctx = this.$refs.chart;
      const chart = new Chart(ctx, { // eslint-disable-line no-unused-vars
        // The type of chart we want to create
        type,
        // The data for our dataset
        data: this.getLocale(data),
        // Configuration options go here
        options: {
          legend: {
            labels: {
              boxWidth: 12,
            },
          },
        },
      });
    },
  },
};
</script>
