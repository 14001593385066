<template>
  <v-dialog
    v-model="dialog"
    max-width="900px"
  >
    <v-card>
      <!------------------------------------------TOOLBAR------------------------------------------>
      <v-toolbar
        dark
        color="grey darken-4"
        class="elevation-0"
      >
        <v-toolbar-title>
          {{ $t('clients.client') }}: {{ clientDetails.name }} {{ clientDetails.surname }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          class="ma-2"
          outline
          icon
          large
          color="red"
          @click="close"
        >
          <v-icon x-large>
            close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <!-----------------------------------------DATATABLE----------------------------------------->
      <v-layout column>
        <v-flex xs12>
          <v-data-table
            :headers="headers"
            :items="clientSales"
            :loading="loading"
            hide-actions
          >
            <v-progress-linear
              slot="progress"
              color="red"
              indeterminate
            />
            <template
              slot="items"
              slot-scope="props"
            >
              <!--STATUS-->
              <td>
                <v-chip
                  v-if="props.item.sale_id && props.item.sale_id.status === 'Sold'"
                  label
                  outline
                  color="green"
                >
                  {{ $t('clients.table1.sold') }}
                </v-chip>
                <v-chip
                  v-else
                  label
                  outline
                  color="red"
                >
                  {{ $t('clients.table1.returned') }}
                </v-chip>
              </td>
              <!--ID-->
              <td>
                <span
                  v-if="props.item.sale_id
                    && props.item.sale_id.part
                    && props.item.sale_id.part.old_id"
                >
                  {{ props.item.sale_id.part.old_id.slice(props.item.sale_id.part.old_id.length-6, props.item.sale_id.part.old_id.length) }} <!-- eslint-disable-line max-len -->
                </span>
                <span v-else-if="props.item.sale_id && props.item.sale_id.part_id">
                  {{ props.item.sale_id.part_id.slice(props.item.sale_id.part_id.length-6,props.item.sale_id.part_id.length) }} <!-- eslint-disable-line max-len -->
                </span>
              </td>
              <!--TITLE-->
              <td>
                <span
                  v-if="props.item.sale_id
                    && props.item.sale_id.part
                    && props.item.sale_id.part.title"
                >
                  {{ multilanguage(props.item.sale_id.part.title) }}
                </span>
              </td>
              <!--DATE-->
              <td>
                <span v-if="props.item.sale_id && props.item.sale_id.date_created">
                  {{ props.item.sale_id.date_created | moment }}
                </span>
              </td>
              <!--PRICE-->
              <td>
                <span v-if="props.item.sale_id && props.item.sale_id.part">
                  <span v-if="props.item.sale_id.part.price">
                    {{ props.item.sale_id.part.price }}
                  </span>
                  <span v-if="props.item.sale_id.part.currency">
                    {{ props.item.sale_id.part.currency }}
                  </span>
                </span>
              </td>
              <!--DEBT-->
              <td>
                <span v-if="props.item.sale_id && props.item.sale_id.debt">
                  {{ props.item.sale_id.debt }}
                </span>
              </td>
              <!--PAYMENT-->
              <td>
                <v-btn
                  v-if="props.item.sale_id"
                  :disabled="props.item.sale_id.status !== 'Sold'
                    || !props.item.sale_id.debt || props.item.sale_id.debt < 1"
                  flat
                  icon
                  @click="saleId = props.item.sale_id._id, showPayment = true"
                >
                  <v-icon>payment</v-icon>
                </v-btn>
              </td>
            </template>
          </v-data-table>
        </v-flex>
      </v-layout>
      <!---------------------------------------MAKE PAYMENT---------------------------------------->
      <v-layout
        v-if="showPayment"
        align-center
        justify-center
        mt-2
        row
      >
        <v-flex
          xs6
          sm4
        >
          <v-layout
            row
            align-center
          >
            <v-text-field
              v-model="payment"
              :label="$t('clients.payment')"
              type="number"
              min="0"
              color="red darken-1"
            />
            <v-btn
              :loading="loadingPayment"
              flat
              icon
              @click="makePayment"
            >
              <v-icon>done</v-icon>
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
      <!------------------------------------------------------------------------------------------->
      <v-layout
        align-center
        mt-2
        row
      >
        <!--TOTAL EXPENSES-->
        <v-flex xs6>
          <p class="ma-2 text-center">
            {{ $t('clients.total_expenses') }}: {{ clientDetails.total_expenses }}
          </p>
        </v-flex>
        <!--TOTAL DEBTS-->
        <v-flex xs6>
          <p class="ma-2 text-center">
            {{ $t('clients.total_debts') }}: {{ clientDetails.total_debts }}
          </p>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import getRequest from '../../api/getRequest';
import postRequest from '../../api/postRequest';

export default {
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LLL');
    },
  },
  data: () => ({
    dialog: false,
    loading: false,
    loadingPayment: false,
    headers: [
      {
        text: 'Status',
        sortable: true,
        value: 'sale_id.status',
      },
      {
        text: 'Part ID',
        sortable: false,
        value: 'sale_id.part_id',
      },
      {
        text: 'Title',
        sortable: false,
        value: 'sale_id.part.title',
      },
      {
        text: 'Date of sale',
        sortable: true,
        value: 'sale_id.date_created',
      },
      {
        text: 'Price',
        sortable: true,
        value: 'sale_id.part.price',
      },
      {
        text: 'Debt',
        sortable: true,
        value: 'sale_id.debt',
      },
      {
        text: 'Make Payment',
        sortable: false,
        value: '',
      },
    ],
    clientDetails: {},
    clientSales: [],
    payment: 0,
    saleId: null,
    showPayment: false,
  }),
  watch: {
    dialog(value) {
      if (!value) this.close();
    },
  },
  created() {
    this.getLocale();
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('clients.table1.status');
      this.headers[1].text = this.$i18n.t('clients.table1.part_id');
      this.headers[2].text = this.$i18n.t('clients.table1.title');
      this.headers[3].text = this.$i18n.t('clients.table1.sale_date');
      this.headers[4].text = this.$i18n.t('clients.table1.price');
      this.headers[5].text = this.$i18n.t('clients.table1.debt');
      this.headers[6].text = this.$i18n.t('clients.table1.make_payment');
    },
    close() {
      this.dialog = false;
      this.loading = false;
      this.loadingPayment = false;
      this.clientDetails = {};
      this.clientSales = [];
      this.payment = 0;
      this.saleId = null;
      this.showPayment = false;
    },
    open(clientId) {
      this.dialog = true;
      this.loading = true;
      getRequest('/client/', `id=${clientId}`)
        .then((response) => {
          this.loading = false;
          [this.clientDetails] = response.client;
          this.clientSales = this.clientDetails.category && this.clientDetails.category.length > 0
            ? this.clientDetails.category
            : [];
        }).catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    makePayment() {
      this.loadingPayment = true;
      if (this.saleId !== null) {
        postRequest.object(
          `/client/payment?id=${this.clientDetails._id}`,
          { sale_id: this.saleId, payment: this.payment },
        )
          .then((response) => {
            console.log(response);
            this.$emit('saved');
            this.close();
          })
          .catch((error) => {
            console.log(error);
            this.close();
          });
      } else {
        this.loadingPayment = false;
        this.showPayment = false;
        this.payment = 0;
      }
    },
    multilanguage(textObject) {
      if (textObject && textObject !== null && typeof textObject === 'object') {
        return textObject[this.$store.getters.language];
      }
      return '';
    },
  },
};
</script>
