<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="500"
  >
    <v-card flat>
      <v-layout
        column
        wrap
      >
        <!-------------------------------------CAMERA SCANNER-------------------------------------->
        <v-flex
          v-if="!physicalScanner"
          xs12
        >
          <qrcode-stream
            v-if="dialog"
            :camera="constraints"
            :paused="paused"
            @decode="decode"
          />
        </v-flex>
        <!-------------------------------------PHYSICAL SCANNER------------------------------------>
        <v-flex
          v-if="physicalScanner"
          xs12
        >
          <v-layout
            mt-2
            mb-2
            justify-center
          >
            <div
              v-if="found === null && !physicalScannerError"
              class="ma-3 blue--text subheading"
            >
              {{ $t('parts.inventory.qr_scanner.physical_scanner') }}
            </div>
            <div
              v-if="physicalScannerError"
              class="ma-3 red--text subheading"
            >
              {{ $t('parts.inventory.qr_scanner.physical_scanner_error') }}
            </div>
          </v-layout>
        </v-flex>
        <!----------------------------------------------------------------------------------------->
        <v-flex xs12>
          <v-layout
            mt-2
            ml-2
            mr-2
            column
            align-center
            justify-center
          >
            <!--LOADER-->
            <v-flex xs12>
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
              />
            </v-flex>

            <!--ALERT-->
            <v-flex xs12>
              <v-alert
                :value="alert.value"
                :type="alert.type"
                outline
              >
                {{ alert.message }}
              </v-alert>
            </v-flex>

            <!--
            <span
              v-if="found === true"
              class="green--text subheading"
            >
              {{ $t('parts.inventory.qr_scanner.found_message_1') }}

              {{ type === 'part'
                ? $i18n.t('parts.inventory.qr_scanner.part')
                : $i18n.t('parts.inventory.qr_scanner.repository')
              }} {{ $t('parts.inventory.qr_scanner.message_2') }}: {{ decodedString }}</span>
            <span
              v-if="found === false"
              class="red--text subheading"
            >
              {{ $t('parts.inventory.qr_scanner.not_found_message_1') }}
              {{ type === 'part'
                ? $i18n.t('parts.inventory.qr_scanner.part')
                : $i18n.t('parts.inventory.qr_scanner.repository')
              }} {{ $t('parts.inventory.qr_scanner.message_2') }}: {{ decodedString }}</span>

            <span
              v-if="type === 'repo' && !hideLabel"
              class="subheading"
            >{{ $t('parts.inventory.qr_scanner.scan_shelf_message') }}</span>
            -->

            <!---------------------------------------ACTIONS--------------------------------------->
            <v-flex xs12>
              <v-layout
                v-if="found === true && type === 'part'"
                row
                wrap
                align-center
                justify-center
              >
                <!--SCAN ANOTHER PART-->
                <v-flex
                  v-if="mode === 'single' || mode === 'multiple'"
                  xs12
                >
                  <v-layout justify-center>
                    <v-btn
                      outline
                      color="grey darken-2"
                      @click="scanAnotherPart"
                    >
                      {{ $t('parts.inventory.qr_scanner.scan_another_part') }}
                      <v-icon right>
                        autorenew
                      </v-icon>
                    </v-btn>
                  </v-layout>
                </v-flex>

                <!--VIEW DETAILS-->
                <v-flex
                  v-if="mode === 'single'"
                  xs12
                  sm6
                >
                  <v-layout justify-center>
                    <v-btn
                      outline
                      color="grey darken-2"
                      :disabled="partType === 'temporary'"
                      @click="details"
                    >
                      {{ $t('parts.inventory.qr_scanner.view_details') }}
                      <v-icon right>
                        view_list
                      </v-icon>
                    </v-btn>
                  </v-layout>
                </v-flex>

                <!--ACTIONS-->
                <v-flex
                  xs12
                  sm6
                >
                  <v-layout justify-center>
                    <v-btn
                      outline
                      color="grey darken-2"
                      @click="actions"
                    >
                      {{ $t('parts.inventory.qr_scanner.actions') }}
                      <v-icon right>
                        compare_arrows
                      </v-icon>
                    </v-btn>
                  </v-layout>
                </v-flex>

                <!--CHANGE LOCATION-->
                <v-flex
                  xs12
                  sm6
                >
                  <v-layout justify-center>
                    <v-btn
                      outline
                      color="grey darken-2"
                      @click="changeRepo"
                    >
                      {{ $t('parts.inventory.qr_scanner.change_location') }}
                      <v-icon right>
                        meeting_room
                      </v-icon>
                    </v-btn>
                  </v-layout>
                </v-flex>

                <!--EDIT-->
                <v-flex
                  v-if="mode === 'single'"
                  xs12
                  sm6
                >
                  <v-layout justify-center>
                    <v-btn
                      v-if="$store.getters.role == 'LekoAdmin'
                        || $store.getters.role == 'InstitutionAdmin'
                        || $store.getters.role == 'SuperAdmin'"
                      outline
                      color="grey darken-2"
                      :disabled="partType === 'temporary'"
                      @click="edit"
                    >
                      {{ $t('parts.inventory.qr_scanner.edit_part') }}
                      <v-icon right>
                        edit
                      </v-icon>
                    </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <!-----------------------------------SCANNED CODES----------------------------------->

            <v-flex
              v-if="mode === 'multiple' && parts.length > 0"
              xs12
            >
              <span
                v-for="p in parts"
                :key="p.part._id"
              >
                <v-chip>
                  {{
                    p.part && p.part.old_id
                      ? p.part.old_id.slice(p.part.old_id.length - 6, p.part.old_id.length)
                      : p.part._id.slice(p.part._id.length - 6, p.part._id.length)
                  }}
                  <v-icon
                    right
                    @click="removeId(part)"
                  > cancel </v-icon>
                </v-chip>
              </span>
            </v-flex>

            <!----------------------------------SELECT LOCATION---------------------------------->
            <v-flex xs12>
              <v-layout
                v-if="found === true && type === 'repo'"
                row
                wrap
                ma-2
              >
                <v-flex
                  xs6
                  pr-1
                >
                  <v-select
                    v-model="repository"
                    :items="repositories"
                    :label="$t('parts.inventory.qr_scanner.select_repository')"
                    color="red darken-1"
                    item-text="name"
                    return-object
                  />
                </v-flex>
                <v-flex
                  xs6
                  pl-1
                >
                  <v-select
                    v-model="room"
                    :items="repository.rooms"
                    :label="$t('parts.inventory.qr_scanner.select_room')"
                    color="red darken-1"
                    item-text="name"
                    return-object
                  />
                </v-flex>
                <v-flex
                  xs4
                  pr-1
                >
                  <v-select
                    v-model="location"
                    :items="room.locations"
                    :label="$t('parts.inventory.qr_scanner.select_location')"
                    color="red darken-1"
                    item-text="code"
                    return-object
                  />
                </v-flex>
                <v-flex
                  xs4
                  pl-1
                  pr-1
                >
                  <v-select
                    v-model="shelf.x"
                    :items="xPositions"
                    :label="$t('parts.inventory.qr_scanner.select_x')"
                    color="red darken-1"
                  />
                </v-flex>
                <v-flex
                  xs4
                  pl-1
                >
                  <v-select
                    v-model="shelf.y"
                    :items="yPositions"
                    :label="$t('parts.inventory.qr_scanner.select_y')"
                    color="red darken-1"
                  />
                </v-flex>
                <v-flex xs12>
                  <v-layout justify-center>
                    <v-btn
                      :loading="loading2"
                      outline
                      color="primary"
                      @click="changeLocation"
                    >
                      {{ $t('parts.inventory.qr_scanner.change_location') }}
                    </v-btn>
                  </v-layout>
                </v-flex>
              </v-layout>
              <!----------------------------------------------------------------------------------->
            </v-flex>
          </v-layout>
        </v-flex>
        <!-- BOTTOM ACTION BUTTONS -->
        <v-flex xs12>
          <v-layout align-center>
            <!--
            <v-btn
              v-if="decodedString"
              outline
              color="primary"
              @click="reScan">
              {{ $t('parts.inventory.qr_scanner.rescan') }}
            </v-btn>
            -->
            <v-btn
              flat
              icon
              @click="reScan"
            >
              <v-icon>refresh</v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              flat
              icon
              @click="switchScanner"
            >
              <v-icon>fas fa-qrcode</v-icon>
            </v-btn>
            <v-btn
              flat
              icon
              @click="switchCamera"
            >
              <v-icon>switch_camera</v-icon>
            </v-btn>
            <v-btn
              outline
              color="error"
              @click="close"
            >
              {{ $t('parts.inventory.qr_scanner.close') }}
            </v-btn>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';
import postRequest from '../../api/postRequest';
import putRequest from '../../api/putRequest';

export default {
  data: () => ({
    dialog: false,
    front: false,
    paused: false,
    loading: false,
    loading2: false,
    alert: {
      value: false,
      type: 'success',
      message: '',
    },
    constraints: {
      audio: false,
      video: {
        facingMode: 'environment',
      },
    },
    part: {},
    partType: '',
    repositories: [],
    repository: {},
    room: {},
    location: {},
    shelf: {},
    xPositions: [],
    yPositions: [],
    found: null,
    decodedString: null,
    type: 'part',
    hideLabel: true,
    physicalScanner: false,
    physicalScannerError: false,
    buffer: '',
    mode: 'single',
    parts: [],
  }),
  watch: {
    location: {
      handler(value) {
        this.generateCoordinates(value.position_x, value.position_y);
      },
      deep: true,
    },
  },
  methods: {
    switchCamera() {
      this.physicalScanner = false;
      document.removeEventListener('keydown', this.listenToEvent);
      this.front = !this.front;
      this.constraints = {
        video: { facingMode: this.front ? 'user' : 'environment' },
        audio: false,
      };
    },
    switchScanner() {
      this.physicalScanner = true;
      document.addEventListener('keydown', this.listenToEvent);
    },
    listenToEvent(e) {
      // console.log(e);
      if (e.key !== 'Enter') {
        this.buffer += e.key;
      } else if (this.buffer.length > 24) {
        this.physicalScannerError = true;
        this.buffer = '';
      } else {
        this.decode(this.buffer);
        this.buffer = '';
      }
    },
    decode(value) {
      if (value !== null && value.length > 0) {
        this.loading = true;
        this.found = null;
        this.hideLabel = true;
        this.decodedString = value;
        this.paused = true;
        this.alert.value = false;
        if (this.type === 'part') {
          getRequest(`/part/inventory/single/${value}/`)
            .then((response) => {
              this.part = response.part;
              this.partType = response.type;
              const found = this.parts
                .find(p => p.part._id.toString() === this.part._id.toString());
              if (!found) { this.parts.push({ part: this.part, type: this.partType }); }
              this.found = true;
              this.loading = false;

              this.alert = {
                value: true,
                type: 'success',
                message: `${this.$i18n.t('parts.inventory.qr_scanner.found_message_1')} ${this.$i18n.t('parts.inventory.qr_scanner.part')} ${this.$i18n.t('parts.inventory.qr_scanner.message_2')}: ${this.decodedString}`,
              };
            })
            .catch((error) => {
              console.log(error);
              this.part = {};
              this.found = false;
              this.loading = false;

              this.alert = {
                value: true,
                type: 'error',
                message: `${this.$i18n.t('parts.inventory.qr_scanner.not_found_message_1')} ${this.$i18n.t('parts.inventory.qr_scanner.part')} ${this.$i18n.t('parts.inventory.qr_scanner.message_2')}: ${this.decodedString}`,
              };
            });
        } else if (this.type === 'repo') {
          getRequest('/repository/shelf/', `id=${value}`)
            .then((response) => {
              console.log(response);
              const repository = response.repository; // eslint-disable-line prefer-destructuring
              this.repository = repository;
              const room = repository.rooms[response.roomIndex];
              this.room = room;
              const location = room.locations[response.locationIndex];
              this.location = location;
              this.shelf = response.shelf;
              this.found = true;
              this.loading = false;

              this.alert = {
                value: true,
                type: 'success',
                message: `${this.$i18n.t('parts.inventory.qr_scanner.found_message_1')} ${this.$i18n.t('parts.inventory.qr_scanner.repository')} ${this.$i18n.t('parts.inventory.qr_scanner.message_2')}: ${this.decodedString}`,
              };
            })
            .catch((error) => {
              console.log(error);
              this.clearRepo();
              this.found = false;
              this.loading = false;

              this.alert = {
                value: true,
                type: 'error',
                message: `${this.$i18n.t('parts.inventory.qr_scanner.not_found_message_1')} ${this.$i18n.t('parts.inventory.qr_scanner.repository')} ${this.$i18n.t('parts.inventory.qr_scanner.message_2')}: ${this.decodedString}`,
              };
            });
        }
      }
    },
    reScan() {
      this.found = null;
      this.hideLabel = false;
      this.loading = false;
      this.paused = false;
      this.decodedString = null;
      this.physicalScannerError = false;
      if (this.type === 'part') {
        this.part = {};
        this.clearRepo();
        this.alert = {
          value: true,
          type: 'info',
          message: this.$i18n.t('parts.inventory.qr_scanner.scan_part_message'),
        };
      } else if (this.type === 'repo') {
        this.clearRepo();
        this.alert = {
          value: true,
          type: 'info',
          message: this.$i18n.t('parts.inventory.qr_scanner.scan_shelf_message'),
        };
      }
    },
    scanAnotherPart() {
      this.mode = 'multiple';
      this.reScan();
    },
    removeId(part) {
      const index = this.parts.findIndex(p => p._id.toString() === part._id.toString());
      if (index > -1) this.parts.splice(index, 1);
    },
    details() {
      this.$emit('details', this.part);
      this.close();
    },
    actions() {
      this.$emit('actions', { part: this.part, parts: this.parts });
      this.close();
    },
    changeRepo() {
      this.type = 'repo';
      this.found = null;
      this.hideLabel = false;
      this.paused = false;
      this.decodedString = null;
      this.loading = false;
      this.getRepositories();

      this.alert = {
        value: true,
        type: 'info',
        message: this.$i18n.t('parts.inventory.qr_scanner.scan_shelf_message'),
      };
    },
    clearRepo() {
      this.repository = {};
      this.room = {};
      this.location = {};
      this.shelf = {};
      this.xPositions = [];
      this.yPositions = [];
    },
    getRepositories() {
      getRequest(
        '/repository/',
        `role=${this.$store.getters.role}&institution_id=${
          this.$store.getters.institutionId
        }`,
      )
        .then((response) => {
          console.log(response);
          this.repositories = response.repositories;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generateCoordinates(x, y) {
      const xArr = [];
      const yArr = [];
      for (let xc = 1; xc <= x; xc += 1) {
        xArr.push(xc);
      }
      for (let yc = 1; yc <= y; yc += 1) {
        yArr.push(yc);
      }
      this.xPositions = xArr;
      this.yPositions = yArr;
    },
    changeLocation() {
      this.loading2 = true;
      const parts = [];
      const tempParts = [];
      this.parts.forEach((part) => {
        if (part.type === 'inventory') parts.push(part.part);
        if (part.type === 'temporary') tempParts.push(part.part);
      });

      const promises = [];
      // Change inventory parts' locations.
      if (parts.length) {
        promises.push(putRequest('/part/location', '', {
          parts_ids: parts.map(p => p._id),
          repository_id: this.repository._id,
          location_id: this.location._id,
          room_id: this.room._id,
          shelf_id: this.shelf._id,
        }));
      }

      // Change temporary parts' locations.
      if (tempParts.length) {
        promises.push(postRequest.object('/temporary-part/insert/location', {
          parts_ids: tempParts.map(p => p._id),
          repository_id: this.repository._id,
          location_id: this.location._id,
          room_id: this.room._id,
          shelf_id: this.shelf._id,
        }));
      }

      Promise.all(promises)
        .then((response) => {
          console.log(response);
          this.$emit('changed-location');
          this.close();
        })
        .catch((error) => {
          console.log(error.response);
          this.close();
        });
    },
    edit() {
      this.$emit('edit', this.part);
      this.close();
    },
    open() {
      this.dialog = true;
      this.alert = {
        value: true,
        type: 'info',
        message: this.$i18n.t('parts.inventory.qr_scanner.scan_part_message'),
      };
    },
    close() {
      this.dialog = false;
      this.paused = false;
      this.loading = false;
      this.loading2 = false;
      this.part = {};
      this.parts = [];
      this.partType = '';
      this.clearRepo();
      this.found = null;
      this.decodedString = null;
      this.type = 'part';
      this.physicalScanner = false;
      this.physicalScannerError = false;
      this.alert = {
        value: false,
        type: 'success',
        message: '',
      };
      this.mode = 'single';
      this.parts = [];
      document.removeEventListener('keydown', this.listenToEvent);
    },
  },
};
</script>
