<template>
  <v-layout
    justify-center
    align-center
    column
  >
    <!--RECORDER-->
    <!--TODO: Align it with camera buttons. -->
    <v-flex
      xs12
      offset-xs2
      offset-sm1
    >
      <v-layout row>
        <v-flex>
          <!--
            <v-btn
              color="#747474"
              flat
              icon
              @click="stopRecording"
            >
              <v-icon>stop</v-icon>
            </v-btn>
          -->
          <v-btn
            v-if="!recState"
            color="#05CBCD"
            fab
            outline
            :class="{
              'ml-3': $vuetify.breakpoint.xsOnly,
              'ml-2': $vuetify.breakpoint.smOnly,
              'mr-3': $vuetify.breakpoint.mdAndUp,
            }"
            @click="startRecording"
          >
            <v-icon
              color="#747474"
              x-large
            >
              mic
            </v-icon>
          </v-btn>
          <v-btn
            v-if="recState"
            color="#05CBCD"
            fab
            outline
            @click="stopRecording"
          >
            <v-icon
              color="#747474"
              x-large
            >
              stop
            </v-icon>
          </v-btn>

          <v-btn
            color="#747474"
            flat
            icon
            @click="deleteRecording"
          >
            <v-icon>delete_forever</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-flex>
    <!--PLAYER-->
    <v-flex xs12>
      <audio
        v-if="dataUrl"
        controls
      >
        <source
          :src="dataUrl"
          type="audio/ogg"
        >
      </audio>
    </v-flex>
  </v-layout>
</template>

<script>
let audioStream;
let mediaRecorder;

function initMediaDevices() {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        resolve(stream);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
export default {
  data: () => ({
    blob: null,
    dataUrl: null,
    recState: false,
  }),
  mounted() {
    this.startAudioDevice();
  },
  beforeDestroy() {
    this.deleteRecording();
    this.stopAudioDevice();
  },
  methods: {
    startAudioDevice() {
      initMediaDevices().then((stream) => {
        let chunks = [];
        audioStream = stream;
        mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.ondataavailable = (event) => {
          chunks.push(event.data);
        };
        mediaRecorder.onstop = (event) => {
          // console.log(event);
          this.blob = new Blob(chunks, {
            type: 'audio/ogg; codecs=opus',
          });
          chunks = [];
          this.dataUrl = window.URL.createObjectURL(this.blob);
          this.$emit('record', { dataUrl: this.dataUrl, blob: this.blob });
        };
      });
    },
    stopAudioDevice() {
      if (audioStream) {
        const tracks = audioStream.getTracks();
        tracks.forEach((track) => {
          console.log('audio track stopped.');
          track.stop();
        });
      }
    },
    startRecording() {
      if (mediaRecorder.state === 'paused') {
        mediaRecorder.resume();
      } else {
        mediaRecorder.start();
      }
      this.recState = true;
    },
    stopRecording() {
      if (mediaRecorder.state !== 'inactive') {
        mediaRecorder.stop();
        this.recState = false;
      }
    },
    pauseRecording() {
      mediaRecorder.pause();
      this.recState = false;
    },
    deleteRecording() {
      this.blob = null;
      this.dataUrl = null;
      this.$emit('delete');
    },
  },
};
</script>
