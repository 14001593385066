<template>
  <div>
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
      class="pt-1"
    >
      <v-layout
        row
        align-cener
        justify-start
      >
        <v-toolbar-title>{{ $t('leko_employees.tools.label') }}</v-toolbar-title>
        <v-divider
          class="mx-5 hidden-md-and-down"
          inset
          vertical
        />
      </v-layout>
    </v-toolbar>
    <!--GLOBAL ALERT-->
    <v-alert
      :value="alert.value"
      :type="alert.type"
    >
      {{ alert.message }}
    </v-alert>
    <!--TOOLS-->
    <v-layout
      row
      wrap
      ma-4
    >
      <v-flex
        xs12
        sm6
      >
        <v-toolbar
          color="red darken-3"
          dark
        >
          <v-toolbar-title> {{ $t('leko_employees.tools.export_inventory') }} </v-toolbar-title>
        </v-toolbar>
        <v-card>
          <v-layout
            row
            wrap
            align-center
            justify-center
          >
            <!-- INSTITUTION -->
            <v-flex xs12>
              <v-autocomplete
                v-model="institution"
                :items="institutions"
                :loading="loadingInstitution"
                :label="$t('parts.advanced_search.institution')"
                item-text="title"
                item-value="_id"
                flat
                solo
                color="red darken-1"
                class="ml-2 mr-2 mt-2"
                hide-details
              />
            </v-flex>
            <!-- STARTING DATE -->
            <v-flex
              xs12
              sm6
            >
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="startDate"
                    :label="$t('parts.advanced_search.starting_date')"
                    :hint="$t('parts.advanced_search.select_starting_date')"
                    solo
                    flat
                    color="black"
                    persistent-hint
                    prepend-icon="event"
                    readonly
                    class="ml-2 mr-2"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="startDate"
                  color="red darken-3"
                  @input="startDateMenu = false"
                />
              </v-menu>
            </v-flex>
            <!-- ENDING DATE -->
            <v-flex
              xs12
              sm6
            >
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                lazy
                transition="scale-transition"
                offset-y
                full-width
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="endDate"
                    :label="$t('parts.advanced_search.ending_date')"
                    :hint="$t('parts.advanced_search.select_ending_date')"
                    solo
                    flat
                    color="black"
                    persistent-hint
                    prepend-icon="event"
                    readonly
                    class="ml-2 mr-2"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="endDate"
                  color="red darken-3"
                  @input="endDateMenu = false"
                />
              </v-menu>
            </v-flex>
            <v-flex>
              <v-layout
                row
                wrap
                align-center
                justify-center
              >
                <v-btn
                  dark
                  icon
                  @click="clear"
                >
                  <v-icon color="grey darken-4">
                    clear_all
                  </v-icon>
                </v-btn>
                
                <!-- <v-btn
                  dark
                  color="grey darken-4"
                  @click="exportInventory"
                >
                  {{ $t('leko_employees.tools.export') }}
                  <v-icon right>
                    file_download
                  </v-icon>
                </v-btn> -->
                <v-btn
                  dark
                  color="grey darken-4"
                >
                  {{ $t('leko_employees.tools.export') }}
                  <v-icon right>
                    file_download
                  </v-icon>
                </v-btn>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import getRequest from '../api/getRequest';

export default {
  data: () => ({
    loading: false,
    loadingInstitution: false,
    alert: {
      value: false,
      type: 'info',
      message: String,
    },
    institution: null,
    institutions: [],
    startDateMenu: false,
    startDate: null,
    endDateMenu: false,
    endDate: null,
  }),
  created() {
    this.getInstitutions();
  },
  methods: {
    clear() {
      this.institution = null;
      this.startDate = null;
      this.endDate = null;
    },
    getInstitutions() {
      this.loadingInstitution = true;
      getRequest('/institution/')
        .then((response) => { this.institutions = response.institutions; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingInstitution = false; });
    },
    exportInventory() {
      this.loading = true;
      getRequest('/tools/export-inventory', `institution_id=${this.institution}&starting_date=${this.startDate}&ending_date=${this.endDate}`)
        .then((response) => {
          this.alert = { value: true, type: 'info', message: this.$i18n.t('leko_employees.tools.alerts.export_inventory_success') };
          setTimeout(() => { this.alert.value = false; }, 5000);
        })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loading = false; });
    },
  },
};
</script>
