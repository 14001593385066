<template>
  <div>
    <v-data-table
    v-model="selected"
    :pagination.sync="pagination"
    :headers="headers"
    :items="shipments"
    :total-items="totalItems"
    :rows-per-page-items="[5,10,25]"
    item-key="_id"
    :loading="loading"
    select-all
    class="elevation-1"
    @update:pagination="updatePagination"
  >
    <v-progress-linear
      slot="progress"
      color="red"
      indeterminate
    />
    <template
      slot="items"
      slot-scope="props"
    >
      <tr
        :class="{tableRowColorSelected : props.index == activeRow}"
        class="tableRowColor"
        @click="activeRow = props.index"
      >
        <td>
          <v-checkbox
            v-model="props.selected"
            color="red darken-3"
            hide-details
          />
        </td>
        <!-----------------------------------------QR CODE----------------------------------------->
        <td
          v-if="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'SuperAdmin'"
          class="ma-1 pt-1 pb-1"
        >
          <v-layout
            class="pointer"
            ma-1
            column
            align-center
            justify-center
            @click="openQrDialog({
              id: props.item._id,
              type: 'shipments',
              printed: props.item.printed
            })"
          >
            <qr-code
              :size="60"
              :text="props.item._id"
            />
            <h4>{{ props.item._id.slice(props.item._id.length-6, props.item._id.length) }}</h4>
          </v-layout>
        </td>
        <!------------------------------------------NAME------------------------------------------->
        <td class="text-xs">
          {{ props.item.name }}
        </td>
        <!---------------------------------------DESCRIPTION--------------------------------------->
        <td class="text-xs">
          {{ props.item.description }}
        </td>
        <!---------------------------------------INSTITUTION--------------------------------------->
        <td class="text-xs">
          {{ props.item.institution ? props.item.institution.title : '' }}
        </td>
        <!------------------------------------------DATE------------------------------------------->
        <td class="text-xs">
          {{   props.item.date | moment   }}
        </td>
        <!-----------------------------------------ACTIONS----------------------------------------->
        <td class="justify-center">
          <v-layout row>
            <v-btn
              icon
              @click="insertEmptyParts(props.item._id)"
            >
              <v-icon color="grey darken-2">
                add
              </v-icon>
            </v-btn>

            <v-btn
              v-if="$store.getters.role == 'LekoAdmin'
                || $store.getters.role == 'InstitutionAdmin'
                || $store.getters.role == 'InstitutionManager'
                || $store.getters.role == 'SuperAdmin'
              "
              :disabled="props.item.vehicle"
              icon
              @click="editShipment(props.item)"
            >
              <v-icon color="grey darken-2">
                edit
              </v-icon>
            </v-btn>

            <v-btn
              v-if="$store.getters.role == 'LekoAdmin'
                || $store.getters.role == 'InstitutionAdmin'
                || $store.getters.role == 'InstitutionManager'
                || $store.getters.role == 'SuperAdmin'
              "
              :disabled="props.item.vehicle"
              icon
              @click="deleteShipment(props.item._id)"
            >
              <v-icon color="grey darken-2">
                delete
              </v-icon>
            </v-btn>
          </v-layout>
        </td>
        <!----------------------------------------------------------------------------------------->
      </tr>
    </template>
  </v-data-table>
  </div>
</template>

<script>
import moment from 'moment';
import VueQrCode from 'vue-qr-generator';

import getRequest from '../../api/getRequest';
import deleteRequest from '../../api/deleteRequest';

export default {
  components: {
    'qr-code': VueQrCode,
  },
  props: ['search'], 
  filters: {
    moment(date) {
      moment.locale(localStorage.getItem('language'));
      return moment(date).format('LL');
    },
  },
  data: () => ({
    headers: [
      {
        text: 'Qr Code',
        sortable: false,
        value: '_id',
      },
      {
        text: 'Name',
        sortable: false,
        value: 'name',
      },
      {
        text: 'Description',
        sortable: false,
        value: 'description',
      },
      {
        text: 'Institution',
        sortable: false,
        value: 'institution',
      },
      {
        text: 'Date',
        sortable: false,
        value: 'date',
      },

    ],
    pagination: {
      rowsPerPage: 5,
      page: 1,
      sortBy: 'date_created',
      descending: true,
    },
    shipments: [],
    totalItems: 0,
    loading: false,
    activeRow: null,
    selected: [],
  }),
  computed: {
    searchField() { return this.$props.search; }
  },
  watch: {
    selected(value) { this.$emit('selected', value); },
    searchField(value) {
      if (!value) this.getShipments();
      else this.searchShipments(value);
    },
    pagination: {
      handler() {
        if (!this.searchField) this.getShipments();
        else this.searchShipments(this.searchField);
      }
    }
  },
  created() {
    this.getLocale();
    this.activeRow = null;
  },
  methods: {
    getLocale() {
      this.headers[0].text = this.$i18n.t('shipments.table.name');
      this.headers[1].text = this.$i18n.t('shipments.table.description');
      this.headers[2].text = this.$i18n.t('shipments.table.institution');
      this.headers[3].text = this.$i18n.t('shipments.table.date');
      this.headers[4].text = this.$i18n.t('shipments.table.actions');
      if (this.$store.getters.role === 'LekoAdmin' || this.$store.getters.role === 'SuperAdmin') {
        this.headers.splice(0, 0, {
          text: this.$i18n.t('shipments.table.qr_code'),
          sortable: false,
          value: 'actions',
        });
      }
    },
    getShipments() {
      this.loading = true;
      getRequest(
        '/shipment/',
        `role=${this.$store.getters.role
        }&institution_id=${this.$store.getters.institutionId
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page}&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending}`,
      )
      .then((response) => {
        this.totalItems = response.count;
        this.shipments = response.shipments;
        this.loading = false;
      })
      .catch((error) => { console.log(error); })
      .finally(() => { this.loading = false; });
    },
    searchShipments(value) {
      this.loading = true;
      getRequest(
        '/shipment/',
        `role=${this.$store.getters.role
        }&search=${value
        }&institution_id=${this.$store.getters.institutionId
        }&limit=${this.pagination.rowsPerPage
        }&page=${this.pagination.page}&sort_by=${this.pagination.sortBy
        }&descending=${this.pagination.descending}`,
      )
      .then((response) => {
        this.totalItems = response.count;
        this.shipments = response.shipments;
        this.loading = false;
      })
      .catch((error) => { console.log(error); })
      .finally(() => { this.loading = false; });

    },
      updatePagination() {
      this.activeRow = null;
    },
    openQrDialog(data) {
      this.$emit('qr', data);
    },
    insertEmptyParts(shipmentId) {
      this.$emit('insert', shipmentId);
    },
    editShipment(shipment) {
      this.$emit('edit-shipment', shipment);
    },
    deleteShipment(shipmentId) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      const c = confirm(this.$i18n.t('delete_alert'));
      if (c === true) {
        this.loading = true;
        deleteRequest('/shipment/', `id=${shipmentId}`)
          .then((response) => {
            if (response.status === 200) {
              this.getShipments();
            }
          });
      }
    },
  },
};
</script>
