<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="500"
  >
    <v-card>
      <v-card-title class="redColor">
        <span
          v-if="edit === true"
          class="headline"
        > {{ $t('car_locations.edit_car_location') }} </span>
        <span
          v-if="edit === false"
          class="headline"
        > {{ $t('car_locations.create_car_location') }} </span>
      </v-card-title>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field
                  v-model="carLocation.name"
                  :rules="rules.required"
                  :label="$t('car_locations.form.name')"
                />
              </v-flex>
              <v-flex xs12>
                <v-text-field
                  v-model="carLocation.description"
                  :label="$t('car_locations.form.description')"
                />
              </v-flex>
              <v-flex
                v-if="$store.getters.role === 'LekoAdmin' || $store.getters.role === 'LekoEmployee' || $store.getters.role === 'SuperAdmin'"
                xs12
              >
                <v-select
                  v-model="carLocation.institution_id"
                  :rules="rules.required"
                  :items="institutions"
                  :loading="loadingInstitutions"
                  :label="$t('car_locations.form.select_institution')"
                  color="red darken-1"
                  item-text="title"
                  item-value="_id"
                />
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          color="red darken-1"
          flat
          @click.native="close"
        >
          {{ $t('car_locations.form.close') }}
        </v-btn>
        <v-btn
          color="blue darken-1"
          flat
          :loading="loading"
          @click.native="save"
        >
          {{ $t('car_locations.form.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import getRequest from '../../api/getRequest';
import postRequest from '../../api/postRequest';
import putRequest from '../../api/putRequest';

export default {
  data: () => ({
    dialog: false,
    valid: false,
    loading: false,
    loadingInstitutions: false,
    rules: {
      required: [v => !!v || 'Please fill this field'],
    },
    carLocation: {},
    institutions: [],
    edit: null,

  }),
  methods: {
    open(edit, carLocation) {
      // assign data
      this.edit = edit;
      if (carLocation) this.carLocation = carLocation;
      if (this.$store.getters.role === 'LekoAdmin' || this.$store.getters.role === 'LekoEmployee' || this.$store.getters.role === 'SuperAdmin') this.getInstitutions();
      // open
      this.dialog = true;
    },
    close() {
      this.$emit('close');
      this.dialog = false;
      this.loading = false;
      this.carLocation = {};
      this.institutions = [];
    },
    save() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        // assign institution
        if (this.$store.getters.role === 'User'
          || this.$store.getters.role === 'InstitutionManager'
          || this.$store.getters.role === 'InstitutionAdmin') {
          this.carLocation.institution_id = this.$store.getters.institutionId;
        }
        // save
        if (this.edit) {
          putRequest('/car-location', `id=${this.carLocation._id}`, this.carLocation)
            .then((response) => { this.$emit('save', response); })
            .catch((error) => { console.log(error); })
            .finally(() => { this.loading = false; this.close(); });
        } else {
          postRequest.object('/car-location', this.carLocation)
            .then((response) => { this.$emit('save', response); })
            .catch((error) => { console.log(error); })
            .finally(() => { this.loading = false; this.close(); });
        }
      }
    },
    getInstitutions() {
      this.loadingInstitutions = true;
      getRequest('/institution')
        .then((response) => { this.institutions = response.institutions; })
        .catch((error) => { console.log(error); })
        .finally(() => { this.loadingInstitutions = false; });
    },
  },
};

</script>
