<template>
  <v-container
    fluid
    class="pa-0"
  >
    <!--TOOLBAR-->
    <v-toolbar
      flat
      color="red darken-3"
    >
      <v-toolbar-title class="hidden-sm-and-down">
        {{ $t('cars.label') }}
      </v-toolbar-title>
      <v-divider
        class="mx-5 hidden-sm-and-down"
        inset
        vertical
      />
      <v-text-field
        v-model="search"
        :label="$t('cars.search')"
        flat
        solo
        color="red darken-1"
        append-icon="search"
        single-line
        hide-details
      />
      <v-spacer class="hidden-sm-and-down" />
      <v-btn
        v-if="$store.getters.role == 'LekoAdmin' || $store.getters.role == 'LekoEmployee' || $store.getters.role == 'SuperAdmin'"
        color="grey darken-4"
        dark
        @click="newCar"
      >
        {{ $t('cars.button') }}
      </v-btn>
    </v-toolbar>
    <!--ALERT-->
    <v-alert
      :value="alert.value"
      type="error"
    >
      {{ alert.message }}
    </v-alert>
    <!--EDIT DIALOG-->
    <edit-dialog
      ref="editDialog"
      @save="save"
      @close="close"
      @error="error"
    />
    <!--DATATABLE-->
    <datatable
      ref="datatable"
      @edit="editCar"
    />
  </v-container>
</template>

<script>
import EditDialog from '@/components/Cars/dialog.vue';
import Datatable from '@/components/Cars/datatable.vue';

export default {
  components: {
    'edit-dialog': EditDialog,
    datatable: Datatable,
  },
  data: () => ({
    search: '',
    alert: { value: false, message: '' },
  }),
  watch: {
    search(value) {
      const { datatable } = this.$refs;
      datatable.search(value);
    },
  },
  methods: {
    newCar() {
      const { editDialog } = this.$refs;
      editDialog.open(false, null);
    },
    editCar(car) {
      const { editDialog } = this.$refs;
      editDialog.open(true, car);
    },
    save() {},
    close() {
      const { datatable } = this.$refs;
      datatable.getCars();
    },
    error() {
      this.alert = { value: true, message: 'An unexpected error occurred.' };
      setTimeout(() => { this.alert = { value: false, message: '' }; }, 2000);
    },
  },
};
</script>
